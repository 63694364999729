/** @format */

import {
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

export function Form({ formik, data }: any) {
  return (
    <>
      <Grid gridTemplateColumns={"1fr 1fr"} display={"grid"} gap={2}>
        <FormControlLabel
          id={"with_position"}
          checked={formik.values.with_position}
          onChange={(event: any) => {
            formik.setFieldValue("with_position", event.target.checked);
          }}
          control={<Checkbox />}
          label='Transferência com a posição ?'
        />
      </Grid>
      <Grid gridTemplateColumns={"1fr 1fr"} mt={2} display={"grid"} gap={2}>
        <TextField
          fullWidth
          id='new_manager_id'
          select
          label='Gestor Futuro'
          value={formik.values.new_manager_id}
          onChange={(event: any) =>
            formik.setFieldValue("new_manager_id", event.target.value)
          }
          onBlur={formik.handleBlur}
          error={
            formik.touched.new_manager_id &&
            Boolean(formik.errors.new_manager_id)
          }
          helperText={
            formik.touched.new_manager_id && formik.errors.new_manager_id
          }>
          {data.options.managers.map((option: any) => (
            <MenuItem key={option.id} value={option.id}>
              {option?.name}
            </MenuItem>
          ))}
        </TextField>

        <DatePicker
          slotProps={{
            textField: {
              fullWidth: true,
              id: "transfer_date",
              onBlur: formik.handleBlur,
              error:
                formik.touched.transfer_date &&
                Boolean(formik.errors.transfer_date),
              helperText:
                formik.touched.transfer_date && formik.errors.transfer_date,
            },
          }}
          label='Data da transferência'
          value={formik.values.transfer_date}
          onChange={(value: any) => {
            formik.setFieldValue("transfer_date", value);
          }}
        />
      </Grid>
      <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
        <div>
          <TextField
            fullWidth
            id='observations'
            label='Comentários'
            variant='outlined'
            multiline={true}
            rows={4}
            value={formik.values.observations}
            onChange={(event: any) =>
              formik.setFieldValue("observations", event.target.value)
            }
            onBlur={formik.handleBlur}
            error={
              formik.touched.observations && Boolean(formik.errors.observations)
            }
            helperText={
              formik.touched.observations && formik.errors.observations
            }
          />
        </div>
      </Grid>
    </>
  );
}
