/** @format */

import { Edit, Undo, Visibility } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ActionAccountingDisapproved } from "../../../../../components/refactor/actionAccountingDisapproved";
import { ActionAproved } from "../../../../../components/refactor/actionApproved";
import { ActionCompleted } from "../../../../../components/refactor/actionCompleted";
import { ActionDisapproved } from "../../../../../components/refactor/actionDisapproved";
import { ActionResend } from "../../../../../components/refactor/actionResend";
import { ActionReturned } from "../../../../../components/refactor/actionReturned";
import { BreadcrumbsComponent } from "../../../../../components/refactor/breadcrumbsComponent";
import { ButtonComponent } from "../../../../../components/refactor/buttonComponent";
import { DocsView } from "../../../../../components/refactor/docsView";
import { InfoComponent } from "../../../../../components/refactor/infoComponent";
import { StatusPermittedByRoleContainer } from "../../../../../components/statusPermittedByRoleContainer";
import {
  ClearCurrency,
  FormatCurrency,
} from "../../../../../helpers/formatCurrency";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../../services/api";
import { validationSchema } from "../helpers/constants";
import { Form } from "../helpers/form";

export function SalaryReviewDetails() {
  const router = useNavigate();
  const { id } = useParams();
  const [docs, setDocs] = useState(false);
  const user = useSelector((state: any) => state.user.data);
  const dispatch = useDispatch();
  const [data, setData]: any = useState({
    collaborator_data: {},
    solicitation_data: {},
    rules_data: {
      salary_review_min_percent: 0,
      salary_review_max_percent: 0,
    },
  });
  const isResendable =
    data?.solicitation_data.status === "returned" &&
    user.id === data.record.created_by_id;
  const permissions = useSelector((state: any) => state.user.permissions);
  const edit =
    permissions !== null &&
    permissions.indexOf("requestSalaryReviewDetailsEdit") !== -1 &&
    (data?.solicitation_data.status === "pending" ||
      (user.role === "rh" &&
        data?.solicitation_data.status !== "completed" &&
        data?.solicitation_data.status !== "approved"));

  const formik: any = useFormik({
    initialValues: {
      e_social_motive: "",
      move_date: null,
      percent: "",
      future_salary: "",
      observations: "",
      actual_salary: "",
    },
    validationSchema: validationSchema(data.rules_data, user),
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      PutInstante(
        null,
        {
          ...values,
          future_salary: ClearCurrency(values.future_salary),
          percent: Number(values.percent),
        },
        "/salary_reviews/" + id
      )
        .then((response: any) => {
          dispatch(updateLoading(false));
          toast.success(response?.data?.message);
          router("/request/salaryReview");
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({}, "/salary_reviews/" + id)
      .then(async (response: any) => {
        const data = response.data.data;
        setData(data);
        const move_date = dayjs(data.record.move_date);
        formik.setValues({
          e_social_motive: data.record.e_social_motive,
          move_date,
          percent: data.record.percent.toString(),
          future_salary: FormatCurrency({
            target: {
              value: `${data.record.future_salary}` || "0",
            },
          }),
          observations: data.record.observations,
          actual_salary: ClearCurrency(
            data.collaborator_data.remuneration_value
          ),
          rules_data: data.rules_data,
        });
        dispatch(updateLoading(false));
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Solicitações", value: "/request/salaryReview" },
            { label: "Revisão salarial", value: "/request/salaryReview" },
            { label: "Ver solicitação" },
          ]}
        />
        <InfoComponent
          user={data.collaborator_data}
          solicitation={data.solicitation_data}
        />
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Detalhes da revisão salarial
              </Typography>
            </Grid>
            <Form formik={formik} userData={user} edit={!edit} />
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <ButtonComponent
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                endIcon={<Undo />}>
                Voltar
              </ButtonComponent>
              {isResendable && (
                <ActionResend
                  id={id}
                  url={"salary_reviews"}
                  disabled={!formik.isValid}
                  onSubmit={(resendActionCallback: any) => {
                    dispatch(updateLoading(true));
                    PutInstante(
                      null,
                      {
                        ...formik.values,
                        future_salary: ClearCurrency(
                          formik.values.future_salary
                        ),
                        percent: Number(formik.values.percent),
                      },
                      "/salary_reviews/" + id
                    )
                      .then((response: any) => {
                        resendActionCallback();
                        dispatch(updateLoading(false));
                        toast.success(response?.data?.message);
                        router("/request/salaryReview");
                      })
                      .catch((e: any) => {
                        GenericError(e, dispatch);
                      });
                  }}
                />
              )}
              {edit && (
                <ButtonComponent
                  variant={"contained"}
                  type={"submit"}
                  endIcon={<Edit />}>
                  Editar
                </ButtonComponent>
              )}
              <StatusPermittedByRoleContainer
                rhAllowedStatuses={[
                  "pending",
                  "waiting_manager_user",
                  "waiting_rh_user",
                  "accounting_return",
                ]}
                managerAllowedStatuses={["pending", "waiting_manager_user"]}
                status={data?.solicitation_data.status}>
                <ActionReturned id={id} url={"salary_reviews"} />
                <ActionDisapproved id={id} url={"salary_reviews"} />
              </StatusPermittedByRoleContainer>

              <StatusPermittedByRoleContainer
                rhAllowedStatuses={[
                  "pending",
                  "waiting_manager_user",
                  "waiting_rh_user",
                ]}
                managerAllowedStatuses={["pending", "waiting_manager_user"]}
                status={data?.solicitation_data.status}>
                <ActionAproved
                  keyPrivate={"requestSalaryReviewDetailsApprove"}
                  id={id}
                  url={"salary_reviews"}
                />
              </StatusPermittedByRoleContainer>
              <StatusPermittedByRoleContainer
                rhAllowedStatuses={["accounting_return"]}
                status={data?.solicitation_data.status}>
                <ActionResend
                  id={id}
                  url={"salary_reviews"}
                  hasObservation
                  disabled={!formik.isValid}
                  onSubmit={(resendActionCallback: any) => {
                    dispatch(updateLoading(true));
                    PutInstante(
                      null,
                      {
                        ...formik.values,
                        future_salary: ClearCurrency(
                          formik.values.future_salary
                        ),
                        percent: Number(formik.values.percent),
                      },
                      "/salary_reviews/" + id
                    )
                      .then((response: any) => {
                        resendActionCallback();
                        dispatch(updateLoading(false));
                        toast.success(response?.data?.message);
                        router("/request/salaryReview");
                      })
                      .catch((e: any) => {
                        GenericError(e, dispatch);
                      });
                  }}
                />
              </StatusPermittedByRoleContainer>
              <StatusPermittedByRoleContainer
                accountantAllowedStatuses={["approved"]}
                status={data?.solicitation_data.status}>
                <ActionCompleted
                  keyPrivate={"requestDetailsComplete"}
                  id={id}
                  url={"salary_reviews"}
                />
                <ActionAccountingDisapproved
                  keyPrivate={"requestDetailsComplete"}
                  id={id}
                  url={"processes"}
                />
              </StatusPermittedByRoleContainer>
              <StatusPermittedByRoleContainer
                rhAllowedStatuses={["approved", "completed"]}
                managerAllowedStatuses={["approved", "completed"]}
                status={data?.solicitation_data.status}>
                <ButtonComponent
                  onClick={() =>
                    router("/request/salaryReview/details/letter/" + id)
                  }
                  variant={"contained"}
                  endIcon={<Visibility />}>
                  Ver documentos de comunicação
                </ButtonComponent>
              </StatusPermittedByRoleContainer>
            </Grid>
          </Paper>
          {docs && (
            <DocsView
              solicitation_uuid={id}
              solicitation_type={"salary_review"}
            />
          )}
        </Grid>
      </Grid>
    </form>
  );
}
