/**
 * eslint-disable array-callback-return
 *
 * @format
 */

/** @format */

export interface UserProps {
  id: number;
  avatar: string;
  name: string;
  manager_id: number | null;
  userActive: boolean;
  userManager: boolean;
  userOptions: boolean;
  open: boolean;
  noMore: boolean;
  hasChildren: boolean;
  noMoreChildren: boolean;
  hasSiblings: boolean;
  hasAbove: boolean;
  super: boolean;
}

export interface OrgProps extends UserProps {
  children: OrgProps[];
  siblings: OrgProps[];
  above: OrgProps[];
}

const removeEmptyChildren = (node: any) => {
  if (node.children && node.children.length === 0) {
    delete node.children;
  } else if (node.children) {
    node.children.forEach(removeEmptyChildren);
  }
};

const addPropertiesRecursively = (
  orgList: OrgProps[],
  mapUsers: Record<number, OrgProps> = {}
): OrgProps[] => {
  return orgList.map((node) => {
    const siblings = orgList.filter((item) => item.id !== node.id);

    if (orgList.length > 0 && siblings.length > 0) {
      node.hasSiblings = true;
      node.siblings = siblings;
    }

    if (node.manager_id !== null) {
      node.hasAbove = true;
      node.above = [{ ...mapUsers[node.manager_id], super: true }];
    }

    if (node.children && node.children.length > 0) {
      node.hasChildren = true;
      node.children = addPropertiesRecursively(node.children, mapUsers);
    }
    return node;
  });
};
let userActive = {};
const findUser = (orgList: OrgProps[]): any => {
  // eslint-disable-next-line array-callback-return
  orgList.filter((node) => {
    if (node.userActive) {
      userActive = node;
    }
    if (node.children && node.children.length > 0) {
      findUser(node.children);
    }
  });
  return [userActive];
};

export function createOrg(
  users: UserProps[],
  activeUser: UserProps
): OrgProps[] {
  const mapUsers: Record<number, OrgProps> = {};
  const rootUsuarios: OrgProps[] = [];

  users.forEach((user) => {
    user.userActive = user.id === activeUser.id;
    user.userManager = user.manager_id === activeUser.id;
    user.userOptions = false;
    user.open = user.id === activeUser.id || user.manager_id === activeUser.id;
    user.hasChildren = false;
    user.noMoreChildren = user.id === activeUser.id;
    user.hasSiblings = false;
    user.noMore = user.manager_id === activeUser.id;
    user.hasAbove = false;
    user.super = false;
    mapUsers[user.id] = { ...user, children: [], siblings: [], above: [] };
  });

  users.forEach((user: any) => {
    if (user.manager_id !== null && mapUsers[user.manager_id] !== undefined) {
      mapUsers[user.manager_id].children.push(mapUsers[user.id]);
      mapUsers[user.manager_id].hasChildren = true;
    } else {
      rootUsuarios.push(mapUsers[user.id]);
    }
  });

  const rootUsuariosData = addPropertiesRecursively(rootUsuarios, mapUsers);
  rootUsuariosData.forEach(removeEmptyChildren);

  Object.freeze(rootUsuariosData);

  const filteredRootUsuarios = findUser(rootUsuariosData);
  return filteredRootUsuarios;
}
