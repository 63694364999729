/** @format */

import { CheckCircle } from "@mui/icons-material";
import { Button } from "@mui/material";
import { AlertDialog, Flex } from "@radix-ui/themes";

interface Props {
  open: boolean;
  date: string;
  type: string;
  close: () => void;
}
export const DialogDismissalNew = ({ date, type, close }: Props) => {
  return (
    <AlertDialog.Root open>
      <AlertDialog.Content maxWidth='450px'>
        <AlertDialog.Title color='red'>
          Atenção - Periodo de estabilidade!
        </AlertDialog.Title>
        <AlertDialog.Description size='2'>
          <strong>Até o dia:</strong> {date}
        </AlertDialog.Description>
        <AlertDialog.Description size='2'>
          <strong>Motivo:</strong> {type}
        </AlertDialog.Description>
        <AlertDialog.Description size='2' style={{ marginTop: 8 }}>
          Para solicitar o desligamento, acione a equipe do RH.
        </AlertDialog.Description>
        <Flex gap='3' mt='4' justify='end'>
          <AlertDialog.Action>
            <Button
              color='success'
              variant={"contained"}
              onClick={close}
              endIcon={<CheckCircle />}>
              Ok
            </Button>
          </AlertDialog.Action>
        </Flex>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};
