/** @format */
import * as yup from "yup";
export const columns = [
  { name: "delegated_user_name", label: "Delegado" },
  { name: "target_user_name", label: "Atribuído a" },
  { name: "created_by_name", label: "Criado por" },
  { name: "initial_date", label: "Data início" },
  { name: "final_date", label: "Data fim" },
  { name: "active", label: "Ativo" },
];

export const validationSchema = yup.object({
  initial_date: yup
    .date()
    .nullable()
    .max(
      yup.ref("final_date"),
      "Data inicial não pode ser posterior a data final"
    )
    .required("Data inicial é obrigatório"),
  final_date: yup
    .date()
    .nullable()
    .min(
      yup.ref("initial_date"),
      "Data final não pode ser anterior a data inicial"
    )
    .min(new Date(), "Data é igual ou anterior a data de hoje")
    .required("Data final é obrigatório"),
  target_user_id: yup.string().nullable().required("Atribuição é obrigatório"),
  delegated_user_id: yup
    .string()
    .nullable()
    .required("Colaborador delegado é obrigatório"),
});
