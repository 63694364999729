/** @format */

export const processOptions = [
  { label: "Todos", value: "all" },
  { label: "Transferência", value: "transfer" },
  { label: "Desligamento", value: "dismissal" },
  { label: "Afastamento", value: "absence" },
  { label: "Revisão salarial", value: "salary_review" },
  { label: "Promoção", value: "promotion" },
  { label: "Férias", value: "vacation_item" },
  { label: "Atualização cadastral", value: "user_update_request" },
];

export const columns = [
  { name: "collaborator_identification", label: "Matrícula" },
  { name: "collaborator_name_formatted", label: "Nome do colaborador" },
  { name: "business_unit_contract_type", label: "Tipo de contrato" },
  { name: "solicitation_type_label", label: "Nome do processo" },
  { name: "approved_at", label: "Data de aprovação" },
  { name: "status", label: "Status" },
];

export const accountantStatusOptions = [
  { label: "Aprovados", value: "approved" },
  { label: "Concluídos", value: "completed" },
  { label: "Reprovado folha", value: "accounting_disapproved" },
];

export const rhStatusOptions = [
  { label: "Aguardando gestor", value: "waiting_manager_user" },
  { label: "Aguardando RH", value: "waiting_rh_user" },
  { label: "Aprovados", value: "approved" },
  { label: "Concluídos", value: "completed" },
  { label: "Reprovado", value: "disapproved" },
  { label: "Reprovado folha", value: "accounting_disapproved" },
];
