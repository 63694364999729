/** @format */

export const handleResourseRoute = (data: any, router: any) => {
  const routes: any = {
    absence: `/request/leave/details/${data.resource_uuid}`,
    dismissal: `/request/dismissals/details/${data.resource_uuid}`,
    salary_review: `/request/salaryReview/details/${data.resource_uuid}`,
    promotion: `/request/promote/details/${data.resource_uuid}`,
    transfer: `/request/transfer/details/${data.resource_uuid}`,
    vacation_item: `/request/vacation/details/${data.resource_uuid}`,
    user_update_request: `/request/updateInformation/details/${data.resource_uuid}`,
  };

  const route = routes[data.solicitation_type_route];
  if (route) {
    router(route);
  }
};
