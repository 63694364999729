/** @format */

export function FormatSolicitation(value: string) {
  const statusDict = {
    dismissal: "Desligamento",
    promotion: "Promoção",
    transfer: "Transferências",
    salaryReview: "Revisão salarial",
  };
  const exist = Object.entries(statusDict).filter(
    (opt: any) => opt[0] === value
  );
  return value ? (exist.length > 0 ? exist[0][1] : "Não possui") : "Não possui";
}
