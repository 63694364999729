/** @format */

import { Download, InfoOutlined, Undo } from "@mui/icons-material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs,
  Button,
  Divider,
  Grid,
  Link,
  Paper,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Callout } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ActionAprovedRule } from "../../../../../components/actionApprovedRule";
import { ActionDisapprovedRule } from "../../../../../components/actionDisapprovedRule";
import { ActionReturnedRule } from "../../../../../components/actionReturnedRule";
import { ActionAccountingDisapproved } from "../../../../../components/refactor/actionAccountingDisapproved";
import { ActionCompleted } from "../../../../../components/refactor/actionCompleted";
import { ActionResend } from "../../../../../components/refactor/actionResend";
import { StatusPermittedByRoleContainer } from "../../../../../components/statusPermittedByRoleContainer";
import {
  admission_type_e_social,
  banks_e_social,
  contract_type_e_social,
  country_e_social,
  dependents_e_social,
  education_level_e_social,
  gender_e_social,
  level_cnh_e_social,
  marital_status_e_social,
  nature_e_social,
  overtime_type_e_social,
  race_e_social,
  salary_e_social,
  security_type_e_social,
  social_security_regime_e_social,
  status_e_social,
  type_of_account,
} from "../../../../../config/constants";
import { fields } from "../../../../../config/fields";
import { EmptyData } from "../../../../../helpers/emptyData";
import { FormatBool } from "../../../../../helpers/formatBool";
import { FormatStatus } from "../../../../../helpers/formatStatus";
import { FormatStatusToHistoric } from "../../../../../helpers/formatStatusToHistoric";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance } from "../../../../../services/api";

export function UpdateInformationDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [options, setOptions] = useState({
    departments: [],
    job_role: [],
    union: [],
    health_insurance: [],
    food_voucher: [],
    business_unit: [],
    sector: [],
    work_section: [],
    cost_center: [],
    managerial_cost_center: [],
    work_category: [],
    work_schedule: [],
    salary_payment_method: [],
    managers: [],
  });
  const [data, setData]: any = useState({
    collaborator_data: {},
    solicitation_data: { files: [] },
    record: { payload: {} },
  });

  const [expandedHistoricId, setExpandedHistoricId] = useState<any>(null);

  const user = useSelector((state: any) => state.user.data);
  const isResendable =
    data?.solicitation_data.status === "returned" &&
    user.id === data.record.created_by_id;

  useEffect(() => {
    GetInstance({}, "/user_update_requests/" + id)
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        const formData = response.data.data;
        setData(formData);
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    GetInstance({}, "/users_form")
      .then(async (response: any) => {
        setOptions(response.data.data.options);
        dispatch(updateLoading(false));
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userInfo = [
    {
      title: "Nome",
      value: data.collaborator_data.name,
    },
    {
      title: "Cód. cargo",
      value: EmptyData(data.collaborator_data.job_role_code),
    },
    {
      title: "Cargo",
      value: EmptyData(data.collaborator_data.job_role_name),
    },
    {
      title: "Status",
      value: FormatStatus(data.collaborator_data.status),
    },
  ];

  const requestInfo = [
    {
      title: "Código",
      value: data.solicitation_data.code,
    },
    {
      title: "Data",
      value: data.solicitation_data.date,
    },
    {
      title: "Solicitante",
      value: EmptyData(data.solicitation_data.created_by),
    },
    {
      title: "Status",
      value: FormatStatus(data.solicitation_data.status),
    },
    {
      title: "Justificativa",
      value: EmptyData(data.solicitation_data.action_justification),
    },
  ];

  const formatField = (key: any, value: any) => {
    const fieldKeys: any = {
      marital_status: marital_status_e_social,
      race: race_e_social,
      education_level: education_level_e_social,
      gender: gender_e_social,
      nationality: country_e_social,
      country: country_e_social,
      father_country_of_birth: country_e_social,
      mother_country_of_birth: country_e_social,
      bank_account_type: type_of_account,

      business_unit_contract_type: contract_type_e_social,
      business_unit_job_security_type: security_type_e_social,
      contract_status: status_e_social,
      contract_nature: nature_e_social,
      contract_salary: salary_e_social,
      admission_type: admission_type_e_social,
      social_security_regime: social_security_regime_e_social,
      overtime_type: overtime_type_e_social,
      bank_code: banks_e_social,
      driver_license_quantity: level_cnh_e_social,
      degree_of_kinship: dependents_e_social,

      manager_id: options.managers,
      department_id: options.departments,
      job_role_id: options.job_role,
      union_id: options.union,
      health_insurance_id: options.health_insurance,
      food_voucher_id: options.food_voucher,
      business_unit_id: options.business_unit,
      sector_id: options.sector,
      work_section_id: options.work_section,
      cost_center_id: options.cost_center,
      managerial_cost_center_id: options.managerial_cost_center,
      category_id: options.work_category,
      work_schedule_id: options.work_schedule,
      salary_payment_method_id: options.salary_payment_method,
    };

    const mappedField =
      fieldKeys[key] &&
      fieldKeys[key].find((item: any) => item.value === value);

    return mappedField
      ? `${mappedField.value} - ${mappedField.label}`
      : FormatBool(value);
  };

  const solicitationHistories =
    data?.solicitation_data?.solicitation_histories?.length &&
    data?.solicitation_data?.solicitation_histories?.sort((a: any, b: any) => {
      const dateA: any = new Date(a.created_at);
      const dateB: any = new Date(b.created_at);

      return dateB - dateA;
    });

  const resend = async (resendActionCallback: any) => {
    resendActionCallback();
  };

  return (
    <form>
      <Grid container gap={2}>
        <Grid container justifyContent={"space-between"}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/'>
              Inicio
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/updateInformation'>
              Solicitações
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/updateInformation'>
              Alteração cadastral
            </Link>
            <Typography fontWeight={800}>Ver solicitação</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid container>
          <Callout.Root size='2' style={{ alignItems: "center" }}>
            <Callout.Icon>
              <InfoOutlined />
            </Callout.Icon>
            <Grid container>
              <Grid container flexDirection={"column"}>
                <Typography fontSize={16} fontWeight={800}>
                  Informações do colaborador
                </Typography>
                <Grid container gap={"12px 96px"} mt={1}>
                  {userInfo.map((item: any) => (
                    <Grid>
                      <Typography fontSize={12} fontWeight={400}>
                        {item.title}
                      </Typography>
                      <Typography fontSize={10} fontWeight={300}>
                        {item.value}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid container flexDirection={"column"}>
                <Typography fontSize={16} fontWeight={800} mt={2}>
                  Informações da solicitação
                </Typography>
                <Grid container gap={"12px 96px"} mt={1}>
                  {requestInfo.map((item: any) => (
                    <Grid>
                      <Typography fontSize={12} fontWeight={400}>
                        {item.title}
                      </Typography>
                      <Typography fontSize={10} fontWeight={300}>
                        {item.value}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Callout.Root>
        </Grid>
        <Grid container gap={2}>
          <Paper style={{ padding: 16, width: "100%" }}>
            <Typography fontSize={16} fontWeight={800}>
              Dados gerais solicitados para alteração
            </Typography>
            <Grid container gap={"12px 96px"} mt={2}>
              {Object.entries(data.record.payload).map(
                ([key, value]: any) =>
                  key !== "dependents" && (
                    <Grid>
                      <Typography fontSize={14} fontWeight={500}>
                        {fields.filter((item: any) => key === item.value)[0]
                          ?.label ?? "N/A"}
                      </Typography>
                      <Typography fontSize={12} fontWeight={400}>
                        {formatField(key, value)}
                      </Typography>
                    </Grid>
                  )
              )}
              {Object.entries(data.record.payload).map(
                ([key, value]: any) =>
                  key === "dependents" && (
                    <Grid container flexDirection={"column"}>
                      <Divider />
                      <Typography fontSize={16} fontWeight={800} mt={2}>
                        Dados de dependentes solicitados para alteração
                      </Typography>
                      {value.map((dep: any, index: any) => (
                        <Grid container gap={1} flexDirection={"column"}>
                          <Typography fontSize={14} fontWeight={500} mt={2}>
                            Dependente {index + 1}:
                          </Typography>
                          <Grid container gap={4}>
                            {Object.entries(dep).map(([key2, value2]: any) => (
                              <Grid>
                                <Typography fontSize={14} fontWeight={500}>
                                  {fields.filter(
                                    (item: any) => key2 === item.value
                                  )[0].label ?? "N/A"}
                                </Typography>
                                <Typography fontSize={12} fontWeight={400}>
                                  {formatField(key2, value2)}
                                </Typography>
                              </Grid>
                            ))}
                          </Grid>
                          {index + 1 < value.length && <Divider />}
                        </Grid>
                      ))}
                    </Grid>
                  )
              )}
            </Grid>
            <Grid mt={2} />
            <Divider />
            <Grid container mt={2}>
              <Typography fontSize={16} fontWeight={800}>
                Documentos anexados para comprovações
              </Typography>
            </Grid>
            <Grid container mt={2} gap={2}>
              {data.solicitation_data.files.map((file: any, index: number) => (
                <Tooltip title={"Baixar arquivo"}>
                  <a
                    title={file.name}
                    href={file.url}
                    target='_blank'
                    style={{
                      border: theme.palette.primary.main + " 1px solid",
                      color: theme.palette.primary.main,
                      padding: 8,
                      fontSize: 12,
                      borderRadius: 24,
                      fontWeight: 800,
                      textDecorationLine: "none",
                      display: "flex",
                      gap: 4,
                      alignItems: "center",
                    }}
                    download={file.name}
                    rel='noreferrer'>
                    <Download fontSize={"small"} />
                    {file.name}
                  </a>
                </Tooltip>
              ))}
              {data.solicitation_data.files.length === 0 && (
                <Typography>Não existem arquivos anexados</Typography>
              )}
            </Grid>
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <Button
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                fullWidth={isMobile}
                endIcon={<Undo />}>
                Voltar
              </Button>

              {isResendable && (
                <ActionResend
                  id={id}
                  url={"user_update_requests"}
                  hasObservation
                  onSubmit={resend}
                />
              )}

              <StatusPermittedByRoleContainer
                rhAllowedStatuses={[
                  "pending",
                  "waiting_manager_user",
                  "waiting_rh_user",
                  "accounting_return",
                ]}
                managerAllowedStatuses={["pending", "waiting_manager_user"]}
                status={data?.solicitation_data.status}>
                <ActionReturnedRule
                  rule={data?.rules?.approve ?? false}
                  id={id}
                  url={"user_update_requests"}
                />
                <ActionDisapprovedRule
                  rule={data?.rules?.approve ?? false}
                  id={id}
                  url={"user_update_requests"}
                />
              </StatusPermittedByRoleContainer>

              <StatusPermittedByRoleContainer
                rhAllowedStatuses={[
                  "pending",
                  "waiting_manager_user",
                  "waiting_rh_user",
                ]}
                managerAllowedStatuses={["pending", "waiting_manager_user"]}
                status={data?.solicitation_data.status}>
                <ActionAprovedRule
                  rule={data?.rules?.approve ?? false}
                  id={id}
                  url={"user_update_requests"}
                />
              </StatusPermittedByRoleContainer>

              <StatusPermittedByRoleContainer
                rhAllowedStatuses={["accounting_return"]}
                status={data?.solicitation_data.status}>
                <ActionResend
                  id={id}
                  url={"user_update_requests"}
                  hasObservation
                  onSubmit={resend}
                />
              </StatusPermittedByRoleContainer>
              <StatusPermittedByRoleContainer
                accountantAllowedStatuses={["approved"]}
                status={data?.solicitation_data.status}>
                <ActionCompleted
                  keyPrivate={"requestDetailsComplete"}
                  id={id}
                  url={"user_update_requests"}
                />

                <ActionAccountingDisapproved
                  keyPrivate={"requestDetailsComplete"}
                  id={id}
                  url={"processes"}
                />
              </StatusPermittedByRoleContainer>
            </Grid>
            {data?.solicitation_data?.solicitation_histories?.length > 0 && (
              <Typography fontSize={18} fontWeight={500}>
                Histórico do Desligamento
              </Typography>
            )}
            {console.log(
              data?.solicitation_data?.solicitation_histories,
              solicitationHistories
            )}
            {data?.solicitation_data?.solicitation_histories?.length > 0 &&
              solicitationHistories.map((history: any, index: number) => (
                <Accordion
                  sx={{
                    marginTop: 3.5,
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: 1,
                    "&:before": {
                      display: "none",
                    },
                  }}
                  expanded={expandedHistoricId === history?.id}
                  onChange={() => setExpandedHistoricId(history?.id)}>
                  <AccordionSummary
                    aria-controls='panel1d-content'
                    id='panel1d-header'
                    expandIcon={
                      <ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />
                    }
                    sx={{
                      margin: 0,
                      flexDirection: "row-reverse",
                      "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                        transform: "rotate(90deg)",
                      },
                    }}>
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='space-between'
                      width='100%'>
                      <Typography marginLeft={2} fontSize={18} fontWeight={500}>
                        {`${history.user_name} - ${FormatStatusToHistoric(
                          history.status
                        )}`}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid display='flex' flexDirection='column' gap={2}>
                      <Grid container gap={"12px 96px"}>
                        {Object.entries(history.new_data.payload).map(
                          ([key, value]: any) =>
                            key !== "dependents" && (
                              <Grid>
                                <Typography fontSize={14} fontWeight={500}>
                                  {fields.filter(
                                    (item: any) => key === item.value
                                  )[0]?.label ?? "N/A"}
                                </Typography>
                                <Typography fontSize={12} fontWeight={400}>
                                  {formatField(key, value)}
                                </Typography>
                              </Grid>
                            )
                        )}
                        {Object.entries(history.new_data.payload).map(
                          ([key, value]: any) =>
                            key === "dependents" && (
                              <Grid container flexDirection={"column"}>
                                <Divider />
                                <Typography
                                  fontSize={16}
                                  fontWeight={800}
                                  mt={2}>
                                  Dados de dependentes solicitados para
                                  alteração
                                </Typography>
                                {value.map((dep: any, index: any) => (
                                  <Grid
                                    container
                                    gap={1}
                                    flexDirection={"column"}>
                                    <Typography
                                      fontSize={14}
                                      fontWeight={500}
                                      mt={2}>
                                      Dependente {index + 1}:
                                    </Typography>
                                    <Grid container gap={4}>
                                      {Object.entries(dep).map(
                                        ([key2, value2]: any) => (
                                          <Grid>
                                            <Typography
                                              fontSize={14}
                                              fontWeight={500}>
                                              {fields.filter(
                                                (item: any) =>
                                                  key2 === item.value
                                              )[0].label ?? "N/A"}
                                            </Typography>
                                            <Typography
                                              fontSize={12}
                                              fontWeight={400}>
                                              {formatField(key2, value2)}
                                            </Typography>
                                          </Grid>
                                        )
                                      )}
                                    </Grid>
                                    {index + 1 < value.length && <Divider />}
                                  </Grid>
                                ))}
                              </Grid>
                            )
                        )}
                      </Grid>
                      {history.observations && (
                        <Grid
                          gridTemplateColumns={"1fr"}
                          display={"grid"}
                          gap={2}>
                          <TextField
                            fullWidth
                            label='Justificativa'
                            variant='outlined'
                            rows={4}
                            multiline={true}
                            disabled
                            value={history.observations}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))}
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
