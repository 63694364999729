/** @format */

import { AddCircle, Cancel } from "@mui/icons-material";
import {
  Button,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { DatePicker } from "@mui/x-date-pickers";
import {
  aviso_e_social,
  dependents_e_social,
  gender_e_social,
} from "../../../../../config/constants";
import { ClearCPF } from "../../../../../helpers/clearString";
import { FormatCurrency } from "../../../../../helpers/formatCurrency";
import { handleCPFChangeReturn } from "../../../../../helpers/handleCPFChange";
import { HiddenCheckChildren } from "../../../../../helpers/hiddenCheck";
import { GetInstance } from "../../../../../services/api";

export function Dependents({ formik, data }: any) {
  function Add() {
    const newArray = formik.values.dependents;
    newArray.push({
      name: "",
      mother_name: "",
      birth_date: null,
      degree_of_kinship: "",
      cpf: "",
      gender: "",
      ir: "",
      family_salary: "",
      is_disability: "",
    });
    formik.setFieldValue("dependents", newArray);
  }

  function Remove(id: number) {
    const newArray = formik.values.dependents;
    newArray.splice(id, 1);
    formik.setFieldValue("dependents", newArray);
  }

  return (
    <Grid container flexDirection={"column"}>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid id={"dependents"}>
          <Typography fontSize={16} fontWeight={500}>
            Dependentes
          </Typography>
        </Grid>
        {formik.values.dependents.map((item: any, index: number) => (
          <div id={"dependents"}>
            <Divider style={{ marginTop: 16 }} />
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}>
              <Typography fontSize={16} fontWeight={500}>
                Dependente {index + 1}
              </Typography>
              <Tooltip title={"Remover dependente"}>
                <IconButton onClick={() => Remove(index)} color={"error"}>
                  <Cancel />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid
              gridTemplateColumns={"1fr 1fr"}
              mt={2}
              display={"grid"}
              gap={2}>
              <TextField
                fullWidth
                id={`dependents[${index}].name`}
                label='Nome'
                value={formik.values.dependents[index].name}
                onChange={(event: any) => {
                  formik.setFieldValue(
                    `dependents[${index}].name`,
                    event.target.value
                  );
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <TextField
                fullWidth
                id={`dependents[${index}].mother_name`}
                label='Nome da mãe'
                value={formik.values.dependents[index].mother_name}
                onChange={(event: any) => {
                  formik.setFieldValue(
                    `dependents[${index}].mother_name`,
                    event.target.value
                  );
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.mother_name &&
                  Boolean(formik.errors.mother_name)
                }
                helperText={
                  formik.touched.mother_name && formik.errors.mother_name
                }
              />
            </Grid>
            <Grid
              gridTemplateColumns={"1fr 1fr 1fr 1fr"}
              mt={2}
              display={"grid"}
              gap={2}>
              <DatePicker
                slotProps={{
                  textField: {
                    fullWidth: true,
                    id: `dependents[${index}].birth_date`,
                    onBlur: formik.handleBlur,
                    error:
                      formik.touched.birth_date &&
                      Boolean(formik.errors.birth_date),
                    helperText:
                      formik.touched.birth_date && formik.errors.birth_date,
                  },
                }}
                label='Data de nascimento'
                value={formik.values.dependents[index].birth_date}
                onChange={(value: any) => {
                  formik.setFieldValue(
                    `dependents[${index}].birth_date`,
                    value
                  );
                }}
              />
              <TextField
                fullWidth
                id={`dependents[${index}].degree_of_kinship`}
                name={`dependents[${index}].degree_of_kinship`}
                select
                label='Parentesco'
                value={formik.values.dependents[index].degree_of_kinship}
                onChange={(event: any) => {
                  formik.setFieldValue(
                    `dependents[${index}].degree_of_kinship`,
                    event.target.value
                  );
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.degree_of_kinship &&
                  Boolean(formik.errors.degree_of_kinship)
                }
                helperText={
                  formik.touched.degree_of_kinship &&
                  formik.errors.degree_of_kinship
                }>
                {dependents_e_social.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value} - {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                id={`dependents[${index}].cpf`}
                label='CPF'
                placeholder={"000.000.000-00"}
                inputProps={{
                  maxLength: 14,
                }}
                value={formik.values.dependents[index].cpf}
                onChange={(event: any) => {
                  formik.setFieldValue(
                    `dependents[${index}].cpf`,
                    handleCPFChangeReturn(event)
                  );
                }}
                onBlur={() => {
                  GetInstance(
                    null,
                    "/users_cpf/" +
                      ClearCPF(formik.values.dependents[index].cpf)
                  )
                    .then((r: any) => {
                      if (!r.data.data.valid) {
                        formik.setFieldError(
                          `dependents[${index}].cpf`,
                          "CPF inválido!"
                        );
                      }
                    })
                    .catch((e: any) => console.log(e));
                }}
                error={formik.touched.cpf && Boolean(formik.errors.cpf)}
                helperText={formik.touched.cpf && formik.errors.cpf}
              />
              <TextField
                fullWidth
                id={`dependents[${index}].gender`}
                name={`dependents[${index}].gender`}
                select
                label='Sexo'
                value={formik.values.dependents[index].gender}
                onChange={(event: any) => {
                  formik.setFieldValue(
                    `dependents[${index}].gender`,
                    event.target.value
                  );
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.gender && Boolean(formik.errors.gender)}
                helperText={formik.touched.gender && formik.errors.gender}>
                {gender_e_social.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                id={`dependents[${index}].family_salary`}
                label='Renda familiar'
                placeholder={"R$0,00"}
                value={formik.values.dependents[index].family_salary}
                onChange={(event: any) => {
                  formik.setFieldValue(
                    `dependents[${index}].family_salary`,
                    FormatCurrency(event)
                  );
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.family_salary &&
                  Boolean(formik.errors.family_salary)
                }
                helperText={
                  formik.touched.family_salary && formik.errors.family_salary
                }
              />
              <TextField
                fullWidth
                id={`dependents[${index}].ir`}
                name={`dependents[${index}].ir`}
                select
                label='Imposto de renda'
                value={formik.values.dependents[index].ir}
                onChange={(event: any) => {
                  formik.setFieldValue(
                    `dependents[${index}].ir`,
                    event.target.value
                  );
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.ir && Boolean(formik.errors.ir)}
                helperText={formik.touched.ir && formik.errors.ir}>
                {aviso_e_social.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                id={`dependents[${index}].is_disability`}
                name={`dependents[${index}].is_disability`}
                select
                label='Incapaz'
                value={formik.values.dependents[index].is_disability}
                onChange={(event: any) => {
                  formik.setFieldValue(
                    `dependents[${index}].is_disability`,
                    event.target.value
                  );
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.is_disability &&
                  Boolean(formik.errors.is_disability)
                }
                helperText={
                  formik.touched.is_disability && formik.errors.is_disability
                }>
                {aviso_e_social.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Divider style={{ marginTop: 16 }} />
          </div>
        ))}
        <Grid mt={2}>
          <Button endIcon={<AddCircle />} onClick={Add} variant={"contained"}>
            Adicionar dependente
          </Button>
        </Grid>
      </HiddenCheckChildren>
    </Grid>
  );
}
