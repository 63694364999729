/** @format */

import { Edit, InfoOutlined, Undo } from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { Callout } from "@radix-ui/themes";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import { ConfigSelection } from "../../../../components/configSelection";
import { GenericError } from "../../../../helpers/genericError";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../services/api";

const validationSchema = yup.object().shape({
  salary_review_min_percent: yup.number().required("Campo obrigatório"),
  salary_review_max_percent: yup
    .number()
    .required("Campo obrigatório")
    .when("salary_review_min_percent", ([salary_review_min_percent], schema) =>
      schema.min(
        salary_review_min_percent,
        "O percentual máximo deve ser maior ou igual ao percentual mínimo"
      )
    ),
});

export function PermissionssalaryReview() {
  const [leftApprovers, setLeftApprovers]: any = useState([]);
  const [rightApprovers, setRightApprovers]: any = useState([]);
  const [options, setOptions]: any = useState([]);
  const dispatch = useDispatch();

  const formik: any = useFormik({
    initialValues: {
      salary_review_min_percent: 0,
      salary_review_max_percent: 0,
    },
    validationSchema: validationSchema,
    onSubmit: async (values: any) => {
      dispatch(updateLoading(true));
      PutInstante(
        null,
        {
          salary_review_requisitions_rules: {
            approvers: rightApprovers,
          },
          salary_review_min_percent: values.salary_review_min_percent,
          salary_review_max_percent: values.salary_review_max_percent,
        },
        "/company_settings"
      )
        .then((response: any) => {
          dispatch(updateLoading(false));
          toast.success(response?.data?.message);
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    GetInstance({}, "/company_settings/")
      .then(async (response: any) => {
        formik.setValues({
          salary_review_max_percent:
            response.data.data.record.salary_review_max_percent,
          salary_review_min_percent:
            response.data.data.record.salary_review_min_percent,
        });
        GetInstance({}, "/users/options?settings=true")
          .then(async (response2: any) => {
            dispatch(updateLoading(false));
            setOptions(response2.data.data);
            const arrayIds = response2.data.data.map((opt: any) => opt.value);
            if (
              Object.keys(
                response.data.data.record.salary_review_requisitions_rules
              ).length > 0
            ) {
              if (
                response.data.data.record.salary_review_requisitions_rules
                  .approvers
              ) {
                const resultOpt = arrayIds.filter(
                  (item: any) =>
                    !response.data.data.record.salary_review_requisitions_rules.approvers.includes(
                      item
                    )
                );
                setRightApprovers(
                  response.data.data.record.salary_review_requisitions_rules
                    .approvers
                );
                setLeftApprovers(resultOpt);
              }
            } else {
              setLeftApprovers(arrayIds);
            }
          })
          .catch((e: any) => {
            if (e.code !== "ERR_CANCELED") {
              dispatch(updateLoading(false));
              toast.error(
                e?.response?.data?.message ??
                  "Erro inesperado, tente novamente."
              );
            }
          });
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form>
      <Grid container gap={2}>
        <Grid container justifyContent={"space-between"}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/'>
              Inicio
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/settings/salaryReview'>
              Configurações
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/settings/salaryReview'>
              Revisão salarial
            </Link>
            <Typography fontWeight={800}>Ver detalhes</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid container>
          <Callout.Root size='2' style={{ alignItems: "center" }}>
            <Callout.Icon>
              <InfoOutlined />
            </Callout.Icon>
            <Grid container flexDirection={"column"}>
              <Typography fontSize={16} fontWeight={400}>
                Nesta tela é permitido a configuração de usuários com permissão
                aos fluxos de revisão salarial e suas configurações da empresa.
              </Typography>
            </Grid>
          </Callout.Root>
        </Grid>
        <Paper sx={{ width: "100%", height: "auto" }}>
          <Grid gridTemplateColumns={"1fr"} display={"grid"} m={2} gap={2}>
            <Typography fontSize={14} fontWeight={500}>
              Configurações
            </Typography>
          </Grid>
          <Grid
            gridTemplateColumns={"1fr 1fr 1fr 1fr"}
            display={"grid"}
            m={2}
            gap={2}>
            <TextField
              fullWidth
              id={`salary_review_min_percent`}
              type='number'
              label='Porcentagem mínima de salário'
              variant='outlined'
              value={formik.values.salary_review_min_percent}
              onChange={(event: any) => {
                formik.setFieldValue(
                  `salary_review_min_percent`,
                  event.target.value
                );
              }}
              onBlur={formik.handleBlur}
              error={Boolean(formik.errors.salary_review_min_percent)}
              helperText={formik.errors.salary_review_min_percent}
            />
            <TextField
              fullWidth
              id={`salary_review_max_percent`}
              type='number'
              label='Porcentagem máxima de salário'
              variant='outlined'
              value={formik.values.salary_review_max_percent}
              onChange={(event: any) => {
                formik.setFieldValue(
                  `salary_review_max_percent`,
                  event.target.value
                );
              }}
              onBlur={formik.handleBlur}
              error={Boolean(formik.errors.salary_review_max_percent)}
              helperText={formik.errors.salary_review_max_percent}
            />
          </Grid>
        </Paper>
        <Grid container>
          <Callout.Root size='2' style={{ alignItems: "center" }}>
            <Callout.Icon>
              <InfoOutlined />
            </Callout.Icon>
            <Grid container flexDirection={"column"}>
              <Typography fontSize={16} fontWeight={400} mt={1}>
                Para realizar esse procedimento, é só selecionar os itens que
                deseja incluir no lado esquerdo, enviar para o lado direito. e
                para remover é só realizar o processo contrário, após a
                conclusão da personalização, clique em 'SALVAR MODIFICAÇÕES'
                para concluir.
              </Typography>
              <Typography fontSize={16} fontWeight={800} mt={1}>
                Esse procedimento afeta as ações: VER DETALHES, APROVAR E
                REPROVAR.
              </Typography>
            </Grid>
          </Callout.Root>
        </Grid>

        <ConfigSelection
          leftLabel='Usuários sem permissão:'
          rightLabel='Usuários com permissão:'
          left={leftApprovers}
          right={rightApprovers}
          options={options}
          setLeft={setLeftApprovers}
          setRight={setRightApprovers}
        />

        <Grid container gap={2} justifyContent={"end"} mt={2}>
          <Button
            variant={"outlined"}
            onClick={() => {
              window.history.back();
            }}
            endIcon={<Undo />}>
            Voltar
          </Button>
          <Button
            variant={"contained"}
            onClick={formik.handleSubmit}
            endIcon={<Edit />}>
            Salvar modificações
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
