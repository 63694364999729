import { useSelector } from "react-redux";

export const StatusPermittedByRoleContainer = ({
  status,
  collaboratorAllowedStatuses = [],
  rhAllowedStatuses = [],
  managerAllowedStatuses = [],
  accountantAllowedStatuses = [],
  children
}: any) => {
  const user = useSelector((state: any) => state.user.data);

  const hasPermission =
    (user.role === "accountant" && accountantAllowedStatuses.includes(status)) ||
    (user.role === "rh" && rhAllowedStatuses.includes(status)) ||
    (user.role === "manager" && managerAllowedStatuses.includes(status)) ||
    (user.role === "collaborator" && collaboratorAllowedStatuses.includes(status));

  return hasPermission ? <>{children}</> : null;
};
