/** @format */

import {
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import {
  ClearCurrency,
  FormatCurrency,
  MaskCurrency,
} from "../../../../../helpers/formatCurrency";
import { ReadjustSalary } from "../../../../../helpers/readjustSalary";

export function FormEdit({ formik, data, edit }: any) {
  const onChangePosition = (position_id: any) => {
    const position = data.options.positions.find(
      (position: any) => position.id === position_id
    );
    formik.setFieldValue("position_id", position_id);
    formik.setFieldValue("department_id", position.department_id);
    formik.setFieldValue("job_role_id", position.job_role_id);
    formik.setFieldValue("sector_id", position.sector_id);
    formik.setFieldValue("cost_center_id", position.cost_center_id);
    formik.setFieldValue("business_unit_id", position.business_unit_id);
  };
  return (
    <>
      <Grid gridTemplateColumns={"1fr 1fr"} display={"grid"} gap={2}>
        <FormControlLabel
          disabled
          id={"with_position"}
          checked={formik.values.with_position}
          onChange={(event: any) => {
            formik.setFieldValue("with_position", event.target.checked);
          }}
          control={<Checkbox />}
          label='Transferência com a posição ?'
        />
        <FormControlLabel
          id={"with_promotion"}
          checked={formik.values.with_promotion}
          disabled={!edit}
          onChange={(event: any) => {
            formik.setFieldValue("with_promotion", event.target.checked);
          }}
          control={<Checkbox />}
          label='Transferência com promoção ?'
        />
      </Grid>
      <Grid gridTemplateColumns={"1fr 1fr"} mt={2} display={"grid"} gap={2}>
        <TextField
          fullWidth
          disabled
          value={data.transfer_data.new_manager_name}
          label='Gerente de destino'
        />

        {!formik.values.with_position ? (
          <TextField
            fullWidth
            id='position_id'
            select
            label='Posição destino'
            disabled={!edit}
            value={formik.values.position_id}
            onChange={(event: any) => onChangePosition(event.target.value)}
            onBlur={formik.handleBlur}
            error={
              formik.touched.position_id && Boolean(formik.errors.position_id)
            }
            helperText={
              formik.touched.position_id && formik.errors.position_id
            }>
            {data.options.positions.map((position: any) => (
              <MenuItem key={position.id} value={position.id}>
                {position.code} {position.job_role_name}
              </MenuItem>
            ))}
          </TextField>
        ) : (
          <TextField
            fullWidth
            disabled
            value={`${data.transfer_data.position_code} - ${data.transfer_data.position_job_role_name}`}
            label='Posição destino'
          />
        )}

        <TextField
          fullWidth
          id='department_id'
          select
          disabled={!edit}
          label='Departamento Destino'
          value={formik.values.department_id}
          onChange={(event: any) =>
            formik.setFieldValue("department_id", event.target.value)
          }
          onBlur={formik.handleBlur}
          error={
            formik.touched.department_id && Boolean(formik.errors.department_id)
          }
          helperText={
            formik.touched.department_id && formik.errors.department_id
          }>
          {data.options.department.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          id='job_role_id'
          select
          disabled={!edit}
          label='Cargo destino'
          value={formik.values.job_role_id}
          onChange={(event: any) =>
            formik.setFieldValue("job_role_id", event.target.value)
          }
          onBlur={formik.handleBlur}
          error={
            formik.touched.job_role_id && Boolean(formik.errors.job_role_id)
          }
          helperText={formik.touched.job_role_id && formik.errors.job_role_id}>
          {data.options.job_role.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid gridTemplateColumns={"1fr 1fr"} mt={2} display={"grid"} gap={2}>
        <DatePicker
          slotProps={{
            textField: {
              fullWidth: true,
              id: "transfer_date",
              onBlur: formik.handleBlur,
              error:
                formik.touched.transfer_date &&
                Boolean(formik.errors.transfer_date),
              helperText:
                formik.touched.transfer_date && formik.errors.transfer_date,
            },
          }}
          disabled
          label='Data da movimentação'
          value={formik.values.transfer_date}
          onChange={(value: any) => {
            formik.setFieldValue("transfer_date", value);
          }}
        />
        <TextField
          fullWidth
          id='business_unit_id'
          select
          disabled={!edit}
          label='Un. de negócio destino'
          value={formik.values.business_unit_id}
          onChange={(event: any) =>
            formik.setFieldValue("business_unit_id", event.target.value)
          }
          onBlur={formik.handleBlur}
          error={
            formik.touched.business_unit_id &&
            Boolean(formik.errors.business_unit_id)
          }
          helperText={
            formik.touched.business_unit_id && formik.errors.business_unit_id
          }>
          {data.options.business_unit.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid gridTemplateColumns={"1fr 1fr"} mt={2} display={"grid"} gap={2}>
        <TextField
          fullWidth
          id='sector_id'
          select
          disabled={!edit}
          label='Setor / Lotação destino'
          value={formik.values.sector_id}
          onChange={(event: any) =>
            formik.setFieldValue("sector_id", event.target.value)
          }
          onBlur={formik.handleBlur}
          error={formik.touched.sector_id && Boolean(formik.errors.sector_id)}
          helperText={formik.touched.sector_id && formik.errors.sector_id}>
          {data.options.sector.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          id='cost_center_id'
          select
          disabled={!edit}
          label='Centro de custo destino'
          value={formik.values.cost_center_id}
          onChange={(event: any) =>
            formik.setFieldValue("cost_center_id", event.target.value)
          }
          onBlur={formik.handleBlur}
          error={
            formik.touched.cost_center_id &&
            Boolean(formik.errors.cost_center_id)
          }
          helperText={
            formik.touched.cost_center_id && formik.errors.cost_center_id
          }>
          {data.options.cost_center.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid gridTemplateColumns={"1fr 1fr"} mt={2} display={"grid"} gap={2}>
        <TextField
          fullWidth
          id='new_remuneration_percentage'
          type={"number"}
          disabled={!edit}
          label='Porcentagem (%)'
          value={formik.values.new_remuneration_percentage}
          onChange={(event: any) => {
            formik.setFieldValue(
              "new_remuneration_percentage",
              event.target.value
            );
            formik.setFieldValue(
              "new_remuneration_value",
              MaskCurrency(
                ((parseFloat(event.target.value) + 100) *
                  parseFloat(formik.values.actual_salary)) /
                  100
              )
            );
          }}
          onBlur={formik.handleBlur}
          error={
            formik.touched.new_remuneration_percentage &&
            Boolean(formik.errors.new_remuneration_percentage)
          }
          helperText={
            formik.touched.new_remuneration_percentage &&
            formik.errors.new_remuneration_percentage
          }
        />
        <TextField
          fullWidth
          id='new_remuneration_value'
          disabled={!edit}
          label='Salário futuro'
          value={formik.values.new_remuneration_value}
          onChange={(event: any) => {
            formik.setFieldValue(
              "new_remuneration_percentage",
              ReadjustSalary(
                Number(formik.values.actual_salary),
                ClearCurrency(FormatCurrency(event))
              )
            );
            formik.setFieldValue(
              "new_remuneration_value",
              FormatCurrency(event)
            );
          }}
          onBlur={formik.handleBlur}
          error={
            formik.touched.new_remuneration_value &&
            Boolean(formik.errors.new_remuneration_value)
          }
          helperText={
            formik.touched.new_remuneration_value &&
            formik.errors.new_remuneration_value
          }
        />
      </Grid>
      <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
        <div>
          <TextField
            fullWidth
            id='observations'
            label='Comentários'
            disabled={!edit}
            variant='outlined'
            multiline={true}
            rows={4}
            value={formik.values.observations}
            onChange={(event: any) =>
              formik.setFieldValue("observations", event.target.value)
            }
            onBlur={formik.handleBlur}
            error={
              formik.touched.observations && Boolean(formik.errors.observations)
            }
            helperText={
              formik.touched.observations && formik.errors.observations
            }
          />
        </div>
      </Grid>
    </>
  );
}
