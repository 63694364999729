/** @format */

import { EmptyData } from "../helpers/emptyData";
import { FormatStatus } from "../helpers/formatStatus";

export interface UserProps {
  position_name: string;
  remuneration_value: any;
  name: string;
  job_role_code: string;
  job_role_name: string;
  status: string;
}
export const userInfo = (user: UserProps) => [
  {
    title: "Nome",
    value: user.name,
  },
  {
    title: "Cód. cargo",
    value: EmptyData(user.job_role_code),
  },
  {
    title: "Cargo",
    value: EmptyData(user.job_role_name),
  },
  {
    title: "Posição",
    value: EmptyData(user.position_name),
  },
  {
    title: "Salário atual",
    value: EmptyData(user.remuneration_value),
  },
  {
    title: "Status",
    value: FormatStatus(user.status),
  },
];
