/** @format */

import { Cancel, CheckCircle, Send } from "@mui/icons-material";
import { Grid, TextField } from "@mui/material";
import { AlertDialog, Flex } from "@radix-ui/themes";
import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import { GenericError } from "../../helpers/genericError";
import { updateLoading } from "../../redux/reducers/common";
import { PostInstante } from "../../services/api";
import { ButtonComponent } from "./buttonComponent";

interface Props {
  id: any;
  url: string;
  disabled?: boolean;
  hasObservation?: boolean;
  onSubmit: any;
  close?: () => void;
}

const validationSchema = yup.object({
  action_justification: yup.string().nullable(),
});

export function ActionResend({
  id,
  url,
  disabled,
  hasObservation = false,
  onSubmit,
  close,
}: Props) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const formik: any = useFormik({
    initialValues: {
      action_justification: "",
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      close && close();
      setOpen(false);
      onSubmit(solicitationSubmit);
    },
  });

  const solicitationSubmit = () => {
    dispatch(updateLoading(true));
    PostInstante(
      null,
      {
        action_type: "resend",
        action_justification: formik.values.action_justification,
      },
      "/" + url + "/" + id + "/action"
    )
      .then((response: any) => {
        dispatch(updateLoading(false));
        toast.success(response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
  };

  return (
    <form>
      <AlertDialog.Root open={open} onOpenChange={setOpen}>
        <AlertDialog.Trigger>
          <ButtonComponent
            disabled={disabled}
            variant={"contained"}
            color={"success"}
            endIcon={<Send />}>
            Reenviar
          </ButtonComponent>
        </AlertDialog.Trigger>
        <AlertDialog.Content maxWidth='450px'>
          <AlertDialog.Title>
            Deseja realmente reenviar esta solicitação?
          </AlertDialog.Title>
          <AlertDialog.Description size='2'>
            Após a confirmação, não será possivel reverter o processo. Tenha
            absoluta certeza antes de realizar essa ação.
          </AlertDialog.Description>
          {hasObservation && (
            <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
              <div>
                <TextField
                  fullWidth
                  id='action_justification'
                  label='Comentário'
                  variant='outlined'
                  multiline={true}
                  rows={4}
                  value={formik.values.action_justification}
                  onChange={(event: any) =>
                    formik.setFieldValue(
                      "action_justification",
                      event.target.value
                    )
                  }
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.action_justification &&
                    Boolean(formik.errors.action_justification)
                  }
                  helperText={
                    formik.touched.action_justification &&
                    formik.errors.action_justification
                  }
                />
              </div>
            </Grid>
          )}
          <Flex gap='3' mt='4' justify='end'>
            <AlertDialog.Cancel>
              <ButtonComponent
                color='error'
                variant={"outlined"}
                endIcon={<Cancel />}>
                Desistir
              </ButtonComponent>
            </AlertDialog.Cancel>
            <AlertDialog.Action>
              <ButtonComponent
                color='success'
                variant={"contained"}
                onClick={formik.handleSubmit}
                endIcon={<CheckCircle />}>
                Confirmar
              </ButtonComponent>
            </AlertDialog.Action>
          </Flex>
        </AlertDialog.Content>
      </AlertDialog.Root>
    </form>
  );
}
