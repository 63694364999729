/** @format */

import { AddCircle, Undo } from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import * as yup from "yup";
import { routers } from "../../../../../config/routers";

const validationSchema = yup.object({
  name: yup.string().nullable().required("Nome é obrigatório"),
  permissions: yup
    .array()
    .nullable()
    .min(1, "Precisa ter pelo menos 1 permissão"),
});

export function PermissionGroupNew() {
  const formik: any = useFormik({
    initialValues: {
      name: "",
      permissions: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert("Em breve");
    },
  });

  const handleChange = (id: string) => {
    if (formik.values.permissions.indexOf(id) === -1) {
      const results = formik.values.permissions;
      results.push(id);
      formik.setFieldValue("permissions", results);
    } else {
      const results = formik.values.permissions;
      const index = results.indexOf(id);
      results.splice(index, 1);
      formik.setFieldValue("permissions", results);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <Grid container justifyContent={"space-between"}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/'>
              Inicio
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/settings/group'>
              Configurações
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/settings/group'>
              Grupo
            </Link>
            <Typography fontWeight={800}>Novo grupo</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Criar novo grupo de permissão
              </Typography>
            </Grid>
            <Grid gridTemplateColumns={"1fr"} display={"grid"} gap={2}>
              <TextField
                fullWidth
                id='function'
                label='Nome'
                variant='outlined'
                value={formik.values.name}
                onChange={(event: any) =>
                  formik.setFieldValue("name", event.target.value)
                }
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid mt={2}>
              <Typography
                color={formik.errors.permissions ? "error" : "inherit"}
                fontSize={16}
                fontWeight={400}>
                Configurações
              </Typography>
              {formik.errors.permissions && (
                <Grid container mt={0.5} ml={2}>
                  <Typography
                    fontSize={"0.75rem"}
                    textAlign={"center"}
                    color={"error"}>
                    {formik.errors.permissions}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid gridTemplateColumns={"1fr"} ml={2} display={"grid"}>
              {routers.map((option: any) => (
                <FormControlLabel
                  label={option.label}
                  slotProps={{
                    typography: {
                      color: formik.errors.name ? "error" : "inherit",
                    },
                  }}
                  onBlur={formik.handleBlur}
                  control={
                    <Checkbox
                      checked={
                        formik.values.permissions.indexOf(option?.id) !== -1
                      }
                      onChange={() => handleChange(option.id)}
                    />
                  }
                />
              ))}
            </Grid>
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <Button
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                endIcon={<Undo />}>
                Voltar
              </Button>
              <Button
                variant={"contained"}
                type={"submit"}
                endIcon={<AddCircle />}>
                Cadastrar
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
