/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

import { useEffect, useState } from "react";
import { GenericError } from "../../../helpers/genericError";
import { updateLoading } from "../../../redux/reducers/common";
import { GetInstance } from "../../../services/api";

export function UserGetAll(dispatch: any) {
  const [data, setData] = useState([]);
  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({}, "users/options")
      .then((response) => {
        dispatch(updateLoading(false));
        setData(response.data.data);
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { users: data };
}
