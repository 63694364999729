/** @format */

import { AddCircle, Undo } from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  Grid,
  Link,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { position_type } from "../../../../../config/constants";
import {
  ClearCurrency,
  FormatCurrency,
} from "../../../../../helpers/formatCurrency";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance, PostInstante } from "../../../../../services/api";

const validationSchema = yup.object({
  function: yup.string().nullable().required("Nome da função é obrigatório"),
  quantity: yup
    .number()
    .nullable()
    .min(1, "Quantidate mínima: 1")
    .required("Quantidade é obrigatório"),
  description: yup
    .string()
    .nullable()
    .required("Responsabilidades é obrigatório"),
  reason_solicitation_id: yup
    .string()
    .nullable()
    .required("Esse campo é obrigatório"),
  manager_id: yup.string().nullable().required("Esse campo é obrigatório"),
  business_unit_id: yup
    .string()
    .nullable()
    .required("Esse campo é obrigatório"),
  cost_center_id: yup.string().nullable().required("Esse campo é obrigatório"),
});

export function PositionNew() {
  const router = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state: any) => state.user.data);

  const [costCenters, setCostCenters] = useState([]);
  const [businessUnit, setBusinessUnit] = useState([]);
  const [reasonSolicitation, setReasonSolicitation] = useState([]);
  const [managers, setManagers] = useState([]);
  const [unitBusiness, setUnitBusiness] = useState<any>([]);
  // const [openModalDismissal, setOpenModalDismissal] = useState(false);
  const [options, setOptions] = useState<any>([]);
  const formik = useFormik({
    initialValues: {
      function: "",
      salary: "",
      quantity: "",
      description: "",
      business_unit_id: "",
      cost_center_id: "",
      reason_solicitation_id: "",
      manager_id: "",
      department_id: "",
      job_role_id: "",
      position_type: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      PostInstante(
        null,
        {
          ...values,
          salary: ClearCurrency(values.salary),
        },
        "/new_positions"
      )
        .then((response: any) => {
          dispatch(updateLoading(false));
          router("/request/position");
          toast.success(response?.data?.message);
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    Promise.all([
      GetInstance(null, "/new_positions/cost_center_by_company"),
      GetInstance(null, "/new_positions/department_by_company"),
      GetInstance(null, "/reason_solicitations"),
      GetInstance(null, "/new_positions/manager_by_company"),
    ])
      .then((res) => {
        setCostCenters(res[0].data);
        setBusinessUnit(res[1].data);
        const reasons = res[2].data.filter((r: any) => r.associated);
        setReasonSolicitation(reasons);
        setManagers(res[3].data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    GetInstance({}, "/new_positions/business_unit_by_company")
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        setUnitBusiness(response.data);
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    GetInstance({}, "/personnel_requisitions_form")
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        setOptions(response.data);
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user?.role === "manager") {
      formik.setFieldValue("manager_id", user.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <Grid container justifyContent={"space-between"}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/'>
              Inicio
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/position'>
              Solicitações
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/position'>
              Posições
            </Link>
            <Typography fontWeight={800}>Nova solicitação</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Criar nova posição
              </Typography>
            </Grid>
            <Grid gridTemplateColumns={"1fr 1fr 1fr"} display={"grid"} gap={2}>
              <TextField
                fullWidth
                id='manager_id'
                select
                label='Gestor'
                disabled={user.role === "manager"}
                value={
                  user.role === "manager" ? user.id : formik.values?.manager_id
                }
                onChange={(event: any) =>
                  formik.setFieldValue("manager_id", event.target.value)
                }
                onBlur={formik.handleBlur}
                error={
                  formik.touched.manager_id && Boolean(formik.errors.manager_id)
                }
                helperText={
                  formik.touched.manager_id && formik.errors.manager_id
                }>
                {managers.map((option: any) => (
                  <MenuItem value={option.id}>{option?.name}</MenuItem>
                ))}
              </TextField>

              <TextField
                fullWidth
                id='department_id'
                select
                label='Departamento'
                value={formik.values.department_id}
                onChange={(event: any) =>
                  formik.setFieldValue("department_id", event.target.value)
                }
                onBlur={formik.handleBlur}
                error={
                  formik.touched.department_id &&
                  Boolean(formik.errors.department_id)
                }
                helperText={
                  formik.touched.department_id && formik.errors.department_id
                }>
                {businessUnit?.map((option: any) => (
                  <MenuItem value={option?.id}>
                    {option?.code} {option?.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                id='cost_center_id'
                select
                label='Centro de custo'
                value={formik.values.cost_center_id}
                onChange={(event: any) =>
                  formik.setFieldValue("cost_center_id", event.target.value)
                }
                onBlur={formik.handleBlur}
                error={
                  formik.touched.cost_center_id &&
                  Boolean(formik.errors.cost_center_id)
                }
                helperText={
                  formik.touched.cost_center_id && formik.errors.cost_center_id
                }>
                {costCenters.map((option: any) => (
                  <MenuItem value={option.id}>
                    {option.code} {option.description}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid
              gridTemplateColumns={"1fr 1fr 1fr"}
              mt={2}
              display={"grid"}
              gap={2}>
              <TextField
                fullWidth
                id='function'
                label='Função'
                placeholder={"Nome da função"}
                variant='outlined'
                value={formik.values.function}
                onChange={(event: any) =>
                  formik.setFieldValue("function", event.target.value)
                }
                onBlur={formik.handleBlur}
                error={
                  formik.touched.function && Boolean(formik.errors.function)
                }
                helperText={formik.touched.function && formik.errors.function}
              />
              <TextField
                fullWidth
                id='quantity'
                type='number'
                label='Quantidade'
                variant='outlined'
                value={formik.values.quantity}
                onChange={(event: any) =>
                  formik.setFieldValue("quantity", event.target.value)
                }
                onBlur={formik.handleBlur}
                error={
                  formik.touched.quantity && Boolean(formik.errors.quantity)
                }
                helperText={formik.touched.quantity && formik.errors.quantity}
              />
              <TextField
                fullWidth
                id='business_unit_id'
                select
                label='Unidade de negócio'
                value={formik.values?.business_unit_id}
                onChange={(event: any) =>
                  formik.setFieldValue("business_unit_id", event.target.value)
                }
                onBlur={formik.handleBlur}
                error={
                  formik.touched.business_unit_id &&
                  Boolean(formik.errors.business_unit_id)
                }
                helperText={
                  formik.touched.business_unit_id &&
                  formik.errors.business_unit_id
                }>
                {unitBusiness?.map((option: any) => (
                  <MenuItem value={option.id}>
                    {option.name} - {option.code}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid
              mt={2}
              gridTemplateColumns={"1fr 1fr 1fr"}
              display={"grid"}
              gap={2}>
              {user.role === "rh" ? (
                <>
                  <TextField
                    fullWidth
                    id='job_role_id'
                    select
                    label='Cargo'
                    disabled={user.role === "manager"}
                    value={
                      user.role === "manager"
                        ? user.id
                        : formik.values?.job_role_id
                    }
                    onChange={(event: any) =>
                      formik.setFieldValue("job_role_id", event.target.value)
                    }
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.job_role_id &&
                      Boolean(formik.errors.job_role_id)
                    }
                    helperText={
                      formik.touched.job_role_id && formik.errors.job_role_id
                    }>
                    {options?.data?.options
                      ? options?.data.options.job_role?.map((option: any) => (
                          <MenuItem value={option.value}>
                            {option?.label}
                          </MenuItem>
                        ))
                      : null}
                  </TextField>
                  <TextField
                    fullWidth
                    id='salary'
                    label='Remuneração'
                    variant='outlined'
                    value={formik.values.salary}
                    onChange={(event: any) => {
                      formik.setFieldValue("salary", FormatCurrency(event));
                    }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.salary && Boolean(formik.errors.salary)
                    }
                    helperText={formik.touched.salary && formik.errors.salary}
                  />
                </>
              ) : null}
              <TextField
                fullWidth
                id='reason_solicitation_id'
                select
                label='Motivo da solicitação'
                value={formik.values.reason_solicitation_id}
                onChange={(event: any) =>
                  formik.setFieldValue(
                    "reason_solicitation_id",
                    event.target.value
                  )
                }
                onBlur={formik.handleBlur}
                error={
                  formik.touched.reason_solicitation_id &&
                  Boolean(formik.errors.reason_solicitation_id)
                }
                helperText={
                  formik.touched.reason_solicitation_id &&
                  formik.errors.reason_solicitation_id
                }>
                {reasonSolicitation.map((option: any) => (
                  <MenuItem value={option.id}>{option?.name}</MenuItem>
                ))}
              </TextField>

              <TextField
                fullWidth
                id='position_type'
                select
                label='Tipo de posição'
                value={formik.values.position_type}
                onChange={(event: any) =>
                  formik.setFieldValue(
                    "position_type",
                    parseInt(event.target.value)
                  )
                }
                onBlur={formik.handleBlur}
                error={
                  formik.touched.position_type &&
                  Boolean(formik.errors.position_type)
                }
                helperText={
                  formik.touched.position_type && formik.errors.position_type
                }>
                {position_type.map((option: any) => (
                  <MenuItem value={option.value}>{option?.label}</MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
              <div>
                <TextField
                  fullWidth
                  id='description'
                  label='Responsabilidades'
                  variant='outlined'
                  multiline={true}
                  rows={4}
                  value={formik.values.description}
                  onChange={(event: any) =>
                    formik.setFieldValue("description", event.target.value)
                  }
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
              </div>
            </Grid>
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <Button
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                fullWidth={isMobile}
                endIcon={<Undo />}>
                Voltar
              </Button>
              <Button
                fullWidth={isMobile}
                variant={"contained"}
                type={"submit"}
                endIcon={<AddCircle />}>
                Cadastrar
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
