/** @format */

import { Edit, InfoOutlined, Undo } from "@mui/icons-material";
import { Breadcrumbs, Button, Grid, Link, Typography } from "@mui/material";
import { Callout } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { ConfigSelection } from "../../../../components/configSelection";
import { fields } from "../../../../config/fields";
import { GenericError } from "../../../../helpers/genericError";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../services/api";

export function PermissionsUpdateInformation() {
  const [leftApprovers, setLeftApprovers]: any = useState([]);
  const [rightApprovers, setRightApprovers]: any = useState([]);
  const [left, setLeft]: any = useState([]);
  const [right, setRight]: any = useState([]);
  const [leftRH, setLeftRH]: any = useState([]);
  const [rightRH, setRightRH]: any = useState([]);
  const [leftCollaborator, setLeftCollaborator]: any = useState([]);
  const [rightCollaborator, setRightCollaborator]: any = useState([]);
  const [leftManager, setLeftManager]: any = useState([]);
  const [rightManager, setRightManager]: any = useState([]);
  const [options, setOptions]: any = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    GetInstance({}, "/company_settings/")
      .then((response: any) => {
        const allOpt: any = [];
        fields.map(
          (item: any) => item?.hidden !== true && allOpt.push(item.value)
        );
        const allOptRequired: any = [];
        fields.map(
          (item: any) =>
            item?.hidden !== true &&
            item?.tab !== true &&
            item?.notRequiredCheck !== true &&
            allOptRequired.push(item.value)
        );
        const resultOpt = allOptRequired.filter(
          (item: any) =>
            !response.data.data.record.fields_with_required_documents.includes(
              item
            )
        );
        setRight(response.data.data.record.fields_with_required_documents);
        setLeft(resultOpt);
        // campos ocultos colaborador
        if (response.data.data.record.user_form_hidden_fields.collaborator) {
          const resultOptCollaborator = allOpt.filter(
            (item: any) =>
              !response.data.data.record.user_form_hidden_fields.collaborator.includes(
                item
              )
          );
          setLeftCollaborator(
            response.data.data.record.user_form_hidden_fields.collaborator
          );
          setRightCollaborator(resultOptCollaborator);
        } else {
          setRightCollaborator(allOpt);
        }
        // campos ocultos gestor
        if (response.data.data.record.user_form_hidden_fields.manager) {
          const resultOptManager = allOpt.filter(
            (item: any) =>
              !response.data.data.record.user_form_hidden_fields.manager.includes(
                item
              )
          );
          setLeftManager(
            response.data.data.record.user_form_hidden_fields.manager
          );
          setRightManager(resultOptManager);
        } else {
          setRightManager(allOpt);
        }
        // campos ocultos rh
        if (response.data.data.record.user_form_hidden_fields.rh) {
          const resultOptRH = allOpt.filter(
            (item: any) =>
              !response.data.data.record.user_form_hidden_fields.rh.includes(
                item
              )
          );
          setLeftRH(response.data.data.record.user_form_hidden_fields.rh);
          setRightRH(resultOptRH);
        } else {
          setRightRH(allOpt);
        }
        GetInstance({}, "/users/options?settings=true")
          .then(async (response2: any) => {
            dispatch(updateLoading(false));
            setOptions(response2.data.data);
            const arrayIds = response2.data.data.map((opt: any) => opt.value);
            if (
              Object.keys(response.data.data.record.user_update_request_rules)
                .length > 0
            ) {
              if (
                response.data.data.record.user_update_request_rules.approvers
              ) {
                const resultOpt = arrayIds.filter(
                  (item: any) =>
                    !response.data.data.record.user_update_request_rules.approvers.includes(
                      item
                    )
                );
                setRightApprovers(
                  response.data.data.record.user_update_request_rules.approvers
                );
                setLeftApprovers(resultOpt);
              }
            } else {
              setLeftApprovers(arrayIds);
            }
          })
          .catch((e: any) => {
            if (e.code !== "ERR_CANCELED") {
              dispatch(updateLoading(false));
              toast.error(
                e?.response?.data?.message ??
                  "Erro inesperado, tente novamente."
              );
            }
          });
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onSubmit() {
    dispatch(updateLoading(true));
    PutInstante(
      null,
      {
        user_update_request_rules: {
          approvers: rightApprovers,
        },
        fields_with_required_documents: right,
        user_form_hidden_fields: {
          rh: leftRH,
          manager: leftManager,
          collaborator: leftCollaborator,
        },
      },
      "/company_settings"
    )
      .then((response: any) => {
        dispatch(updateLoading(false));
        toast.success(response?.data?.message);
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
  }
  return (
    <form>
      <Grid container gap={2}>
        <Grid container justifyContent={"space-between"}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/'>
              Inicio
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/settings/updateInformation'>
              Configurações
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/settings/updateInformation'>
              Alteração cadastral
            </Link>
            <Typography fontWeight={800}>Ver detalhes</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid container>
          <Callout.Root size='2' style={{ alignItems: "center" }}>
            <Callout.Icon>
              <InfoOutlined />
            </Callout.Icon>
            <Grid container flexDirection={"column"}>
              <Typography fontSize={16} fontWeight={400}>
                Abaixo é permitido a configuração de usuários com permissão aos
                fluxos de alteração cadastral.
              </Typography>
              <Typography fontSize={16} fontWeight={400} mt={1}>
                Para realizar esse procedimento, é só selecionar os itens que
                deseja incluir no lado esquerdo, enviar para o lado direito. e
                para remover é só realizar o processo contrário, após a
                conclusão da personalização, clique em 'SALVAR MODIFICAÇÕES'
                para concluir.
              </Typography>
              <Typography fontSize={16} fontWeight={800} mt={1}>
                Esse procedimento afeta as ações: VER DETALHES, APROVAR E
                REPROVAR.
              </Typography>
            </Grid>
          </Callout.Root>
        </Grid>

        <ConfigSelection
          leftLabel='Usuários sem permissão:'
          rightLabel='Usuários com permissão:'
          left={leftApprovers}
          right={rightApprovers}
          options={options}
          setLeft={setLeftApprovers}
          setRight={setRightApprovers}
        />

        <Grid container>
          <Callout.Root size='2' style={{ alignItems: "center" }}>
            <Callout.Icon>
              <InfoOutlined />
            </Callout.Icon>
            <Grid container flexDirection={"column"}>
              <Typography fontSize={16} fontWeight={400}>
                Abaixo é permitido a configuração de campos obrigatórios para
                inclusão de comprovantes aos fluxos de alteração cadastral.
              </Typography>
              <Typography fontSize={16} fontWeight={400} mt={1}>
                Para realizar esse procedimento, é só selecionar os itens que
                deseja incluir no lado esquerdo, enviar para o lado direito. e
                para remover é só realizar o processo contrário, após a
                conclusão da personalização, clique em 'SALVAR MODIFICAÇÕES'
                para concluir.
              </Typography>
            </Grid>
          </Callout.Root>
        </Grid>

        <ConfigSelection
          leftLabel='Campos sem comprovação:'
          rightLabel='Campos com comprovante obrigatório:'
          left={left}
          right={right}
          options={fields}
          setLeft={setLeft}
          setRight={setRight}
        />

        <Grid container>
          <Callout.Root size='2' style={{ alignItems: "center" }}>
            <Callout.Icon>
              <InfoOutlined />
            </Callout.Icon>
            <Grid container flexDirection={"column"}>
              <Typography fontSize={16} fontWeight={400}>
                Abaixo é permitido a configuração de campos que não deve ser
                mostrado para o perfil aos fluxos de alteração cadastral.
              </Typography>
              <Typography fontSize={16} fontWeight={400} mt={1}>
                Para realizar esse procedimento, é só selecionar os itens que
                deseja incluir no lado esquerdo, enviar para o lado direito. e
                para remover é só realizar o processo contrário, após a
                conclusão da personalização, clique em 'SALVAR MODIFICAÇÕES'
                para concluir.
              </Typography>
            </Grid>
          </Callout.Root>
        </Grid>

        <ConfigSelection
          leftLabel='Perfil Colaborador - campos ocultos:'
          rightLabel='Perfil Colaborador - campos liberados:'
          left={leftCollaborator}
          right={rightCollaborator}
          options={fields}
          setLeft={setLeftCollaborator}
          setRight={setRightCollaborator}
        />

        <ConfigSelection
          leftLabel='Perfil RH - campos ocultos:'
          rightLabel='Perfil RH - campos liberados:'
          left={leftRH}
          right={rightRH}
          options={fields}
          setLeft={setLeftRH}
          setRight={setRightRH}
        />

        <ConfigSelection
          leftLabel='Perfil Gestor - campos ocultos:'
          rightLabel='Perfil Gestor - campos liberados:'
          left={leftManager}
          right={rightManager}
          options={fields}
          setLeft={setLeftManager}
          setRight={setRightManager}
        />

        <Grid container gap={2} justifyContent={"end"} mt={2}>
          <Button
            variant={"outlined"}
            onClick={() => {
              window.history.back();
            }}
            endIcon={<Undo />}>
            Voltar
          </Button>
          <Button variant={"contained"} onClick={onSubmit} endIcon={<Edit />}>
            Salvar modificações
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
