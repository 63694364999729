/** @format */

import { toast } from "react-toastify";
import { GenericError } from "../../../../helpers/genericError";
import { updateLoading } from "../../../../redux/reducers/common";
import { PutInstante } from "../../../../services/api";

export function readNotification(values: any, message = true, dispatch: any) {
  dispatch(updateLoading(true));
  PutInstante(values.id, { read: true }, "/notifications")
    .then(() => {
      if (message !== false) {
        dispatch(updateLoading(false));
        toast.success("Notificação lida com sucesso");
        window.location.reload();
      }
    })
    .catch((e: any) => {
      GenericError(e, dispatch);
    });
}
