/** @format */

import { Tree, TreeNode } from "react-organizational-chart";
import { OrgProps } from "./createOrg";
import { OrgItem } from "./orgItem";

interface Props {
  data: OrgProps[];
  companyData: any;
  level?: number;
  setData: any;
}

export const Org = ({ data, companyData, level = 0, setData }: Props) => {
  return (
    <>
      <Tree
        lineWidth={"2px"}
        lineColor={companyData.secondary}
        lineBorderRadius={"10px"}
        label={
          <TreeNode
            label={
              <img
                style={{ borderRadius: 12 }}
                {...companyData.logoDefault}
                alt='Logo da empresa'
              />
            }
          />
        }
        children={
          data &&
          data.length > 0 &&
          data.map((child: any, index: number) => (
            <OrgItem
              setData={setData}
              key={index}
              child={child}
              level={level + 1}
            />
          ))
        }
      />
    </>
  );
};
