/** @format */

import { ChangeCircleOutlined, ExitToAppOutlined } from "@mui/icons-material";
import { Chip, IconButton, Tooltip, useTheme } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Logout } from "../../services/protected/logout";
import { DialogDelegation } from "./delegationDialog";

export function DelegationHeader() {
  const theme = useTheme();
  const router = useNavigate();
  const dispatch = useDispatch();
  const delegation = useSelector((state: any) => state.user.delegation_data);
  const [delegationModal, setDelegationModal] = useState(false);
  const [delegationUser, setDelegationUser]: any = useState(null);
  return (
    <>
      <DialogDelegation
        {...{
          delegationModal,
          setDelegationModal,
          delegationUser,
          setDelegationUser,
        }}
      />
      {delegation?.delegated_session && (
        <>
          <div
            style={{
              width: 2,
              height: 40,
              backgroundColor: theme.palette.grey[400],
              marginRight: 12,
            }}
          />
          <Chip
            style={{ marginRight: 8 }}
            color={"primary"}
            label={"Acessando como delegado"}
          />
          <IconButton onClick={() => Logout(dispatch, router)}>
            <Tooltip title={"Sair"}>
              <ExitToAppOutlined />
            </Tooltip>
          </IconButton>
          <div
            style={{
              width: 2,
              height: 40,
              backgroundColor: theme.palette.grey[400],
              marginRight: 12,
              marginLeft: 12,
            }}
          />
        </>
      )}
      {delegation?.delegation_options?.length > 0 && (
        <>
          <div
            style={{
              width: 2,
              height: 40,
              backgroundColor: theme.palette.grey[400],
              marginRight: 12,
            }}
          />
          <IconButton onClick={() => setDelegationModal(!delegationModal)}>
            <Tooltip title={"Acessar como delegado"}>
              <ChangeCircleOutlined />
            </Tooltip>
          </IconButton>
          <div
            style={{
              width: 2,
              height: 40,
              backgroundColor: theme.palette.grey[400],
              marginRight: 12,
              marginLeft: 12,
            }}
          />
        </>
      )}
    </>
  );
}
