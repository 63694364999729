/** @format */
import * as yup from "yup";
export const columns = [
  { name: "solicitation_code", label: "Código" },
  { name: "collaborator_name", label: "Nome" },
  { name: "collaborator_role", label: "Cargo" },
  { name: "new_manager_name", label: "Gestor futuro" },
  { name: "department_name", label: "Departamento" },
  { name: "solicitation_status", label: "Status" },
];

export const validationSchema = yup.object({
  transfer_date: yup
    .date()
    .min(new Date(), "Data da tranferência precisa ser futura")
    .nullable()
    .required("Data da tranferência é obrigatório"),
  new_manager_id: yup.string().nullable().required("Esse campo é obrigatório"),
  observations: yup.string().nullable().required("Comentário é obrigatório"),
});

export const validationSchemaEdit = (data: any) =>
  yup.object({
    with_promotion: yup.boolean(),
    position_id: yup.string().nullable().required("Posição é obrigatório"),
    transfer_date: yup
      .date()
      .min(new Date(), "Data da transferência precisa ser futura")
      .nullable()
      .required("Data da transferência é obrigatório"),
    business_unit_id: yup
      .string()
      .nullable()
      .required("Unidade de negócio destino é obrigatório"),
    sector_id: yup
      .string()
      .nullable()
      .required("Setor / Locação destino é obrigatório"),
    cost_center_id: yup
      .string()
      .nullable()
      .required("Centro de custo destino é obrigatório"),
    job_role_id: yup
      .string()
      .nullable()
      .required("Cargo destino é obrigatório"),
    department_id: yup
      .string()
      .nullable()
      .required("Departamento destino é obrigatório"),
    observations: yup.string().nullable().required("Comentário é obrigatório"),
    new_remuneration_percentage: yup
      .number()
      .nullable()
      .when("with_promotion", ([with_promotion], schema) => {
        if (with_promotion) {
          return schema
            .required("Desconto é obrigatório")
            .min(
              data.rules_data.transfer_with_promotion_min_percent,
              `A porcentagem deve ser de pelo menos ${data.rules_data.transfer_with_promotion_min_percent}%`
            )
            .max(
              data.rules_data.transfer_with_promotion_max_percent,
              `A porcentagem não pode ser maior que ${data.rules_data.transfer_with_promotion_max_percent}%`
            );
        } else {
          return schema
            .required("Desconto é obrigatório")
            .min(
              data.rules_data.transfer_without_promotion_min_percent,
              `A porcentagem deve ser de pelo menos ${data.rules_data.transfer_without_promotion_min_percent}%`
            )
            .max(
              data.rules_data.transfer_without_promotion_max_percent,
              `A porcentagem não pode ser maior que ${data.rules_data.transfer_without_promotion_max_percent}%`
            );
        }
      }),
    new_remuneration_value: yup
      .string()
      .nullable()
      .required("Salário é obrigatório"),
  });
