/** @format */

export const permissionsMock: {
  id: number;
  uuid: "manager" | "rh" | "collaborator" | "accountant";
  name: string;
  permissions: string[];
}[] = [
  {
    id: 1,
    uuid: "manager",
    name: "Gestor",
    permissions: [
      "home",
      "organizational",
      "dataPulse",
      "notification",
      "vacationTimeDashboard",
      "settingsAccount",
      "employeeActive",
      "employeeLeave",
      "employeePosition",
      "delegation",
      "delegationNew",
      "delegationDetails",
      "requestUpdateInformation",
      "requestUpdateInformationRequest",
      "requestUpdateInformationDetails",
      "requestPersonal",
      "requestPersonalNew",
      "requestPersonalDetails",
      "requestVacation",
      "requestVacationNew",
      "requestVacationDetails",
      "requestLeave",
      "requestLeaveNew",
      "requestLeaveDetails",
      "requestDismissals",
      "requestDismissalsNew",
      "requestDismissalsDetails",
      "requestDismissalsDetailsView",
      // "requestDismissalRequests",
      // "requestDismissalRequestsNew",
      // "requestDismissalRequestsDetails",
      "requestSalaryReview",
      "requestSalaryReviewNew",
      "requestSalaryReviewDetails",
      "requestSalaryReviewDetailsView",
      "requestPosition",
      "requestPositionNew",
      "requestPositionDetails",
      "requestPromote",
      "requestPromoteNew",
      "requestPromoteDetails",
      "requestPromoteDetailsView",
      "requestTransfer",
      "requestTransferNew",
      "requestTransferDetails",
      "requestTransferDetailsView",
    ],
  },
  {
    id: 2,
    uuid: "rh",
    name: "Recursos humanos",
    permissions: [
      "home",
      "dataPulse",
      "notification",
      "audit",
      "usersRole",
      "permissionsAudit",
      "vacationTimeDashboard",
      "vacationCollaborator",
      "vacationCollaboratorNew",
      "vacationCollaboratorDetails",
      "settingsAccount",
      "settingsRequiredUpdateInformation",
      // "calendar",
      // "grades",
      "organizational",
      "processes",
      "employeeLeave",
      "employeeActive",
      "employeeInactive",
      "employeePending",
      "employeePendingDetails",
      "employeePendingActive",
      "employeePosition",
      "employeeNew",
      "delegation",
      "delegationNew",
      "delegationDetails",
      "requestLeave",
      "requestLeaveNew",
      "requestLeaveDetails",
      "requestLeaveDetailsEdit",
      "requestDismissals",
      "requestDismissalsNew",
      "requestDismissalsDetails",
      "requestDismissalsDetailsEdit",
      "requestDismissalsDetailsView",
      // "requestDismissalRequests",
      // "requestDismissalRequestsNew",
      // "requestDismissalRequestsDetails",
      "requestPosition",
      "requestPositionNew",
      "requestPositionDetails",
      "requestPositionDetailsEdit",
      "requestPositionDetailsRemove",
      "requestPromote",
      "requestPromoteNew",
      "requestPromoteDetails",
      "requestPromoteDetailsView",
      "requestSalaryReview",
      "requestSalaryReviewNew",
      "requestSalaryReviewDetails",
      "requestSalaryReviewDetailsEdit",
      "requestSalaryReviewDetailsView",
      "requestTransfer",
      "requestTransferNew",
      "requestTransferDetails",
      "requestTransferDetailsView",
      "requestUpdateInformation",
      "requestUpdateInformationRequest",
      "requestUpdateInformationDetails",
      "requestPersonal",
      "requestPersonalNew",
      "requestPersonalDetails",
      "requestPersonalDetailsEdit",
      "requestVacation",
      "requestVacationNew",
      "requestVacationDetails",
      "permissionsRequiredForgetPassword",
      "permissionGroup",
      "permissionGroupNew",
      "permissionGroupDetails",
      "permissionsRequiredPersonnel",
      "permissionsRequiredDismissals",
      "permissionsRequiredLeave",
      "permissionsRequiredPosition",
      "permissionsRequiredPromote",
      "permissionsRequiredSalaryReview",
      "permissionsRequiredTransfer",
      "permissionsRequiredVacation",
      // "permissionLetter",
      // "permissionLetterNew",
      // "permissionLetterDetails",
      "permissionsRequiredUpdateInformation",
      "permissionsRequiredUpdateInformationEdit",
    ],
  },
  {
    id: 3,
    uuid: "collaborator",
    name: "Colaborador",
    permissions: [
      "home",
      "dataPulse",
      "notification",
      "organizational",
      "settingsAccount",
      "requestLeave",
      "requestLeaveNew",
      "requestLeaveDetails",
      "requestDismissals",
      "requestDismissalsNew",
      "requestDismissalsDetails",
      "requestUpdateInformation",
      "requestUpdateInformationRequest",
      "requestUpdateInformationDetails",
      "requestVacation",
      "requestVacationNew",
      "requestVacationDetails",
    ],
  },
  {
    id: 4,
    uuid: "accountant",
    name: "Contador",
    permissions: [
      "home",
      "notification",
      "organizational",
      "processes",
      "requestLeaveDetails",
      "requestDismissalsDetails",
      "requestUpdateInformationDetails",
      "requestVacationDetails",
      "requestSalaryReviewDetails",
      "requestPromoteDetails",
      "requestTransferDetails",
      "requestPositionDetails",
      "requestDetailsComplete",
      "requestDetailsAccountingReturned",
    ],
  },
];
