/** @format */

import { Edit, InfoOutlined, Undo } from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  Grid,
  Link,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { DatePicker } from "@mui/x-date-pickers";
import { Callout } from "@radix-ui/themes";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { ActionAproved } from "../../../../../components/refactor/actionApproved";
import { ActionDisapproved } from "../../../../../components/refactor/actionDisapproved";
import { ActionResend } from "../../../../../components/refactor/actionResend";
import { ActionReturned } from "../../../../../components/refactor/actionReturned";
import { StatusPermittedByRoleContainer } from "../../../../../components/statusPermittedByRoleContainer";
import { EmptyData } from "../../../../../helpers/emptyData";
import {
  ClearCurrency,
  FormatCurrency,
} from "../../../../../helpers/formatCurrency";
import { FormatStatus } from "../../../../../helpers/formatStatus";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../../services/api";

const validationSchema = yup.object({
  initial_date: yup
    .date()
    .min(new Date(), "Data é igual ou anterior a data de hoje")
    .nullable()
    .required("Data é obrigatório"),
  job_role_id: yup.string().nullable().required("Posição é obrigatório"),
  job_role_group_id: yup.string().nullable().required("Cargo é obrigatório"),
  salary: yup.string().nullable().required("Remuneração é obrigatório"),
  observations: yup.string().nullable().required("Informação é obrigatório"),
});

export function PersonalDetails() {
  const router = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [data, setData]: any = useState({
    options: { job_role: [], job_role_group: [] },
    collaborator_data: {},
    solicitation_data: {},
  });

  const user = useSelector((state: any) => state.user.data);
  const permissions = useSelector((state: any) => state.user.permissions);
  const edit =
    permissions !== null &&
    permissions.indexOf("requestPersonalDetailsEdit") !== -1 &&
    data?.solicitation_data.status === "pending";
  const isResendable =
    data?.solicitation_data.status === "returned" &&
    user.id === data.record.created_by_id;

  const formik: any = useFormik({
    initialValues: {
      job_role_id: "",
      job_role_group_id: "",
      salary: "",
      initial_date: null,
      position_id: "",
      observations: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      PutInstante(
        id,
        {
          ...values,
          salary: ClearCurrency(values.salary),
        },
        "/personnel_requisitions"
      )
        .then((response: any) => {
          dispatch(updateLoading(false));
          router("/request/personal");
          toast.success(response?.data?.message);
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    GetInstance({}, "/personnel_requisitions/" + id)
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        const data = response.data.data;
        setData(data);
        const initial_date = dayjs(data.record.initial_date);
        formik.setValues({
          job_role_id: data.record.job_role_id,
          job_role_group_id: data.record.job_role_group_id,
          salary: FormatCurrency({
            target: {
              value: `${data.record.salary}` || "0",
            },
          }),
          initial_date,
          position_id: data.position.id,
          observations: data.record.observations,
        });
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const requestInfo = [
    {
      title: "Código",
      value: data.solicitation_data.code,
    },
    {
      title: "Data",
      value: data.solicitation_data.date,
    },
    {
      title: "Solicitante",
      value: EmptyData(data.solicitation_data.created_by),
    },
    {
      title: "Status",
      value: FormatStatus(data.solicitation_data.status),
    },
    {
      title: "Justificativa",
      value: EmptyData(data.solicitation_data.action_justification),
    },
  ];

  const resend = (resendActionCallback: any) => {
    dispatch(updateLoading(true));
    PutInstante(
      id,
      {
        ...formik.values,
        salary: ClearCurrency(formik.values.salary),
      },
      "/personnel_requisitions"
    )
      .then((response: any) => {
        resendActionCallback();
        dispatch(updateLoading(false));
        router("/request/personal");
        toast.success(response?.data?.message);
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <Grid container justifyContent={"space-between"}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/'>
              Inicio
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/personal'>
              Solicitações
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/personal'>
              Requisição de pessoal
            </Link>
            <Typography fontWeight={800}>Ver solicitação</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid container>
          <Callout.Root size='2' style={{ alignItems: "center" }}>
            <Callout.Icon>
              <InfoOutlined />
            </Callout.Icon>
            <Grid container>
              <Grid container flexDirection={"column"}>
                <Typography fontSize={16} fontWeight={800}>
                  Informações da solicitação
                </Typography>
                <Grid container gap={"12px 96px"} mt={1}>
                  {requestInfo.map((item: any) => (
                    <Grid key={item.title}>
                      <Typography fontSize={12} fontWeight={600}>
                        {item.title}
                      </Typography>
                      <Typography fontSize={10} fontWeight={400}>
                        {item.value}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Callout.Root>
        </Grid>
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Detalhes da requisição de pessoal
              </Typography>
            </Grid>
            <Grid gridTemplateColumns={"1fr 1fr"} display={"grid"} gap={2}>
              <TextField
                fullWidth
                disabled
                label='Posição'
                value={`${data.position?.code} ${data.position?.job_role_name}`}
              />
              <TextField
                fullWidth
                disabled
                id='job_role_id'
                select
                label='Cargo'
                value={formik.values.job_role_id}
                onChange={(event: any) =>
                  formik.setFieldValue("job_role_id", event.target.value)
                }
                onBlur={formik.handleBlur}
                error={
                  formik.touched.job_role_id &&
                  Boolean(formik.errors.job_role_id)
                }
                helperText={
                  formik.touched.job_role_id && formik.errors.job_role_id
                }>
                {data.options.job_role.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid
              gridTemplateColumns={"1fr 1fr"}
              mt={2}
              display={"grid"}
              gap={2}>
              <DatePicker
                slotProps={{
                  textField: {
                    fullWidth: true,
                    id: "initial_date",
                    onBlur: formik.handleBlur,
                    error:
                      formik.touched.initial_date &&
                      Boolean(formik.errors.initial_date),
                    helperText:
                      formik.touched.initial_date && formik.errors.initial_date,
                  },
                }}
                disabled
                label='Data prevista de início'
                value={formik.values.initial_date}
                onChange={(value: any) => {
                  formik.setFieldValue("initial_date", value);
                }}
              />
              <TextField
                fullWidth
                disabled={!edit}
                id='salary'
                label='Remuneração'
                variant='outlined'
                value={formik.values.salary}
                onChange={(event: any) =>
                  formik.setFieldValue("salary", FormatCurrency(event))
                }
                onBlur={formik.handleBlur}
                error={formik.touched.salary && Boolean(formik.errors.salary)}
                helperText={formik.touched.salary && formik.errors.salary}
              />
            </Grid>
            <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
              <div>
                <TextField
                  fullWidth
                  disabled
                  id='observations'
                  label='Responsabilidades'
                  variant='outlined'
                  multiline={true}
                  rows={4}
                  value={formik.values.observations}
                  onChange={(event: any) =>
                    formik.setFieldValue("observations", event.target.value)
                  }
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.observations &&
                    Boolean(formik.errors.observations)
                  }
                  helperText={
                    formik.touched.observations && formik.errors.observations
                  }
                />
              </div>
            </Grid>
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <Button
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                fullWidth={isMobile}
                endIcon={<Undo />}>
                Voltar
              </Button>

              {isResendable && (
                <ActionResend
                  id={id}
                  url={"personnel_requisitions"}
                  disabled={!formik.isValid}
                  onSubmit={resend}
                />
              )}

              {edit && (
                <Button
                  variant={"contained"}
                  type={"submit"}
                  endIcon={<Edit />}>
                  Editar
                </Button>
              )}

              <StatusPermittedByRoleContainer
                rhAllowedStatuses={[
                  "pending",
                  "waiting_manager_user",
                  "waiting_rh_user",
                  "accounting_return",
                ]}
                managerAllowedStatuses={["pending", "waiting_manager_user"]}
                status={data?.solicitation_data.status}>
                <ActionReturned id={id} url={"personnel_requisitions"} />
                <ActionDisapproved id={id} url={"personnel_requisitions"} />
              </StatusPermittedByRoleContainer>

              <StatusPermittedByRoleContainer
                rhAllowedStatuses={[
                  "pending",
                  "waiting_manager_user",
                  "waiting_rh_user",
                ]}
                managerAllowedStatuses={["pending", "waiting_manager_user"]}
                status={data?.solicitation_data.status}>
                <ActionAproved
                  keyPrivate={"requestPersonalDetailsApprove"}
                  id={id}
                  url={"personnel_requisitions"}
                />
              </StatusPermittedByRoleContainer>

              <StatusPermittedByRoleContainer
                rhAllowedStatuses={["accounting_return"]}
                status={data?.solicitation_data.status}>
                <ActionResend
                  id={id}
                  url={"personnel_requisitions"}
                  hasObservation
                  disabled={!formik.isValid}
                  onSubmit={resend}
                />
              </StatusPermittedByRoleContainer>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
