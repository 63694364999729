/** @format */

import { Visibility } from "@mui/icons-material";
import {
  Breadcrumbs,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";
import { optionsTable } from "../../../../config/optionsTable";
import { permissionsMock } from "../../../../config/permissionsMock";

const columns = [
  { name: "name", label: "Nome" },
  { name: "uuid", label: "Identificador" },
  { name: "id", label: "Código" },
];

export function GroupPermission() {
  const router = useNavigate();

  return (
    <Grid container gap={2}>
      <Grid container justifyContent={"space-between"}>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link
            underline='hover'
            sx={{ cursor: "pointer" }}
            color='inherit'
            href='/'>
            Inicio
          </Link>
          <Typography>Configurações</Typography>
          <Typography fontWeight={800}>Grupo</Typography>
        </Breadcrumbs>
        {/* <Button
          variant={"contained"}
          onClick={() => {
            router("/settings/group/new");
          }}
          endIcon={<GroupAdd />}>
          Novo grupo
        </Button> */}
      </Grid>
      <Grid container>
        <MUIDataTable
          title={""}
          data={permissionsMock}
          columns={[
            {
              name: "action",
              label: "Ações",
              options: {
                sort: false,
                customBodyRenderLite: (dataIndex: number) => {
                  return (
                    <Grid container>
                      <Tooltip title={"Ver detalhes"}>
                        <IconButton
                          onClick={() => {
                            router(
                              `/settings/group/details/${permissionsMock[dataIndex]?.id}`
                            );
                          }}>
                          <Visibility color='primary' />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  );
                },
              },
            },
            ...columns,
          ]}
          options={{
            ...optionsTable,
            searchPlaceholder: "Pesquise por nome",
            download: false,
          }}
        />
      </Grid>
    </Grid>
  );
}
