/** @format */

export const columns = [
  { name: "solicitation_code", label: "Código" },
  { name: "name", label: "Função" },
  { name: "solicitation_date", label: "Data da solicitação" },
  { name: "job_role_name", label: "Cargo" },
  { name: "salary", label: "Remuneração" },
  { name: "created_by_name", label: "Solicitante" },
  { name: "department_name", label: "Departamento" },
  { name: "solicitation_status", label: "Status" },
];
