/** @format */

import { Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Lottie from "react-lottie";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import animationData from "../../assets/lotties/notification.json";
import { GenericError } from "../../helpers/genericError";
import { RedirectSolicitation } from "../../helpers/redirectSolicitation";
import { getNotificationsUnread } from "../../pages/protected/notifications/helpers/getNotification";
import { updateLoading } from "../../redux/reducers/common";
import { PutInstante } from "../../services/api";
import Chip from "./chip";

const ListItemWrapper = ({ children }: any) => {
  return (
    <Box
      sx={{
        p: 2,
        minWidth: 330,
        borderBottom: "1px solid",
        borderColor: "divider",
        cursor: "pointer",
        "&:hover": {
          bgcolor: "primary.light",
        },
      }}>
      {children}
    </Box>
  );
};

const ListItemWrappeEmpty = ({ children }: any) => {
  return (
    <Box
      sx={{
        p: 2,
        minWidth: 330,
        borderBottom: "1px solid",
        borderColor: "divider",
        cursor: "default",
      }}>
      {children}
    </Box>
  );
};

ListItemWrapper.propTypes = {
  children: PropTypes.node,
};

const NotificationList = (data: any) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const router = useNavigate();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const chipSX = {
    height: 24,
    padding: "0 6px",
  };

  const chipWarningSX = {
    ...chipSX,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.warning.main,
  };

  function readNotification(values: any, message = true) {
    dispatch(updateLoading(true));
    PutInstante(values.id, { read: true }, "/notifications")
      .then(() => {
        if (message) {
          dispatch(updateLoading(false));
          toast.success("Notificação lida com sucesso");
          getNotificationsUnread(dispatch);
        }
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
  }

  function onClick(notification: any) {
    readNotification(
      {
        id: notification.uuid,
      },
      false
    );
    if (!!notification?.resource_uuid) {
      RedirectSolicitation(
        notification.resource_type,
        notification.resource_uuid,
        router
      );
    }
    data.setOpen(false);
  }

  return (
    <List
      sx={{
        width: "100%",
        maxWidth: 330,
        py: 0,
        cursor: "pointer",
        borderRadius: "10px",
        [theme.breakpoints.down("md")]: {
          maxWidth: 300,
        },
        "& .MuiListItemSecondaryAction-root": {
          top: 22,
        },
        "& .MuiDivider-root": {
          my: 0,
        },
        "& .list-container": {
          pl: 2,
        },
      }}>
      {data.data.map((notification: any) => (
        <ListItemWrapper>
          <ListItem
            alignItems='center'
            onClick={() => {
              onClick(notification);
            }}>
            <Grid
              container
              gap={2}
              justifyContent='space-between'
              onClick={() => {
                onClick(notification);
              }}>
              <Grid
                item
                onClick={() => {
                  onClick(notification);
                }}>
                <Tooltip
                  title={notification.title.length > 25 && notification.title}>
                  <Typography
                    sx={{ cursor: "pointer" }}
                    variant='body1'
                    display='block'
                    onClick={() => {
                      onClick(notification);
                    }}
                    gutterBottom>
                    {notification.title.length > 20
                      ? notification.title.slice(0, 20) + "..."
                      : notification.title}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item>
                <Typography
                  sx={{ cursor: "pointer" }}
                  variant='caption'
                  display='block'
                  onClick={() => {
                    onClick(notification);
                  }}
                  gutterBottom>
                  {notification.created_at}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
          <Grid
            onClick={() => {
              onClick(notification);
            }}
            container
            direction='column'
            className='list-container'>
            <Grid
              item
              xs={12}
              sx={{ pb: 2 }}
              onClick={() => {
                onClick(notification);
              }}>
              <Typography
                onClick={() => {
                  onClick(notification);
                }}
                sx={{ cursor: "pointer" }}
                variant='subtitle2'>
                {notification.message}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              onClick={() => {
                onClick(notification);
              }}>
              <Grid container gap={1}>
                {notification.read_at === "Não possui" && (
                  <Grid item>
                    <Chip label='Não lida' sx={chipWarningSX} />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </ListItemWrapper>
      ))}
      {data.data.length === 0 && (
        <ListItemWrappeEmpty>
          <ListItem alignItems='center'>
            <Grid
              container
              gap={2}
              flexDirection={"column"}
              justifyContent='center'>
              <Lottie options={defaultOptions} height={50} width={"auto"} />
              <Typography align='center'>Sem notificações novas</Typography>
            </Grid>
          </ListItem>
        </ListItemWrappeEmpty>
      )}
      <Divider />
    </List>
  );
};

export default NotificationList;
