/** @format */

import { FilterEmpty } from "../../../../helpers/emptyData";
import { GenericError } from "../../../../helpers/genericError";
import { udateNotification } from "../../../../redux/reducers/common";
import { GetInstance } from "../../../../services/api";

export const getNotificationsUnread = (dispatch: any) => {
  GetInstance(
    {
      unread: true,
    },
    "notifications"
  )
    .then((response: any) => {
      dispatch(
        udateNotification({
          totalNotifications: response.data.data.total_count,
          notifications: FilterEmpty(response.data.data.data.slice(0, 3)),
        })
      );
    })
    .catch((e: any) => {
      GenericError(e);
    });
};
