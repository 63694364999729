/** @format */

import { AddCircle, Undo } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BreadcrumbsComponent } from "../../../../components/refactor/breadcrumbsComponent";
import { ButtonComponent } from "../../../../components/refactor/buttonComponent";
import { InfoComponent } from "../../../../components/refactor/infoComponent";
import { GenericError } from "../../../../helpers/genericError";
import { updateLoading } from "../../../../redux/reducers/common";
import { PostInstante } from "../../../../services/api";
import { validationSchema } from "../helpers/constants";
import { Form } from "../helpers/form";

export function DelegationNew() {
  const router = useNavigate();
  const user = useSelector((state: any) => state.user.data);
  let { id } = useParams();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      initial_date: null,
      final_date: null,
      target_user_id: "",
      delegated_user_id: user.id,
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      dispatch(updateLoading(true));
      PostInstante(null, values, "/delegations")
        .then((response: any) => {
          dispatch(updateLoading(false));
          router("/delegation");
          toast.success(response?.data?.message);
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Delegações", value: "/delegation" },
            { label: "Nova delegação" },
          ]}
        />
        <InfoComponent
          instruction='Para delegar suas tarefas e fluxos de trabalho, em períodos de
                  ausência a outro colaborador digite os dados abaixo.'
        />

        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Criar nova delegação de fluxo de trabalho
              </Typography>
            </Grid>
            <Form formik={formik} id={id} />
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <ButtonComponent
                variant={"outlined"}
                onClick={() => {
                  router("/delegation");
                }}
                endIcon={<Undo />}>
                Voltar
              </ButtonComponent>
              <ButtonComponent
                variant={"contained"}
                type={"submit"}
                endIcon={<AddCircle />}>
                Cadastrar
              </ButtonComponent>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
