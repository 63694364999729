/**
 * eslint-disable array-callback-return
 *
 * @format
 */

/** @format */

import { AddCircle, InfoOutlined, Undo } from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  Divider,
  Grid,
  Link,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { DatePicker } from "@mui/x-date-pickers";
import { Callout, Tooltip } from "@radix-ui/themes";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { aviso_e_social } from "../../../../../config/constants";
import { EmptyData } from "../../../../../helpers/emptyData";
import { FormatDate } from "../../../../../helpers/formatDate";
import { FormatStatus } from "../../../../../helpers/formatStatus";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance, PostInstante } from "../../../../../services/api";

const validationSchema = yup.object().shape({
  vacation_items: yup.array().of(
    yup.object().shape({
      days_quantity: yup
        .number()
        .min(5, "Mínimo de 5 dias")
        .required("Campo obrigatório")
        .test(
          "max",
          "Quantidade de dias superior ao disponivel.",
          function (item: any, all: any) {
            return all.options.context.days_available >= 0;
          }
        ),
      start_date: yup
        .date()
        .typeError("Data inválida")
        .nullable("Data inválida")
        .required("Campo obrigatório")
        .test(
          "min",
          "Data de inicio não pode ser anterior ao período concessivo",
          function (item: any, all: any) {
            return (
              all.options.context.concessive_period_start.getTime() <
              new Date(item).getTime()
            );
          }
        )
        .test(
          "min_policy",
          "Data não respeita as regras da empresa",
          function (item: any, all: any) {
            const policyDay = new Date();
            policyDay.setDate(
              policyDay.getUTCDate() +
                all.options.context.vacations_request_minimum_days
            );
            return new Date(item).getTime() > policyDay.getTime();
          }
        ),
      end_date: yup
        .date()
        .nullable("Data inválida")
        .test(
          "min",
          "Data de término não pode ser posterior ao período concessivo",
          function (item: any, all: any) {
            return (
              all.options.context.concessive_period_end.getTime() >
              new Date(item).getTime()
            );
          }
        )
        .typeError("Data de início ou quantidade inválido."),
      thirteen_anticipation: yup
        .string()
        .nullable()
        .required("Campo obrigatório"),
      abono_pecuniario: yup.string().nullable().required("Campo obrigatório"),
    })
  ),
});

export function VacationNew() {
  const router = useNavigate();
  const { id } = useParams();
  const [user, setUser]: any = useState({ vacations: [{}], vacationsOpen: [] });
  const [valide, setValide] = useState(false);
  const userData: any = useSelector((state: any) => state?.user?.data);
  const dispatch = useDispatch();
  const formik: any = useFormik({
    initialValues: {
      vacation_items: [
        {
          start_date: null,
          end_date: null,
          days_quantity: "",
          thirteen_anticipation: false,
          abono_pecuniario: false,
          payment_date: null,
          description: "",
        },
      ],
    },
    validateOnBlur: valide,
    validateOnChange: valide,
    validationSchema: validationSchema,
    onSubmit: async (values: any) => {
      const sendValues = values;
      // eslint-disable-next-line array-callback-return
      await sendValues.vacation_items.map((item: any) => {
        item.days_quantity = Number(item.days_quantity);
        item.abono_quantity = item.abono_pecuniario
          ? sendValues.abono_quantity
          : 0;
      });
      dispatch(updateLoading(true));
      PostInstante(
        null,
        {
          ...sendValues.vacation_items[0],
          vacation_uuid: sendValues.uuid,
        },
        "/vacation_items"
      )
        .then((response: any) => {
          dispatch(updateLoading(false));
          router("/request/vacation");
          toast.success(response?.data?.message);
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    if (id !== undefined) {
      GetInstance({}, "/vacation_items_form?user_uuid=" + id)
        .then(async (response: any) => {
          dispatch(updateLoading(false));
          if (response.data.data.available_vacation === null) {
            window.history.back();
            toast.error("Não existem periodo de férias.");
            return;
          }
          setUser({
            vacations_custom_days_abono:
              response.data.data.company_rules.vacations_rules
                ?.vacations_custom_days_abono ?? null,
            ...response.data.data.user_data,
            ...response.data.data.available_vacation,
            vacations: [response.data.data.available_vacation],
            vacationsOpen: response.data.data.other_open_vacations ?? [],
          });
          response.data.data.available_vacation.concessive_period_start =
            new Date(
              response.data.data.available_vacation.concessive_period_start
            );
          response.data.data.available_vacation.concessive_period_end =
            new Date(
              response.data.data.available_vacation.concessive_period_end
            );
          formik.setValues({
            ...formik.values,
            ...response.data.data.available_vacation,
            vacations_request_minimum_days:
              userData?.role === "rh"
                ? 0
                : response.data.data.company_rules
                    .vacations_request_minimum_days,
          });
          setValide(true);
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const userInfo = [
    {
      title: "Nome",
      value: user.name,
    },
    {
      title: "Cód. cargo",
      value: EmptyData(user.job_role_code),
    },
    {
      title: "Cargo",
      value: EmptyData(user.job_role_name),
    },
    {
      title: "Status",
      value: FormatStatus(user.status),
    },
  ];

  const Info = (opt: any) => [
    {
      title: "Período aquisitivo",
      value:
        FormatDate(opt.acquisition_period_start) +
        " - " +
        FormatDate(opt.acquisition_period_end),
    },
    {
      title: "Dias disponíveis",
      value: EmptyData(formik.values.days_available),
    },
    {
      title: "Dias utilizados",
      value: EmptyData(formik.values.days_used),
    },
    {
      title: "Período concessivo",
      value:
        FormatDate(opt.concessive_period_start) +
        " - " +
        FormatDate(opt.concessive_period_end),
    },
  ];

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <Grid container justifyContent={"space-between"}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/'>
              Inicio
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/vacation'>
              Solicitações
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/vacation'>
              Férias
            </Link>
            <Typography fontWeight={800}>Nova solicitação</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid container>
          <Callout.Root size='2' style={{ alignItems: "center" }}>
            <Callout.Icon>
              <InfoOutlined />
            </Callout.Icon>
            <Grid container flexDirection={"column"}>
              <Typography fontSize={16} fontWeight={800}>
                Informações do colaborador
              </Typography>
              <Grid container gap={"12px 96px"} mt={1}>
                {userInfo.map((item: any) => (
                  <Grid>
                    <Typography fontSize={12} fontWeight={400}>
                      {item.title}
                    </Typography>
                    <Typography fontSize={10} fontWeight={300}>
                      {item.value}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
              <Typography fontSize={16} fontWeight={800} mt={2}>
                Período de férias disponivel para programação
              </Typography>
              <Grid
                display={"grid"}
                gridTemplateColumns={"1fr"}
                gap={"12px 96px"}
                mt={1}>
                {user.vacations.map((opt: any) => (
                  <Grid display={"flex"} justifyContent={"space-between"}>
                    {Info(opt).map((item: any) => (
                      <Grid>
                        <Typography fontSize={12} fontWeight={400}>
                          {item.title}
                        </Typography>
                        <Typography fontSize={10} fontWeight={300}>
                          {item.value}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                ))}
              </Grid>
              {user.vacationsOpen.length > 0 && (
                <Typography fontSize={14} fontWeight={800} mt={2}>
                  Outros período(s) de férias em aberto
                </Typography>
              )}
              <Grid
                display={"grid"}
                gridTemplateColumns={"1fr"}
                gap={"12px 96px"}
                mt={1}>
                {user.vacationsOpen.map((opt: any) => (
                  <Grid display={"flex"} justifyContent={"space-between"}>
                    {Info(opt).map((item: any) => (
                      <Grid>
                        <Typography fontSize={12} fontWeight={400}>
                          {item.title}
                        </Typography>
                        <Typography fontSize={10} fontWeight={300}>
                          {item.value}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Callout.Root>
        </Grid>
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Criar programação para o período aquisitivo (
                {FormatDate(user.vacations[0]?.acquisition_period_start) +
                  " - " +
                  FormatDate(user.vacations[0]?.acquisition_period_end)}
                )
              </Typography>
            </Grid>
            <Grid container flexDirection={"column"}>
              <Tooltip
                content={
                  <Grid container>
                    <Grid>
                      <Typography fontSize={12} fontWeight={400}>
                        <strong>
                          Sempre que houver mais de 1 periodo, o mais antigo
                          sempre é o disponivel para programação.
                        </strong>
                      </Typography>
                      <Typography fontSize={12} fontWeight={400}>
                        <strong> Mínimo de Dias:</strong> Cada período deve ter
                        no mínimo 5 dias.
                      </Typography>
                      <Typography fontSize={12} fontWeight={400}>
                        <strong> Política da empresa:</strong> As férias devem
                        ser solicitadas com pelo menos{" "}
                        {formik.values?.vacations_request_minimum_days} dias de
                        antecedência.
                      </Typography>
                      <Typography fontSize={12} fontWeight={400}>
                        <strong>Divisão de Saldo:</strong> Se dividir o saldo em
                        3 períodos, pelo menos um deve ter 14 dias ou mais.
                      </Typography>
                      <Typography fontSize={12} fontWeight={400}>
                        <strong>Abono Pecuniário:</strong> Ao selecionar "Sim" ,
                        1/3 dos dias disponíveis será convertido em remuneração,
                        e o saldo será reduzido proporcionalmente.
                      </Typography>
                      <Typography fontSize={12} fontWeight={400}>
                        <strong>
                          As férias serão definidas pela disponibilidade de
                          dias, sendo seu início dois dias antes de um feriado
                          ou do Descanso Semanal Remunerado (DSR).
                        </strong>
                      </Typography>
                    </Grid>
                  </Grid>
                }>
                <Typography align='center' fontSize={12} fontWeight={500}>
                  Clique aqui para ver as instruções
                </Typography>
              </Tooltip>
            </Grid>
            {formik.values.vacation_items.map((item: any, index: number) => (
              <>
                <Divider style={{ marginTop: 16 }} />
                <Grid
                  container
                  justifyContent={"space-between"}
                  alignItems={"center"}>
                  {/* <Typography fontSize={16} fontWeight={500}>
                    Programação {index + 1}
                  </Typography> */}
                  {/* <Tooltip content={"Remover programação"}>
                    <IconButton onClick={() => Remove(index)} color={"error"}>
                      <Cancel />
                    </IconButton>
                  </Tooltip> */}
                </Grid>
                <Grid
                  gridTemplateColumns={"1fr 1fr 1fr"}
                  display={"grid"}
                  mt={2}
                  gap={2}>
                  <TextField
                    fullWidth
                    id={`vacation_items[${index}].days_quantity`}
                    type='number'
                    label='Quantidade'
                    variant='outlined'
                    value={formik.values.vacation_items[index]?.days_quantity}
                    onChange={(event: any) => {
                      formik.setFieldValue(
                        `vacation_items[${index}].days_quantity`,
                        event.target.value
                      );
                      formik.setFieldValue(
                        `vacation_items[${index}].abono_pecuniario`,
                        false
                      );
                    }}
                    onBlur={() => {
                      const newValue =
                        formik.values.vacation_items[index]?.days_quantity
                          .length > 0
                          ? Number(
                              formik.values.vacation_items[index]?.days_quantity
                            )
                          : 0;

                      const allDaysArray = formik.values.vacation_items.map(
                        (item: any) => Number(item?.days_quantity)
                      );
                      const allDay = allDaysArray.reduce(
                        (acumulador: number, valorAtual: number) => {
                          return Number(acumulador) + Number(valorAtual);
                        },
                        0
                      );
                      formik.setFieldValue(
                        "days_available",
                        Number(user.days_available) - Number(allDay)
                      );
                      formik.setFieldValue(
                        "days_used",
                        Number(user.days_used) + Number(allDay)
                      );
                      if (formik.values.vacation_items[index]?.start_date) {
                        const dateInit = new Date(
                          formik.values.vacation_items[index]?.start_date
                        );
                        dateInit.setUTCDate(dateInit.getUTCDate() + newValue);
                        formik.setFieldValue(
                          `vacation_items[${index}].end_date`,
                          dayjs(dateInit)
                        );
                        const dataPayment = new Date(
                          formik.values.vacation_items[index]?.start_date
                        );
                        dataPayment.setUTCDate(dataPayment.getUTCDate() - 2);
                        formik.setFieldValue(
                          `vacation_items[${index}].payment_date`,
                          dayjs(dataPayment)
                        );
                      }
                    }}
                    error={
                      formik.errors.vacation_items &&
                      Boolean(
                        formik.errors.vacation_items[index]?.days_quantity
                      )
                    }
                    helperText={
                      formik.errors.vacation_items &&
                      formik.errors.vacation_items[index]?.days_quantity
                    }
                  />
                  <DatePicker
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        id: `vacation_items[${index}].start_date`,
                        onBlur: formik.handleBlur,
                        error:
                          formik.errors.vacation_items &&
                          Boolean(
                            formik.errors.vacation_items[index]?.start_date
                          ),
                        helperText:
                          formik.errors.vacation_items &&
                          formik.errors.vacation_items[index]?.start_date,
                      },
                    }}
                    label='Data de início'
                    value={formik.values.vacation_items[index]?.start_date}
                    onChange={(value: any) => {
                      formik.setFieldValue(
                        `vacation_items[${index}].start_date`,
                        value
                      );
                      const dataPayment = new Date(value);
                      dataPayment.setUTCDate(dataPayment.getUTCDate() - 2);
                      formik.setFieldValue(
                        `vacation_items[${index}].payment_date`,
                        dayjs(dataPayment)
                      );
                      if (formik.values.vacation_items[index]?.days_quantity) {
                        const dateInit = new Date(value);
                        dateInit.setUTCDate(
                          dateInit.getUTCDate() +
                            Number(
                              formik.values.vacation_items[index]?.days_quantity
                            )
                        );
                        formik.setFieldValue(
                          `vacation_items[${index}].end_date`,
                          dayjs(dateInit)
                        );
                      }
                    }}
                  />
                  <DatePicker
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        id: `vacation_items[${index}].end_date`,
                        onBlur: formik.handleBlur,
                        error:
                          formik.errors.vacation_items &&
                          Boolean(
                            formik.errors.vacation_items[index]?.end_date
                          ),
                        helperText:
                          formik.errors.vacation_items &&
                          formik.errors.vacation_items[index]?.end_date,
                      },
                    }}
                    disabled
                    label='Data de término'
                    value={formik.values.vacation_items[index].end_date}
                    onChange={(value: any) => {
                      formik.setFieldValue(
                        `vacation_items[${index}].end_date`,
                        value
                      );
                    }}
                  />
                  <TextField
                    fullWidth
                    id={`vacation_items[${index}].thirteen_anticipation`}
                    select
                    label='Antecipação do 13°'
                    value={
                      formik.values.vacation_items[index]?.thirteen_anticipation
                    }
                    onChange={(event: any) =>
                      formik.setFieldValue(
                        `vacation_items[${index}].thirteen_anticipation`,
                        event.target.value
                      )
                    }
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.vacation_items &&
                      Boolean(
                        formik.errors.vacation_items[index]
                          ?.thirteen_anticipation
                      )
                    }
                    helperText={
                      formik.errors.vacation_items &&
                      formik.errors.vacation_items[index]?.thirteen_anticipation
                    }>
                    {aviso_e_social.map((option: any) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    fullWidth
                    id={`vacation_items[${index}].abono_pecuniario`}
                    select
                    label='Abono pecuniário'
                    value={
                      formik.values.vacation_items[index]?.abono_pecuniario
                    }
                    onChange={(event: any) => {
                      formik.setFieldValue(
                        `vacation_items[${index}].abono_pecuniario`,
                        event.target.value
                      );
                      if (event.target.value) {
                        const abono =
                          user.vacations_custom_days_abono ??
                          Math.floor(user.days_available / 3);
                        formik.setFieldValue("abono_quantity", abono);
                        formik.setFieldValue(
                          "days_available",
                          Number(formik.values.days_available) - Number(abono)
                        );
                        formik.setFieldValue(
                          "days_used",
                          Number(formik.values.days_used) + Number(abono)
                        );
                      } else {
                        const abono =
                          user.vacations_custom_days_abono ??
                          Math.floor(user.days_available / 3);
                        formik.setFieldValue("abono_quantity", abono);
                        formik.setFieldValue(
                          "days_available",
                          Number(formik.values.days_available) + Number(abono)
                        );
                        formik.setFieldValue(
                          "days_used",
                          Number(formik.values.days_used) - Number(abono)
                        );
                      }
                    }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.vacation_items &&
                      Boolean(
                        formik.errors.vacation_items[index]?.abono_pecuniario
                      )
                    }
                    helperText={
                      formik.errors.vacation_items &&
                      formik.errors.vacation_items[index]?.abono_pecuniario
                    }>
                    {aviso_e_social.map((option: any) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <DatePicker
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        id: `vacation_items[${index}].payment_date`,
                        onBlur: formik.handleBlur,
                        error:
                          formik.errors.vacation_items &&
                          Boolean(
                            formik.errors.vacation_items[index]?.payment_date
                          ),
                        helperText:
                          formik.errors.vacation_items &&
                          formik.errors.vacation_items[index]?.payment_date,
                      },
                    }}
                    disabled
                    label='Data de pagamento'
                    value={formik.values.vacation_items[index]?.payment_date}
                    onChange={(value: any) => {
                      formik.setFieldValue(
                        `vacation_items[${index}].payment_date`,
                        value
                      );
                    }}
                  />
                </Grid>
                <Grid
                  gridTemplateColumns={"1fr"}
                  mt={2}
                  display={"grid"}
                  gap={2}>
                  <div>
                    <TextField
                      fullWidth
                      id={`vacation_items[${index}].description`}
                      label='Informações sobre as férias'
                      variant='outlined'
                      multiline={true}
                      rows={4}
                      value={formik.values.vacation_items[index]?.description}
                      onChange={(event: any) =>
                        formik.setFieldValue(
                          `vacation_items[${index}].description`,
                          event.target.value
                        )
                      }
                      onBlur={formik.handleBlur}
                      error={
                        formik.errors.vacation_items &&
                        Boolean(
                          formik.errors.vacation_items[index]?.description
                        )
                      }
                      helperText={
                        formik.errors.vacation_items &&
                        formik.errors.vacation_items[index]?.description
                      }
                    />
                  </div>
                </Grid>
                <Divider style={{ marginTop: 16 }} />
              </>
            ))}
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <Button
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                fullWidth={isMobile}
                endIcon={<Undo />}>
                Voltar
              </Button>
              <Button
                variant={"contained"}
                onClick={() => {
                  setValide(true);
                  formik.handleSubmit();
                }}
                fullWidth={isMobile}
                endIcon={<AddCircle />}>
                Solicitar
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
