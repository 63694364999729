/** @format */

import { AddCircle, Undo } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BreadcrumbsComponent } from "../../../../../components/refactor/breadcrumbsComponent";
import { ButtonComponent } from "../../../../../components/refactor/buttonComponent";
import { InfoComponent } from "../../../../../components/refactor/infoComponent";
import { InputFile } from "../../../../../components/refactor/inputFile";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import {
  GetInstance,
  PostInstante,
  PostInstanteFormData,
  RemoveInstance,
} from "../../../../../services/api";
import { validationSchema } from "../helpers/constants";
import { Form } from "../helpers/form";

export function LeaveNew() {
  const router = useNavigate();
  const { id } = useParams();
  const [user, setUser]: any = useState({});
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      absence_date: null,
      return_date: null,
      e_social_motive: "",
      observations: "",
      files: [],
    },
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      PostInstante(null, { ...values, collaborator_id: user.uuid }, "/absences")
        .then((response: any) => {
          const formData = new FormData();
          // eslint-disable-next-line array-callback-return
          formik.values.files.map((file: any) => {
            formData.append("file[]", file);
          });
          PostInstanteFormData(
            null,
            formData,
            "/absences/" + response.data.data.absence_item_id + "/upload_files"
          )
            .then(() => {
              dispatch(updateLoading(false));
              router("/request/leave");
              toast.success(response?.data?.message);
            })
            .catch((e: any) => {
              if (e.code !== "ERR_CANCELED") {
                RemoveInstance(id, "/absences")
                  .then(() => {
                    dispatch(updateLoading(false));
                    router("/request/leave");
                  })
                  .catch((e: any) => {
                    GenericError(e, dispatch);
                  });
              }
            });
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    GetInstance({}, "/absences_form?user_uuid=" + id)
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        setUser(response.data.data.user_data);
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Solicitações", value: "/request/leave" },
            { label: "Afastamentos", value: "/request/leave" },
            { label: "Nova solicitação" },
          ]}
        />
        <InfoComponent user={user} />
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Criar afastamento
              </Typography>
            </Grid>
            <Form formik={formik} />
            <InputFile formik={formik} />
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <ButtonComponent
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                endIcon={<Undo />}>
                Voltar
              </ButtonComponent>
              <ButtonComponent
                variant={"contained"}
                type={"submit"}
                endIcon={<AddCircle />}>
                Cadastrar
              </ButtonComponent>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
