/** @format */

import { Edit, Undo, Visibility } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ActionAproved } from "../../../../../components/refactor/actionApproved";
import { ActionDisapproved } from "../../../../../components/refactor/actionDisapproved";
import { BreadcrumbsComponent } from "../../../../../components/refactor/breadcrumbsComponent";
import { ButtonComponent } from "../../../../../components/refactor/buttonComponent";
import { DocsView } from "../../../../../components/refactor/docsView";
import { InfoComponent } from "../../../../../components/refactor/infoComponent";
import { StatusPermittedByRoleContainer } from "../../../../../components/statusPermittedByRoleContainer";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../../services/api";
import { validationSchema } from "../helpers/constants";
import { Form } from "../helpers/form";

export function DismissalRequestsDetails() {
  const [docs, setDocs] = useState(false);
  const [valide, setValide] = useState(false);
  const router = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [data, setData]: any = useState({
    collaborator_data: {},
    solicitation_data: {},
  });
  const [options, setOptions] = useState({
    dismissal_reasons: [],
    dismissal_types: [],
  });

  const formik: any = useFormik({
    initialValues: {
      dismissal_date: null,
      early_warning: "",
      comment: "",
      dismissal_reason_id: "",
      dismissal_type_id: "",
    },
    validateOnBlur: valide,
    validateOnChange: valide,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setValide(true);
      dispatch(updateLoading(true));
      PutInstante(null, { ...values }, `/dismissal_requests/${id}`)
        .then((response: any) => {
          dispatch(updateLoading(false));
          router("/request/dismissalRequests");
          toast.success(response?.data?.message);
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    GetInstance(null, "/dismissal_requests/" + id)
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        const formData = response.data.data;
        setData(formData);
        formik.setValues({
          dismissal_date: dayjs(formData.record.dismissal_date),
          early_warning: formData.record.early_warning,
          dismissal_reason_id: formData.record.dismissal_reason_id,
          dismissal_type_id: formData.record.dismissal_type_id,
          comment: formData.record.comment,
        });
        setOptions({
          dismissal_reasons: formData.dismissal_types.group_0.filter(
            (d: any) => d.associated
          ),
          dismissal_types: formData.dismissal_reasons.group_0.filter(
            (d: any) => d.associated
          ),
        });
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
          router("/request/dismissalRequests");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Início" },
            { label: "Solicitações", value: "/request/dismissalRequests" },
            {
              label: "Autorização de desligamento",
              value: "/request/dismissalRequests",
            },
            { label: "Detalhes da solicitação" },
          ]}
        />
        <InfoComponent
          user={data?.collaborator_data}
          solicitation={data?.solicitation_data}
        />
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Detalhes da autorização de desligamento
              </Typography>
            </Grid>
            <Form
              formik={formik}
              options={options}
              edit={data.solicitation_data.status === "approved"}
            />
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <ButtonComponent
                variant={"outlined"}
                onClick={() => {
                  router("/request/dismissalRequests");
                }}
                endIcon={<Undo />}>
                Voltar
              </ButtonComponent>
              <StatusPermittedByRoleContainer
                rhAllowedStatuses={[
                  "pending",
                  "waiting_manager_user",
                  "waiting_rh_user",
                ]}
                managerAllowedStatuses={["pending", "waiting_manager_user"]}
                status={data?.solicitation_data.status}>
                <ButtonComponent
                  variant={"contained"}
                  type={"submit"}
                  endIcon={<Edit />}>
                  Editar
                </ButtonComponent>
              </StatusPermittedByRoleContainer>
              <StatusPermittedByRoleContainer
                rhAllowedStatuses={[
                  "pending",
                  "waiting_manager_user",
                  "waiting_rh_user",
                ]}
                status={data?.solicitation_data.status}>
                <ActionDisapproved id={id} url={"dismissal_requests"} />
                <ActionAproved
                  keyPrivate={"requestDismissalRequestsDetails"}
                  id={id}
                  url={"dismissal_requests"}
                />
              </StatusPermittedByRoleContainer>
              <StatusPermittedByRoleContainer
                rhAllowedStatuses={["approved", "completed"]}
                managerAllowedStatuses={["approved", "completed"]}
                status={data?.solicitation_data.status}>
                <ButtonComponent
                  onClick={() => setDocs(true)}
                  variant={"contained"}
                  endIcon={<Visibility />}>
                  Ver documentos de comunicação
                </ButtonComponent>
              </StatusPermittedByRoleContainer>
            </Grid>
          </Paper>
          {docs && (
            <DocsView
              solicitation_uuid={id}
              solicitation_type={"dismissal"}
              dismissal_type_id={formik.values.dismissal_type_id}
              dismissal_reason_id={formik.values.dismissal_reason_id}
            />
          )}
        </Grid>
      </Grid>
    </form>
  );
}
