/** @format */

import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { updateLoading } from "../../redux/reducers/common";
import { PostInstante } from "../api";

export const Logout = async (dispatch: any, router: any) => {
  dispatch(updateLoading(true));
  PostInstante(null, {}, "/logout")
    .then(() => {
      dispatch(updateLoading(false));
      Cookies.remove("auth");
      router("/login");
    })
    .catch(() => {
      dispatch(updateLoading(false));
      toast.error(
        "Erro ao tentar sair, tente novamente ou então entre em contato com o suporte"
      );
    });
};
