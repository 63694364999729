/** @format */

export const chipSX = {
  height: 24,
  padding: "0 6px",
};

export const chipWarningSX = (theme: any) => ({
  ...chipSX,
  color: theme.palette.common.white,
  backgroundColor: theme.palette.warning.main,
});

export const chipSuccessSX = (theme: any) => ({
  ...chipSX,
  color: theme.palette.common.white,
  backgroundColor: theme.palette.success.main,
});

export const columns = [
  { name: "created_at", label: "Data" },
  { name: "title", label: "Titulo" },
  { name: "message", label: "Mensagem" },
];
