/** @format */
import * as yup from "yup";
export const columns = [
  { name: "solicitation_code", label: "Código" },
  { name: "collaborator_name", label: "Nome" },
  { name: "job_role_name", label: "Cargo destino" },
  { name: "created_by_name", label: "Gestor" },
  { name: "solicitation_status", label: "Status" },
];
export const validationSchema = (min: number, max: number) =>
  yup.object({
    movement_date: yup
      .date()
      .min(new Date(), "Data da movimentação precisa ser futura")
      .nullable()
      .required("Data da movimentação é obrigatório"),
    target_job_role_id: yup
      .string()
      .nullable()
      .required("Cargo destino é obrigatório"),
    target_position_id: yup
      .string()
      .nullable()
      .required("Posição destino é obrigatório"),
    percent: yup
      .number()
      .nullable()
      .min(min, `Reajuste não pode ser menor de ${min}%`)
      .max(max, `Reajuste não pode ser maior de ${max}%`)
      .required("Porcentagem é obrigatório"),
    target_salary: yup.string().nullable().required("Salário é obrigatório"),
    observations: yup.string().nullable().required("Comentário é obrigatório"),
  });
