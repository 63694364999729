/** @format */

import { GroupAdd, Visibility, Warning } from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  Link,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Callout } from "@radix-ui/themes";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { optionsTable } from "../../../../config/optionsTable";
import { FilterEmpty } from "../../../../helpers/emptyData";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance } from "../../../../services/api";

const columns = [
  { name: "import_date", label: "Data da importação" },
  { name: "created_by_name", label: "Solicitante" },
  { name: "status", label: "Status" },
];

let timerSearch: any;

export function Import() {
  const dispatch = useDispatch();
  const router = useNavigate();
  const [data, setData]: any = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch]: any = useState(null);
  const [order, setOrder]: any = useState(null);

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance(
      { page: page + 1, per_page: perPage, search, order_by: order },
      "/file_imports"
    )
      .then((response: any) => {
        setData(FilterEmpty(response.data.data.data));
        setTotal(response.data.data.total_count);
        dispatch(updateLoading(false));
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          // toast.error(e?.response?.data?.message ?? 'Erro inesperado, tente novamente.');
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, search, order]);
  return (
    <Grid container gap={2}>
      <Grid container justifyContent={"space-between"}>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link
            underline='hover'
            sx={{ cursor: "pointer" }}
            color='inherit'
            href='/public'>
            Inicio
          </Link>
          <Typography fontWeight={400}>Colaboradores</Typography>
          <Typography fontWeight={800}>Importações</Typography>
        </Breadcrumbs>
        <Button
          variant={"contained"}
          onClick={() => {
            router("/employee/import/new");
          }}
          endIcon={<GroupAdd />}>
          Nova importação
        </Button>
      </Grid>
      <Grid container>
        <MUIDataTable
          title={""}
          data={data}
          columns={[
            {
              name: "action",
              label: "Ações",
              options: {
                sort: false,
                customBodyRenderLite: (dataIndex: number) => {
                  return (
                    data[dataIndex].status === "failed" && (
                      <Tooltip title={"Ver detalhes"}>
                        <IconButton onClick={() => {}}>
                          <Visibility color='primary' />
                        </IconButton>
                      </Tooltip>
                    )
                  );
                },
              },
            },
            ...columns,
          ]}
          options={{
            ...optionsTable,
            searchPlaceholder: "Pesquise por solicitante",
            download: false,
            expandableRowsOnClick: true,
            expandableRowsHeader: false,
            expandableRows: true,
            renderExpandableRow: (rowData, rowMeta) => {
              const colSpan = rowData.length + 1;
              const errors: any = [];
              if (Array.isArray(data[rowMeta.dataIndex].logs)) {
                // eslint-disable-next-line array-callback-return
                data[rowMeta.dataIndex].logs.map((log: any) => {
                  errors.push({ error: log });
                });
              }
              return data[rowMeta.dataIndex].status === "Processado" ? (
                <TableRow>
                  <TableCell colSpan={colSpan}>
                    <Grid container gap={2}>
                      <Callout.Root color={"green"}>
                        <Callout.Icon>
                          <Warning />
                        </Callout.Icon>
                        <Callout.Text>
                          Dados processados com sucesso.
                        </Callout.Text>
                      </Callout.Root>
                    </Grid>
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell colSpan={colSpan}>
                    <Grid container gap={2}>
                      <Callout.Root color={"red"}>
                        <Callout.Icon>
                          <Warning />
                        </Callout.Icon>
                        <Callout.Text>
                          Foram encontrados erros na planilha e por isso, não
                          foi processada com sucesso.
                        </Callout.Text>
                      </Callout.Root>
                    </Grid>
                    <Grid container mt={2}>
                      <MUIDataTable
                        title={""}
                        data={errors}
                        columns={[
                          {
                            name: "error",
                            label: "Falha",
                          },
                        ]}
                        options={{
                          ...optionsTable,
                          searchPlaceholder: "Pesquise por solicitante",
                          download: false,
                          searchAlwaysOpen: false,
                          search: false,
                        }}
                      />
                    </Grid>
                  </TableCell>
                </TableRow>
              );
            },
            onSearchChange: (value: string) => {
              clearTimeout(timerSearch);
              timerSearch = setTimeout(() => {
                setPage(0);
                setSearch(value);
              }, 500);
            },
            onColumnSortChange: (value: string, direction: string) => {
              setOrder(`${value} ${direction}`);
            },
            count: total,
            page,
            onChangePage: (page: number) => {
              setPage(page);
            },
            rowsPerPage: perPage,
            onChangeRowsPerPage: (perPage: number) => {
              setPage(0);
              setPerPage(perPage);
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
