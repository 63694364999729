/** @format */

import {
  AccountTreeOutlined,
  Analytics,
  CallSplit,
  Difference,
  DoneAll,
  DonutSmall,
  Home,
  Mood,
  Notifications,
  PeopleOutline,
  Security,
  Settings,
} from "@mui/icons-material";
import { routers } from "./routers";

export const menuItems = {
  items: [
    {
      id: "init",
      title: "",
      type: "group",
      children: [
        {
          id: "home",
          title: "Inicio",
          type: "item",
          path: routers.filter((item: any) => item.id === "home")[0].path,
          icon: <Home />,
        },
        {
          id: "organizational",
          title: "Organograma",
          type: "item",
          path: routers.filter((item: any) => item.id === "organizational")[0]
            .path,
          icon: <AccountTreeOutlined />,
        },
        {
          id: "employee",
          title: "Colaboradores",
          type: "collapse",
          icon: <PeopleOutline />,
          children: [
            {
              id: "active",
              title: "Ativos",
              type: "item",
              path: routers.filter(
                (item: any) => item.id === "employeeActive"
              )[0].path,
            },
            {
              id: "employeeLeave",
              title: "Afastados",
              type: "item",
              path: routers.filter(
                (item: any) => item.id === "employeeLeave"
              )[0].path,
            },
            {
              id: "inactive",
              title: "Desligados",
              type: "item",
              path: routers.filter(
                (item: any) => item.id === "employeeInactive"
              )[0].path,
            },

            {
              id: "pending",
              title: "Pendentes",
              type: "item",
              path: routers.filter(
                (item: any) => item.id === "employeePending"
              )[0].path,
            },
            {
              id: "import",
              title: "Importações",
              type: "item",
              path: routers.filter(
                (item: any) => item.id === "employeeImport"
              )[0].path,
            },
          ],
        },
        {
          id: "position",
          title: "Posições",
          type: "item",
          path: routers.filter((item: any) => item.id === "employeePosition")[0]
            .path,
          icon: <DonutSmall />,
        },
        {
          id: "dataPulse",
          title: "DataPulse",
          type: "item",
          path: routers.filter((item: any) => item.id === "dataPulse")[0].path,
          icon: <Analytics />,
          demo: true,
        },
        {
          id: "delegation",
          title: "Delegações",
          type: "item",
          path: routers.filter((item: any) => item.id === "delegation")[0].path,
          icon: <CallSplit />,
          breadcrumbs: false,
        },
        {
          id: "vacation",
          title: "Férias",
          type: "collapse",
          icon: <Mood />,
          children: [
            {
              id: "vacationTimeDashboard",
              title: "Dashboard",
              type: "item",
              path: routers.filter(
                (item: any) => item.id === "vacationTimeDashboard"
              )[0].path,
            },
            {
              id: "vacationCollaborator",
              title: "Periodos",
              type: "item",
              path: routers.filter(
                (item: any) => item.id === "vacationCollaborator"
              )[0].path,
            },
          ].sort((a, b) =>
            a.title.toString().toLowerCase() > b.title.toString().toLowerCase()
              ? 1
              : -1
          ),
        },
        {
          id: "requests",
          title: "Solicitações",
          type: "collapse",
          icon: <Difference />,
          children: [
            {
              id: "updateInformation",
              title: "Alteração cadastral",
              type: "item",
              path: routers.filter(
                (item: any) => item.id === "requestUpdateInformation"
              )[0].path,
            },
            {
              id: "vacation",
              title: "Férias",
              type: "item",
              path: routers.filter(
                (item: any) => item.id === "requestVacation"
              )[0].path,
            },
            {
              id: "leave",
              title: "Afastamentos",
              type: "item",
              path: routers.filter((item: any) => item.id === "requestLeave")[0]
                .path,
            },
            {
              id: "dismissals",
              title: "Desligamentos",
              type: "item",
              path: routers.filter(
                (item: any) => item.id === "requestDismissals"
              )[0].path,
            },
            {
              id: "dismissals",
              title: "Autorizações de desligamento",
              type: "item",
              path: routers.filter(
                (item: any) => item.id === "requestDismissalRequests"
              )[0].path,
            },
            {
              id: "promote",
              title: "Promoções",
              type: "item",
              path: routers.filter(
                (item: any) => item.id === "requestPromote"
              )[0].path,
            },
            {
              id: "salaryReview",
              title: "Revisão salarial",
              type: "item",
              path: routers.filter(
                (item: any) => item.id === "requestSalaryReview"
              )[0].path,
            },
            {
              id: "transfer",
              title: "Transferências",
              type: "item",
              path: routers.filter(
                (item: any) => item.id === "requestTransfer"
              )[0].path,
            },
            {
              id: "position",
              title: "Posições",
              type: "item",
              path: routers.filter(
                (item: any) => item.id === "requestPosition"
              )[0].path,
            },
            {
              id: "personal",
              title: "Requisição de pessoal",
              type: "item",
              path: routers.filter(
                (item: any) => item.id === "requestPersonal"
              )[0].path,
            },
          ].sort((a, b) =>
            a.title.toString().toLowerCase() > b.title.toString().toLowerCase()
              ? 1
              : -1
          ),
        },
        {
          id: "processes",
          title: "Processos",
          type: "item",
          path: routers.filter((item: any) => item.id === "processes")[0].path,
          icon: <DoneAll />,
          breadcrumbs: false,
        },
        {
          id: "notification",
          title: "Notificações",
          type: "item",
          path: routers.filter((item: any) => item.id === "notification")[0]
            .path,
          icon: <Notifications />,
        },
        {
          id: "audit",
          title: "Auditória",
          type: "item",
          path: routers.filter((item: any) => item.id === "audit")[0].path,
          icon: <Security />,
        },
        {
          id: "settings",
          title: "Configurações",
          type: "collapse",
          icon: <Settings />,
          children: [
            {
              id: "permissionsRequiredUpdateInformation",
              title: "Alteração cadastral",
              type: "item",
              path: routers.filter(
                (item: any) =>
                  item.id === "permissionsRequiredUpdateInformation"
              )[0].path,
            },
            {
              id: "permissionsRequiredVacation",
              title: "Férias",
              type: "item",
              path: routers.filter(
                (item: any) => item.id === "permissionsRequiredVacation"
              )[0].path,
            },
            {
              id: "permissionsRequiredLeave",
              title: "Afastamentos",
              type: "item",
              path: routers.filter(
                (item: any) => item.id === "permissionsRequiredLeave"
              )[0].path,
            },
            {
              id: "permissionsRequiredDismissals",
              title: "Desligamentos",
              type: "item",
              path: routers.filter(
                (item: any) => item.id === "permissionsRequiredDismissals"
              )[0].path,
            },
            {
              id: "permissionsRequiredPromote",
              title: "Promoções",
              type: "item",
              path: routers.filter(
                (item: any) => item.id === "permissionsRequiredPromote"
              )[0].path,
            },
            {
              id: "permissionsRequiredsalaryReview",
              title: "Revisão salarial",
              type: "item",
              path: routers.filter(
                (item: any) => item.id === "permissionsRequiredSalaryReview"
              )[0].path,
            },
            {
              id: "permissionsRequiredTransfer",
              title: "Transferências",
              type: "item",
              path: routers.filter(
                (item: any) => item.id === "permissionsRequiredTransfer"
              )[0].path,
            },
            {
              id: "permissionsRequiredPosition",
              title: "Posições",
              type: "item",
              path: routers.filter(
                (item: any) => item.id === "permissionsRequiredPosition"
              )[0].path,
            },
            {
              id: "permissionsRequiredPersonnel",
              title: "Requisição de pessoal",
              type: "item",
              path: routers.filter(
                (item: any) => item.id === "permissionsRequiredPersonnel"
              )[0].path,
            },
            {
              id: "permissionGroup",
              title: "Grupos",
              type: "item",
              path: routers.filter(
                (item: any) => item.id === "permissionGroup"
              )[0].path,
            },
            {
              id: "permissionLetter",
              title: "Cartas",
              type: "item",
              path: routers.filter(
                (item: any) => item.id === "permissionLetter"
              )[0].path,
            },
            {
              id: "usersRole",
              title: "Perfis de usuários",
              type: "item",
              path: routers.filter((item: any) => item.id === "usersRole")[0]
                .path,
            },
            {
              id: "permissionsRequiredForgetPassword",
              title: "Recuperar senha",
              type: "item",
              path: routers.filter(
                (item: any) => item.id === "permissionsRequiredForgetPassword"
              )[0].path,
            },
            {
              id: "permissionsAudit",
              title: "Auditória",
              type: "item",
              path: routers.filter(
                (item: any) => item.id === "permissionsAudit"
              )[0].path,
            },
            {
              id: "calendar",
              title: "Calendário",
              type: "item",
              path: routers.filter((item: any) => item.id === "calendar")[0]
                .path,
            },
            {
              id: "grades",
              title: "Grades",
              type: "item",
              path: routers.filter((item: any) => item.id === "grades")[0].path,
            },
          ].sort((a, b) =>
            a.title.toString().toLowerCase() > b.title.toString().toLowerCase()
              ? 1
              : -1
          ),
        },
      ],
    },
  ],
};
