/** @format */

import { Cancel, CheckCircle } from "@mui/icons-material";
import { Grid, TextField } from "@mui/material";
import { AlertDialog, Flex } from "@radix-ui/themes";
import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import { GenericError } from "../../helpers/genericError";
import { updateLoading } from "../../redux/reducers/common";
import { PostInstante } from "../../services/api";
import { ButtonComponent } from "./buttonComponent";

const validationSchema = yup.object({
  action_justification: yup
    .string()
    .nullable()
    .required("Justificativa é obrigatório"),
});

interface Props {
  id: string | number | null | undefined;
  url: string;
  close?: () => void;
  beforeAction?: (onSubmit: any) => void;
}

export function ActionDisapproved({ id, url, beforeAction, close }: Props) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const formik: any = useFormik({
    initialValues: {
      action_justification: "",
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      close && close();
      setOpen(false);
      dispatch(updateLoading(true));
      PostInstante(
        null,
        {
          action_type: "disapproved",
          action_justification: formik.values.action_justification,
        },
        "/" + url + "/" + id + "/action"
      )
        .then((response: any) => {
          dispatch(updateLoading(false));
          toast.success(response?.data?.message);
          setTimeout(() => {
            window.history.back();
          }, 3000);
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <AlertDialog.Root open={open} onOpenChange={setOpen}>
        <AlertDialog.Trigger>
          <ButtonComponent
            variant={"contained"}
            color={"error"}
            endIcon={<Cancel />}>
            Reprovar
          </ButtonComponent>
        </AlertDialog.Trigger>
        <AlertDialog.Content maxWidth='450px'>
          <AlertDialog.Title>
            Deseja realmente reprovar esta solicitação?
          </AlertDialog.Title>
          <AlertDialog.Description size='2'>
            Justifique abaixo o motivo da reprovação.
          </AlertDialog.Description>
          <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
            <div>
              <TextField
                fullWidth
                id='action_justification'
                label='Justificativa'
                variant='outlined'
                multiline={true}
                rows={4}
                value={formik.values.action_justification}
                onChange={(event: any) =>
                  formik.setFieldValue(
                    "action_justification",
                    event.target.value
                  )
                }
                onBlur={formik.handleBlur}
                error={
                  formik.touched.action_justification &&
                  Boolean(formik.errors.action_justification)
                }
                helperText={
                  formik.touched.action_justification &&
                  formik.errors.action_justification
                }
              />
            </div>
          </Grid>
          <Flex gap='3' mt='4' justify='end'>
            <AlertDialog.Cancel>
              <ButtonComponent
                variant={"outlined"}
                color='error'
                endIcon={<Cancel />}>
                Desistir
              </ButtonComponent>
            </AlertDialog.Cancel>
            <AlertDialog.Action>
              <ButtonComponent
                variant={"contained"}
                color='success'
                onClick={
                  beforeAction
                    ? beforeAction(formik.handleSubmit)
                    : formik.handleSubmit
                }
                endIcon={<CheckCircle />}>
                Confirmar
              </ButtonComponent>
            </AlertDialog.Action>
          </Flex>
        </AlertDialog.Content>
      </AlertDialog.Root>
    </form>
  );
}
