/** @format */

import { Grid, MenuItem, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { leave_e_social } from "../../../../../config/constants";

export function Form({ formik, edit, showDate }: any) {
  return (
    <>
      <Grid
        gridTemplateColumns={showDate ? "1fr 1fr 1fr " : "1fr 1fr"}
        display={"grid"}
        gap={2}>
        <DatePicker
          slotProps={{
            textField: {
              fullWidth: true,
              id: "absence_date",
              onBlur: formik.handleBlur,
              error:
                formik.touched.absence_date &&
                Boolean(formik.errors.absence_date),
              helperText:
                formik.touched.absence_date && formik.errors.absence_date,
            },
          }}
          disabled={edit}
          label='Data de saída'
          value={formik.values.absence_date}
          onChange={(value: any) => {
            formik.setFieldValue("absence_date", value);
          }}
        />
        <DatePicker
          slotProps={{
            textField: {
              fullWidth: true,
              id: "return_date",
              onChange: (value: any) => {
                formik.setFieldValue("return_date", value, true);
              },
              onBlur: formik.handleBlur,
              error:
                formik.touched.return_date &&
                Boolean(formik.errors.return_date),
              helperText:
                formik.touched.return_date && formik.errors.return_date,
            },
          }}
          disabled={edit}
          label='Data de retorno'
          value={formik.values.return_date}
          onChange={(value: any) => {
            formik.setFieldValue("return_date", value, true);

            if (value < (formik.values.absence_date as any)) {
              formik.setFieldTouched("return_date");
            }
          }}
        />
        {showDate && (
          <DatePicker
            slotProps={{
              textField: {
                id: "return_date",
                onBlur: formik.handleBlur,
                error:
                  formik.touched.return_date &&
                  Boolean(formik.errors.return_date),
                helperText:
                  formik.touched.return_date && formik.errors.return_date,
              },
            }}
            label='Nova data de retorno'
            value={formik.values.return_date}
            onChange={(value: any) => {
              formik.setFieldValue("return_date", value);
            }}
          />
        )}
      </Grid>
      <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
        <TextField
          fullWidth
          disabled={edit}
          id='e_social_motive'
          select
          label='Motivo do afastamento e-Social'
          value={formik.values.e_social_motive}
          onChange={(event: any) =>
            formik.setFieldValue("e_social_motive", event.target.value)
          }
          onBlur={formik.handleBlur}
          error={
            formik.touched.e_social_motive &&
            Boolean(formik.errors.e_social_motive)
          }
          helperText={
            formik.touched.e_social_motive && formik.errors.e_social_motive
          }>
          {leave_e_social.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.value} - {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
        <div>
          <TextField
            fullWidth
            disabled={edit}
            id='observations'
            label='Informações sobre o afastamento'
            variant='outlined'
            multiline={true}
            rows={4}
            value={formik.values.observations}
            onChange={(event: any) =>
              formik.setFieldValue("observations", event.target.value)
            }
            onBlur={formik.handleBlur}
            error={
              formik.touched.observations && Boolean(formik.errors.observations)
            }
            helperText={
              formik.touched.observations && formik.errors.observations
            }
          />
        </div>
      </Grid>
    </>
  );
}
