/** @format */

import { Download, InfoOutlined, Undo } from "@mui/icons-material";
import { Breadcrumbs, Button, Grid, Link, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { Callout } from "@radix-ui/themes";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { EmptyData } from "../../../../../helpers/emptyData";
import { FormatCurrency } from "../../../../../helpers/formatCurrency";
import { FormatStatus } from "../../../../../helpers/formatStatus";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance } from "../../../../../services/api";
import "./styles.css";

export function TransferLetter() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [data, setData]: any = useState({
    collaborator_data: {},
    solicitation_data: {},
    transfer_data: {},
  });
  const company = useSelector((state: any) => state.company);

  useEffect(() => {
    GetInstance({ transfer_uuid: id }, "/transfers/update_form")
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        setData({
          ...response.data.data,
          transfer_data: {
            ...response.data.data.transfer_data,
            transfer_date: dayjs(
              response.data.data.transfer_data.transfer_date
            ).format("DD/MM/YYYY"),
          },
        });
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const userInfo = [
    {
      title: "Nome",
      value: data.collaborator_data.name,
    },
    {
      title: "Cód. cargo",
      value: EmptyData(data.collaborator_data.job_role_code),
    },
    {
      title: "Cargo",
      value: EmptyData(data.collaborator_data.job_role_name),
    },
    {
      title: "Salário atual",
      value: data.collaborator_data.remuneration_value,
    },
    {
      title: "Status",
      value: FormatStatus(data.collaborator_data.status),
    },
  ];

  const requestInfo = [
    {
      title: "Código",
      value: data.solicitation_data.code,
    },
    {
      title: "Data",
      value: data.solicitation_data.date,
    },
    {
      title: "Solicitante",
      value: EmptyData(data.solicitation_data.created_by),
    },
    {
      title: "Status",
      value: FormatStatus(data.solicitation_data.status),
    },
    {
      title: "Justificativa",
      value: EmptyData(data.solicitation_data.action_justification),
    },
  ];

  return (
    <>
      <Grid container gap={2}>
        <Grid container justifyContent={"space-between"}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/'>
              Inicio
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/transfer'>
              Solicitações
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/transfer'>
              Transferências
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href={"/request/promote/details/" + id}>
              Detalhes da solicitação
            </Link>
            <Typography fontWeight={800}>Carta de comunicação</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid container>
          <Callout.Root size='2' style={{ alignItems: "center" }}>
            <Callout.Icon>
              <InfoOutlined />
            </Callout.Icon>
            <Grid container flexDirection={"column"}>
              <Typography fontSize={16} fontWeight={800}>
                Informações do colaborador
              </Typography>
              <Grid container gap={"12px 96px"} mt={1}>
                {userInfo.map((item: any) => (
                  <Grid key={item.title}>
                    <Typography fontSize={12} fontWeight={600}>
                      {item.title}
                    </Typography>
                    <Typography fontSize={10} fontWeight={400}>
                      {item.value}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
              <Grid container flexDirection={"column"} mt={2}>
                <Typography fontSize={16} fontWeight={800}>
                  Informações da solicitação
                </Typography>
                <Grid container gap={"12px 96px"} mt={1}>
                  {requestInfo.map((item: any) => (
                    <Grid key={item.title}>
                      <Typography fontSize={12} fontWeight={600}>
                        {item.title}
                      </Typography>
                      <Typography fontSize={10} fontWeight={400}>
                        {item.value}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Callout.Root>
        </Grid>
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Carta de comunicação
              </Typography>
            </Grid>
            <Grid
              display={"flex"}
              justifyContent={"space-between"}
              flexDirection={"column"}
              className='printable-content'
              height={"100%"}>
              {data.transfer_data.with_promotion ? (
                <Grid height={"100%"}>
                  <Grid gridTemplateColumns={"1fr"} display={"grid"}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}>
                      <img {...company.logoSmall} alt='Logo da empresa' />
                      <div style={{ gap: 12, display: "flex" }}>
                        {company?.logos?.map((item: any) => (
                          <img {...item} alt='Logo da empresa' />
                        ))}
                      </div>
                    </div>
                    <Typography fontSize={12} mb={3} mt={3}>
                      Prezado(a) {data.collaborator_data.name},
                    </Typography>
                    <Typography fontSize={12} mb={3}>
                      A {company.name} reconhece sua dedicação, empenho e
                      contribuição pela busca constante de resultados, motivo
                      pelo qual gostaríamos de parabenizá-lo(a) pela conquista
                      de uma importante etapa em sua carreira.
                    </Typography>
                    <Typography fontSize={12} mb={3}>
                      Por essas razões, informamos que sua promoção para o cargo
                      de {data.transfer_data?.position_job_role_name || ""}{" "}
                      reflete nossa confiança no seu potencial para assumir
                      novas responsabilidades e desafios. Você está recebendo um
                      aumento de{" "}
                      {data.transfer_data?.new_remuneration_percentage || 0}% e
                      seu salário passará a ser R${" "}
                      {FormatCurrency({
                        target: {
                          value: `${data.transfer_data?.new_remuneration_value}`,
                        },
                      }) || 0}{" "}
                      a partir de {data.transfer_data?.transfer_date || ""}.
                    </Typography>
                    <Typography fontSize={12} mb={3}>
                      Além disso, como parte desta nova fase em sua carreira,
                      você será transferido(a) para{" "}
                      {data.transfer_data?.department_name || ""}. Acreditamos
                      que esta mudança será uma excelente oportunidade para você
                      aplicar suas habilidades em um novo ambiente e contribuir
                      ainda mais para o nosso sucesso coletivo.
                    </Typography>
                    <Typography fontSize={12} mb={3}>
                      Parabéns!
                    </Typography>
                    <Typography fontSize={12}>
                      {data.solicitation_data.created_by}
                    </Typography>
                    <Grid gridTemplateColumns={"1fr"} display={"grid"}>
                      <Typography align='center' fontSize={12}>
                        __________________________________________________________________________
                      </Typography>
                      <Typography fontWeight={800} align='center' fontSize={12}>
                        {data.transfer_data?.new_manager_name === "-"
                          ? "Não informado"
                          : data.transfer_data?.new_manager_name}
                      </Typography>
                      <Typography align='center' fontSize={12}>
                        {data.transfer_data?.new_manager_job_role_name === "-"
                          ? "Não informado"
                          : data.transfer_data?.new_manager_job_role_name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid height={"100%"}>
                  <Grid gridTemplateColumns={"1fr"} display={"grid"}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}>
                      <img {...company.logoSmall} alt='Logo da empresa' />
                      <div style={{ gap: 12, display: "flex" }}>
                        {company?.logos?.map((item: any) => (
                          <img {...item} alt='Logo da empresa' />
                        ))}
                      </div>
                    </div>
                    <Typography fontSize={12} mb={3} mt={3}>
                      Prezado(a) {data.collaborator_data.name},
                    </Typography>
                    <Typography fontSize={12} mb={3}>
                      É com satisfação que informamos sua transferência para o{" "}
                      {data.transfer_data?.department_name || ""}, a partir de{" "}
                      {data.transfer_data?.transfer_date || ""}.
                    </Typography>
                    <Typography fontSize={12} mb={3}>
                      Acreditamos que sua experiência e competências serão
                      extremamente valiosas para a equipe do{" "}
                      {data.transfer_data?.department_name || ""}, e estamos
                      confiantes de que sua contribuição será fundamental para o
                      sucesso contínuo de nossa empresa.
                    </Typography>
                    <Typography fontSize={12} mb={3}>
                      Agradecemos antecipadamente por sua colaboração e
                      flexibilidade durante este período de transição. Estamos à
                      disposição para auxiliar no que for necessário para
                      facilitar este processo.
                    </Typography>
                    <Typography fontSize={12} mb={3}>
                      Atenciosamente,
                    </Typography>
                    <Typography fontSize={12}>
                      {data.solicitation_data.created_by}
                    </Typography>
                    <Grid gridTemplateColumns={"1fr"} display={"grid"}>
                      <Typography align='center' fontSize={12}>
                        __________________________________________________________________________
                      </Typography>
                      <Typography fontWeight={800} align='center' fontSize={12}>
                        {data.transfer_data?.new_manager_name === "-"
                          ? "Não informado"
                          : data.transfer_data?.new_manager_name}
                      </Typography>
                      <Typography align='center' fontSize={12}>
                        {data.transfer_data?.new_manager_job_role_name === "-"
                          ? "Não informado"
                          : data.transfer_data?.new_manager_job_role_name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Paper>
          <Grid container gap={2} mt={2} justifyContent={"end"}>
            <Button
              variant={"outlined"}
              onClick={() => {
                window.history.back();
              }}
              fullWidth={isMobile}
              endIcon={<Undo />}>
              Voltar
            </Button>
            <Button
              fullWidth={isMobile}
              variant={"contained"}
              onClick={() => window.print()}
              endIcon={<Download />}>
              Baixar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
