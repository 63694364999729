/** @format */

import { Grid, MenuItem, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import {
  motive_e_social,
  motive_e_social_manager,
} from "../../../../../config/constants";
import {
  ClearCurrency,
  FormatCurrency,
  MaskCurrency,
} from "../../../../../helpers/formatCurrency";
import { ReadjustSalary } from "../../../../../helpers/readjustSalary";

export function Form({ formik, userData, edit }: any) {
  return (
    <>
      <Grid gridTemplateColumns={"1fr 1fr"} display={"grid"} gap={2}>
        <TextField
          fullWidth
          id='e_social_motive'
          select
          label='Motivo da revisão'
          value={formik.values.e_social_motive}
          onChange={(event: any) =>
            formik.setFieldValue("e_social_motive", event.target.value)
          }
          disabled={edit}
          onBlur={formik.handleBlur}
          error={
            formik.touched.e_social_motive &&
            Boolean(formik.errors.e_social_motive)
          }
          helperText={
            formik.touched.e_social_motive && formik.errors.e_social_motive
          }>
          {userData.role === "rh"
            ? motive_e_social.map((option: any) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))
            : motive_e_social_manager.map((option: any) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
        </TextField>
        <DatePicker
          slotProps={{
            textField: {
              fullWidth: true,
              id: "move_date",
              onBlur: formik.handleBlur,
              error:
                formik.touched.move_date && Boolean(formik.errors.move_date),
              helperText: formik.touched.move_date && formik.errors.move_date,
            },
          }}
          disabled={edit}
          label='Data da movimentação'
          value={formik.values.move_date}
          onChange={(value: any) => {
            formik.setFieldValue("move_date", value);
          }}
        />
      </Grid>
      <Grid gridTemplateColumns={"1fr 1fr"} mt={2} display={"grid"} gap={2}>
        <TextField
          fullWidth
          id='percent'
          type={"number"}
          disabled={edit}
          label='Porcentagem (%)'
          value={formik.values.percent}
          onChange={(event: any) => {
            formik.setFieldValue("percent", event.target.value);
            formik.setFieldValue(
              "future_salary",
              MaskCurrency(
                ((parseFloat(event.target.value) + 100) *
                  parseFloat(formik.values.actual_salary)) /
                  100
              )
            );
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.percent && Boolean(formik.errors.percent)}
          helperText={formik.touched.percent && formik.errors.percent}
        />
        <TextField
          fullWidth
          id='future_salary'
          label='Salário futuro'
          disabled={edit}
          value={formik.values.future_salary}
          onChange={(event: any) => {
            formik.setFieldValue(
              "percent",
              ReadjustSalary(
                Number(formik.values.actual_salary),
                ClearCurrency(FormatCurrency(event))
              )
            );
            formik.setFieldValue("future_salary", FormatCurrency(event));
          }}
          onBlur={formik.handleBlur}
          error={
            formik.touched.future_salary && Boolean(formik.errors.future_salary)
          }
          helperText={
            formik.touched.future_salary && formik.errors.future_salary
          }
        />
      </Grid>
      <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
        <div>
          <TextField
            fullWidth
            id='observations'
            label='Comentários'
            variant='outlined'
            disabled={edit}
            multiline={true}
            rows={4}
            value={formik.values.observations}
            onChange={(event: any) =>
              formik.setFieldValue("observations", event.target.value)
            }
            onBlur={formik.handleBlur}
            error={
              formik.touched.observations && Boolean(formik.errors.observations)
            }
            helperText={
              formik.touched.observations && formik.errors.observations
            }
          />
        </div>
      </Grid>
    </>
  );
}
