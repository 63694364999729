/** @format */

import * as yup from "yup";

export const validationSchema = yup.object({
  dismissal_date: yup
    .string()
    .nullable()
    .required("Data de saída é obrigatório"),
  early_warning: yup.string().nullable().required("Campo é obrigatório"),
  dismissal_type_id: yup.string().nullable().required("Campo é obrigatório"),
  dismissal_reason_id: yup.string().nullable().required("Campo é obrigatório"),
  comment: yup.string().nullable().required("Campo é obrigatório"),
});

export const columns = [
  { name: "solicitation_code", label: "Código" },
  { name: "collaborator_name", label: "Nome" },
  { name: "solicitation_date", label: "Data da solicitação" },
  { name: "dismissal_date", label: "Data do deslig. pretendida" },
  { name: "solicitation_status", label: "Status" },
];
