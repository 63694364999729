/** @format */
import * as yup from "yup";
export const columns = [
  { name: "solicitation_code", label: "Código" },
  { name: "job_role_name", label: "Cargo" },
  { name: "solicitation_date", label: "Data da solicitação" },
  { name: "initial_date", label: "Data do início pretendida" },
  { name: "created_by_name", label: "Solicitante" },
  { name: "department_name", label: "Departamento" },
  { name: "solicitation_status", label: "Status" },
];

export const validationSchema = yup.object({
  initial_date: yup
    .date()
    .min(new Date(), "Data é igual ou anterior a data de hoje")
    .nullable()
    .required("Data é obrigatório"),
  job_role_id: yup.string().nullable(),
  salary: yup.string().nullable(),
  position_id: yup.string().nullable().required("Esse campo é obrigatório"),
});
