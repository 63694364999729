/** @format */

import { Cancel, CheckCircle } from "@mui/icons-material";
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { AlertDialog, Flex } from "@radix-ui/themes";

export const DialogPosition = ({
  formik,
  onSubmit,
  setOpenModalPosition,
}: any) => {
  return (
    <AlertDialog.Root open>
      <AlertDialog.Content maxWidth='450px'>
        <AlertDialog.Title color='red'>Atenção</AlertDialog.Title>
        <AlertDialog.Description size='2' style={{ gap: 12 }}>
          Por favor escolha o destino da posição do colaborador,
        </AlertDialog.Description>
        <Flex mt='1'>
          <FormControl>
            <AlertDialog.Description size='2' style={{ gap: 12 }}>
              Vai manter a posição?
            </AlertDialog.Description>
            <RadioGroup
              value={formik.values.position_delete}
              onChange={(event) =>
                formik.setFieldValue("position_delete", event.target.value)
              }
              defaultValue={formik.values.position_delete}>
              <FormControlLabel value={true} control={<Radio />} label='Sim' />
              <FormControlLabel value={false} control={<Radio />} label='Não' />
            </RadioGroup>
          </FormControl>
        </Flex>
        <Flex gap='3' mt='4' justify='end'>
          <AlertDialog.Cancel>
            <Button
              color='error'
              variant={"outlined"}
              onClick={() => setOpenModalPosition(false)}
              endIcon={<Cancel />}>
              Cancelar
            </Button>
          </AlertDialog.Cancel>
          <AlertDialog.Action>
            <Button
              color='success'
              variant={"contained"}
              onClick={onSubmit}
              endIcon={<CheckCircle />}>
              Confirmar
            </Button>
          </AlertDialog.Action>
        </Flex>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};
