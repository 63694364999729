/** @format */

import { GroupAdd, Visibility } from "@mui/icons-material";
import { Grid, IconButton, Tooltip } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";
import { ActionRemove } from "../../../../components/refactor/actionRemove";
import { BreadcrumbsComponent } from "../../../../components/refactor/breadcrumbsComponent";
import { ButtonComponent } from "../../../../components/refactor/buttonComponent";
import { optionsTable } from "../../../../config/optionsTable";
import { IsPermissions } from "../../../../helpers/isPermission";
import usePosition from "../../../../services/protected/hooks/usePosition";
import { columns } from "./helpers/constants";

export function PositionRequest() {
  const router = useNavigate();
  const {
    data,
    total,
    page,
    setPage,
    setPerPage,
    perPage,
    searchCall,
    setOrder,
  } = usePosition();

  return (
    <Grid container gap={2}>
      <BreadcrumbsComponent
        links={[
          { label: "Início" },
          { label: "Solicitações", value: "/request/position" },
          { label: "Posições" },
        ]}
        buttons={
          <ButtonComponent
            variant={"contained"}
            onClick={() => {
              router("/request/position/new");
            }}
            endIcon={<GroupAdd />}>
            Nova posição
          </ButtonComponent>
        }
      />
      <MUIDataTable
        title={""}
        data={data}
        columns={[
          {
            name: "action",
            label: "Ações",
            options: {
              sort: false,
              customBodyRenderLite: (dataIndex: number) => {
                return (
                  <Grid container>
                    <IsPermissions keyPrivate={"requestPositionDetails"}>
                      <Tooltip title={"Ver solicitação"}>
                        <IconButton
                          onClick={() =>
                            router(
                              `/request/position/details/${data[dataIndex].uuid}`
                            )
                          }>
                          <Visibility color='primary' />
                        </IconButton>
                      </Tooltip>
                    </IsPermissions>
                    <ActionRemove
                      statusList={data[dataIndex].solicitation_status}
                      url='/new_positions'
                      id={data[dataIndex].uuid}
                    />
                  </Grid>
                );
              },
            },
          },
          ...columns,
        ]}
        options={{
          ...optionsTable,
          searchPlaceholder: "Pesquise por nome",
          download: false,
          onSearchChange: (value: string) => {
            searchCall(value);
          },
          onColumnSortChange: (value: string, direction: string) => {
            setOrder(`${value} ${direction}`);
          },
          count: total,
          page,
          onChangePage: (page: number) => {
            setPage(page);
          },
          rowsPerPage: perPage,
          onChangeRowsPerPage: (perPage: number) => {
            setPage(0);
            setPerPage(perPage);
          },
        }}
      />
    </Grid>
  );
}
