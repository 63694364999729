/** @format */

import { CssBaseline, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AlertDialog, Flex, Theme } from "@radix-ui/themes";
import "@radix-ui/themes/styles.css";
import "dayjs/locale/pt-br";
import Cookies from "js-cookie";
import React from "react";
import ReactDOM from "react-dom/client";
import Lottie from "react-lottie";
import { Provider, useSelector } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loadingData from "./assets/lotties/loading2.json";
import loadingSessao from "./assets/lotties/loadingSessao.json";
import { router } from "./config/router";
import { ThemeMui } from "./config/theme";
import "./index.css";
import store from "./redux/store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

function Loading() {
  const loading = useSelector((state: any) => state.common.loading);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <AlertDialog.Root open={loading}>
      <AlertDialog.Content maxWidth='450px'>
        <Flex gap='3' mt='4' justify='end'>
          <Lottie options={defaultOptions} height={100} width={"auto"} />
        </Flex>
        <AlertDialog.Description size='5' align={"center"}>
          Carregando ...
        </AlertDialog.Description>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
}

function LoadingSessao() {
  const loading = useSelector((state: any) => state.common.loadingSessao);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingSessao,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <AlertDialog.Root open={loading}>
      <AlertDialog.Content maxWidth='450px'>
        <Flex gap='3' mt='4' justify='end'>
          <Lottie options={defaultOptions} height={150} width={"auto"} />
        </Flex>
        <AlertDialog.Description size='5' align={"center"}>
          Iniciando a sessão...
        </AlertDialog.Description>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
}

function App() {
  const mode: any = Cookies.get("theme") ?? "light";
  const company = useSelector((state: any) => state?.company);
  const loading = useSelector((state: any) => state.common.loadingSessao);
  return (
    <ThemeProvider theme={ThemeMui()}>
      <Theme appearance={mode} accentColor={company.colorType}>
        <CssBaseline />
        {loading ? <LoadingSessao /> : <Loading />}
        <RouterProvider router={router} />
      </Theme>
    </ThemeProvider>
  );
}

root.render(
  <React.StrictMode>
    <ToastContainer
      position={"top-right"}
      autoClose={5000}
      closeOnClick
      pauseOnFocusLoss
      pauseOnHover
      theme={"colored"}
    />
    <Provider store={store}>
      <LocalizationProvider adapterLocale={"pt-br"} dateAdapter={AdapterDayjs}>
        <App />
      </LocalizationProvider>
    </Provider>
  </React.StrictMode>
);
