/** @format */

import { Download } from "@mui/icons-material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { leave_e_social } from "../../../../../config/constants";
import { FormatDate } from "../../../../../helpers/formatDate";

export function HistoryComponent({ data }: any) {
  const theme = useTheme();
  const [expandedAccordionAbsence, setExpandedAccordion] = useState<any>(null);

  const handleChangeAccordionAbsence =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedAccordion(isExpanded ? panel : false);
    };
  return data?.history_data?.length > 0 ? (
    <>
      <Typography fontSize={18} fontWeight={500}>
        Histórico do Afastamento
      </Typography>
      {data?.history_data?.map((history: any, index: number) => (
        <Accordion
          sx={{
            marginTop: 3.5,
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: 1,
            "&:before": {
              display: "none",
            },
          }}
          expanded={expandedAccordionAbsence === history.id}
          onChange={handleChangeAccordionAbsence(history.id)}>
          <AccordionSummary
            aria-controls='panel1d-content'
            id='panel1d-header'
            expandIcon={
              <ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />
            }
            sx={{
              margin: 0,
              flexDirection: "row-reverse",
              "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                transform: "rotate(90deg)",
              },
            }}>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              width='100%'>
              <Typography marginLeft={2} fontSize={18} fontWeight={500}>
                {index + 1} - {FormatDate(history.created_at)}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Grid display='flex' flexDirection='column' gap={2}>
              <Grid gridTemplateColumns={"1fr 1fr"} display={"grid"} gap={2}>
                <TextField
                  fullWidth
                  label='Data do afastamento'
                  variant='outlined'
                  rows={4}
                  disabled
                  value={FormatDate(history.absence_date)}
                />
                <TextField
                  fullWidth
                  label='Data do retorno'
                  variant='outlined'
                  rows={4}
                  disabled
                  value={FormatDate(history.return_date)}
                />
              </Grid>
              <Grid display='flex' flexDirection='column' gap={2}>
                <TextField
                  fullWidth
                  label='Motivo do afastamento e-social'
                  variant='outlined'
                  multiline={true}
                  rows={2}
                  disabled
                  value={
                    leave_e_social?.find(
                      (e) => e.value === history?.e_social_motive
                    )?.label
                  }
                />
                <TextField
                  fullWidth
                  label='Informações sobre o afastamento'
                  variant='outlined'
                  multiline={true}
                  rows={4}
                  disabled
                  value={history?.observations}
                />
                <Grid container mt={2} gap={2}>
                  {history.files.map((file: any, index: number) => (
                    <Tooltip title={"Baixar arquivo"}>
                      <a
                        title={file.name}
                        href={file.url}
                        target='_blank'
                        style={{
                          border: theme.palette.primary.main + " 1px solid",
                          color: theme.palette.primary.main,
                          padding: 8,
                          fontSize: 12,
                          borderRadius: 24,
                          fontWeight: 800,
                          textDecorationLine: "none",
                          display: "flex",
                          gap: 4,
                          alignItems: "center",
                        }}
                        download={file.name}
                        rel='noreferrer'>
                        <Download fontSize={"small"} />
                        {file.name}
                      </a>
                    </Tooltip>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  ) : (
    <></>
  );
}
