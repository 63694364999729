/** @format */

import { Visibility } from "@mui/icons-material";
import { Grid, IconButton, Tooltip } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";
import { ActionRemove } from "../../../../components/refactor/actionRemove";
import { BreadcrumbsComponent } from "../../../../components/refactor/breadcrumbsComponent";
import { optionsTable } from "../../../../config/optionsTable";
import { IsPermissions } from "../../../../helpers/isPermission";
import useDismissalRequests from "../../../../services/protected/hooks/useDismissalRequest";
import { columns } from "./helpers/constants";

export function DismissalRequests() {
  const router = useNavigate();
  const {
    data,
    searchCall,
    setOrder,
    page,
    setPage,
    setPerPage,
    perPage,
    total,
  } = useDismissalRequests();

  return (
    <Grid container gap={2}>
      <BreadcrumbsComponent
        links={[
          { label: "Início" },
          { label: "Solicitações", value: "/request/dismissalRequests" },
          {
            label: "Autorizações de desligamento",
          },
        ]}
      />
      <Grid container>
        <MUIDataTable
          title={""}
          data={data}
          columns={[
            {
              name: "action",
              label: "Ações",
              options: {
                sort: false,
                customBodyRenderLite: (dataIndex: number) => {
                  return (
                    <Grid container>
                      <IsPermissions
                        keyPrivate={"requestDismissalRequestsDetails"}>
                        <Tooltip title={"Ver solicitação"}>
                          <IconButton
                            onClick={() =>
                              router(
                                `/request/dismissalRequests/details/${data[dataIndex].uuid}`
                              )
                            }>
                            <Visibility color='primary' />
                          </IconButton>
                        </Tooltip>
                      </IsPermissions>
                      <ActionRemove
                        statusList={data[dataIndex].solicitation_status}
                        url='/dismissal_requests'
                        id={data[dataIndex].uuid}
                      />
                    </Grid>
                  );
                },
              },
            },
            ...columns,
          ]}
          options={{
            ...optionsTable,
            searchPlaceholder: "Pesquise por nome",
            download: false,
            onSearchChange: (value: string) => {
              searchCall(value);
            },
            onColumnSortChange: (value: string, direction: string) => {
              setOrder(`${value} ${direction}`);
            },
            count: total,
            page,
            onChangePage: (page: number) => {
              setPage(page);
            },
            rowsPerPage: perPage,
            onChangeRowsPerPage: (perPage: number) => {
              setPage(0);
              setPerPage(perPage);
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
