/** @format */
import * as yup from "yup";
export const columns = [
  { name: "solicitation_code", label: "Código" },
  { name: "collaborator_name", label: "Nome" },
  { name: "solicitation_date", label: "Data da solicitação" },
  { name: "dismissal_date", label: "Data do deslig. pretendida" },
  { name: "solicitation_status", label: "Status" },
];

export const validationSchema = yup.object({
  dismissal_date: yup
    .string()
    .nullable()
    .required("Data de saída é obrigatório"),
  early_warning: yup.string().nullable().required("Campo é obrigatório"),
  dismissal_type: yup.string().nullable().required("Campo é obrigatório"),
  dismissal_reason: yup.string().nullable().required("Campo é obrigatório"),
  comment: yup
    .string()
    .nullable()
    .test({
      name: "dismissal_reason",
      message: "Campo é obrigatório",
      test: (_value, context) => {
        const valueField = context.options?.context?.dismissal_reason;
        return valueField;
      },
    }),
  files: yup
    .array()
    .min(1, "Precisa ter ao menos 1 anexo")
    .nullable()
    .required("Arquivo é obrigatório"),
});
