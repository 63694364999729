/** @format */

import { Cancel, CheckCircle } from "@mui/icons-material";
import { Button, Grid, TextField } from "@mui/material";
import { AlertDialog, Flex } from "@radix-ui/themes";
import { useFormik } from "formik";
import Lottie from "react-lottie";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import animationData from "../assets/lotties/loading_modal.json";
import { GenericError } from "../helpers/genericError";
import { updateLoading } from "../redux/reducers/common";
import { PostInstante } from "../services/api";

const validationSchema = yup.object({
  action_justification: yup
    .string()
    .nullable()
    .required("Justificativa é obrigatório"),
});

export function ActionReturnedRule({ rule, id, url }: any) {
  const dispatch = useDispatch();
  const loading = useSelector((state: any) => state.common.loading);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const formik: any = useFormik({
    initialValues: {
      action_justification: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      PostInstante(
        null,
        {
          action_type: "returned",
          action_justification: formik.values.action_justification,
        },
        "/" + url + "/" + id + "/action"
      )
        .then((response: any) => {
          dispatch(updateLoading(false));
          toast.success(response?.data?.message);
          setTimeout(() => {
            window.history.back();
          }, 3000);
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  return (
    rule && (
      <form onSubmit={formik.handleSubmit}>
        <AlertDialog.Root>
          <AlertDialog.Trigger>
            <></>
            {/* <Button
              variant={"contained"}
              color={"warning"}
              fullWidth={isMobile}
              endIcon={<CancelScheduleSend />}>
              Devolver
            </Button> */}
          </AlertDialog.Trigger>
          <AlertDialog.Content maxWidth='450px'>
            {loading ? (
              <Lottie options={defaultOptions} height={"auto"} width={200} />
            ) : (
              <>
                <AlertDialog.Title>
                  Deseja realmente devolver esta solicitação?
                </AlertDialog.Title>
                <AlertDialog.Description size='2'>
                  Justifique abaixo o motivo da devolução.
                </AlertDialog.Description>
                <Grid
                  gridTemplateColumns={"1fr"}
                  mt={2}
                  display={"grid"}
                  gap={2}>
                  <div>
                    <TextField
                      fullWidth
                      id='action_justification'
                      label='Justificativa'
                      variant='outlined'
                      multiline={true}
                      rows={4}
                      value={formik.values.action_justification}
                      onChange={(event: any) =>
                        formik.setFieldValue(
                          "action_justification",
                          event.target.value
                        )
                      }
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.action_justification &&
                        Boolean(formik.errors.action_justification)
                      }
                      helperText={
                        formik.touched.action_justification &&
                        formik.errors.action_justification
                      }
                    />
                  </div>
                </Grid>
                <Flex gap='3' mt='4' justify='end'>
                  <AlertDialog.Cancel>
                    <Button variant={"outlined"} endIcon={<Cancel />}>
                      Cancelar
                    </Button>
                  </AlertDialog.Cancel>
                  <AlertDialog.Action>
                    <Button
                      variant={"contained"}
                      onClick={formik.handleSubmit}
                      endIcon={<CheckCircle />}>
                      Confirmar
                    </Button>
                  </AlertDialog.Action>
                </Flex>
              </>
            )}
          </AlertDialog.Content>
        </AlertDialog.Root>
      </form>
    )
  );
}
