/** @format */

import { CloseFullscreen, OpenInFull } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { Avatar, IconButton, Tooltip } from "@radix-ui/themes";
import { useState } from "react";
import { TreeNode } from "react-organizational-chart";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CardUser } from "./cardUser";
import { OrgProps } from "./createOrg";

interface Props {
  child: OrgProps;
  level: number;
  setData: any;
  key: number;
}

export const OrgItem = ({ key, child, level, setData }: Props) => {
  const navigate = useNavigate();
  const [change, setChange] = useState(0);
  const companyData = useSelector((state: any) => state.company);
  return child.open ? (
    <TreeNode
      key={key}
      label={
        child.hasSiblings || child.hasAbove ? (
          <Grid display={"inline-grid"}>
            <TreeNode
              label={
                !child.noMore ? (
                  <Tooltip content='Expandir'>
                    <IconButton
                      color={companyData.primary}
                      variant='ghost'
                      radius='full'
                      style={{ cursor: "pointer", margin: 8 }}
                      onClick={() => {
                        if (child.hasAbove && child.hasSiblings) {
                          child.above[0].open = true;
                          child.above[0].noMoreChildren = true;
                          child.siblings.map((item: any) => (item.open = true));
                          child.siblings.map(
                            (item: any) => (item.noMore = true)
                          );
                          child.siblings.map(
                            (item: any) =>
                              item.hasChildren &&
                              item.children.map(
                                (item2: any) => (item2.noMore = true)
                              )
                          );
                          child.noMore = true;
                          child.above[0].children = [child, ...child.siblings];
                          setData(() => child.above);
                        } else if (child.hasSiblings) {
                          child.siblings.map((item: any) => (item.open = true));
                          child.siblings.map(
                            (item: any) => (item.noMore = true)
                          );
                          child.noMore = true;
                          setData((prev: any) => [child, ...child.siblings]);
                        } else if (child.hasAbove) {
                          child.above[0].open = true;
                          child.noMore = true;
                          setData(() => child.above);
                        }
                      }}>
                      <OpenInFull fontSize='small' />
                    </IconButton>
                  </Tooltip>
                ) : (
                  child.noMore &&
                  (child.userActive || child.super) && (
                    <Tooltip content='Minimizar'>
                      <IconButton
                        color={companyData.primary}
                        variant='ghost'
                        radius='full'
                        style={{ cursor: "pointer", margin: 8 }}
                        onClick={() => {
                          if (child.hasAbove && child.hasSiblings) {
                            child.above[0].open = false;
                            child.siblings.map(
                              (item: any) => (item.open = false)
                            );
                            child.siblings.map(
                              (item: any) => (item.noMore = false)
                            );
                            child.siblings.map(
                              (item: any) =>
                                item.hasChildren &&
                                item.children.map(
                                  (item2: any) => (item2.noMore = false)
                                )
                            );
                            child.above[0].children = [
                              child,
                              ...child.siblings,
                            ];
                            child.noMore = false;
                            setData(() => [child]);
                          } else if (child.hasSiblings) {
                            child.siblings.map(
                              (item: any) => (item.open = false)
                            );
                            child.siblings.map(
                              (item: any) => (item.noMore = false)
                            );
                            child.noMore = false;
                            setData((prev: any) => [child, ...child.siblings]);
                          } else if (child.hasAbove) {
                            child.above[0].open = false;
                            child.noMore = false;
                            setData(() => child.above);
                          }
                        }}>
                        <CloseFullscreen fontSize='small' />
                      </IconButton>
                    </Tooltip>
                  )
                )
              }
              children={
                <CardUser
                  navigate={navigate}
                  change={change}
                  setChange={setChange}
                  company={companyData}
                  user={child}
                />
              }
            />
          </Grid>
        ) : child.open ? (
          <CardUser
            navigate={navigate}
            change={change}
            setChange={setChange}
            company={companyData}
            user={child}
          />
        ) : (
          <Tooltip content='Ver detalhes'>
            <IconButton
              color={companyData.primary}
              variant='ghost'
              radius='full'
              style={{ cursor: "pointer", margin: 8 }}
              onClick={() => {
                setChange(change + 1);
                child.open = true;
              }}>
              <Avatar
                size='2'
                color={companyData.primary}
                src={child?.avatar}
                radius='full'
                fallback={child?.name?.split("")[0] ?? ""}
              />
            </IconButton>
          </Tooltip>
        )
      }
      children={
        child.children &&
        child.children.length > 0 &&
        (!child.noMoreChildren ? (
          <TreeNode
            label={
              <Tooltip content='Expandir'>
                <IconButton
                  color={companyData.primary}
                  variant='ghost'
                  radius='full'
                  style={{ cursor: "pointer", margin: 8 }}
                  onClick={() => {
                    child.children.map((item: any) => (item.open = true));
                    child.children.map((item: any) => (item.noMore = true));
                    child.noMoreChildren = true;
                    setChange((prev) => prev + 1);
                  }}>
                  <OpenInFull fontSize='small' />
                </IconButton>
              </Tooltip>
            }
          />
        ) : (
          child.noMoreChildren && (
            <TreeNode
              label={
                // (level < 1 || child.userActive) &&
                <Tooltip content='Minimizar'>
                  <IconButton
                    color={companyData.primary}
                    variant='ghost'
                    radius='full'
                    style={{ cursor: "pointer", margin: 8 }}
                    onClick={() => {
                      child.children.map((item: any) => (item.open = false));
                      child.children.map((item: any) => (item.noMore = false));
                      child.noMoreChildren = false;
                      setChange((prev) => prev + 1);
                    }}>
                    <CloseFullscreen fontSize='small' />
                  </IconButton>
                </Tooltip>
              }
              children={
                child.children &&
                child.children.length > 0 &&
                child.children.map((childChild: any, index: number) => (
                  <OrgItem
                    key={index}
                    child={childChild}
                    level={level + 1}
                    setData={setData}
                  />
                ))
              }
            />
          )
        ))
      }
    />
  ) : (
    <></>
  );
};
