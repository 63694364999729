/** @format */

import { exportDataToCSV } from "../../../../../helpers/exportDataToCSV";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance } from "../../../../../services/api";

export const handleDownloadButton = async (dispatch: any, formik: any) => {
  dispatch(updateLoading(true));
  const values = formik.values;
  GetInstance(
    {
      job_role_id: values.job_role_id,
      manager_id: values.manager_id,
      department_id: values.department_id,
      status: "active",
    },
    "/users/export_csv"
  )
    .then((response: any) => {
      exportDataToCSV(response.data, "Colaboradores");
      dispatch(updateLoading(false));
    })
    .catch((e: any) => {
      GenericError(e, dispatch);
    });
};
