/** @format */

import {
  Box,
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Link,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";

import {
  AddCircleOutlined,
  ArrowDownward,
  ArrowUpward,
  CheckCircle,
  DragIndicator,
  Edit,
  HighlightOff,
} from "@mui/icons-material";
import { IconButton } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { FormatStatus } from "../../../../helpers/formatStatus";
import { GenericError } from "../../../../helpers/genericError";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance, PostInstante } from "../../../../services/api";

export function Grades() {
  const [items, setItems] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalMode, setModalMode] = useState("");
  const dispatch = useDispatch();

  const [draggedItemIndex, setDraggedItemIndex] = useState<number | null>(null);

  const handleDragStart = (index: number): void => {
    setDraggedItemIndex(index);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
  };

  const handleDrop = (index: number): void => {
    if (draggedItemIndex === null) return;

    const updatedItems = [...items];
    const [removed] = updatedItems.splice(draggedItemIndex, 1);
    updatedItems.splice(index, 0, removed);

    setItems(updatedItems);
    setDraggedItemIndex(null);
  };

  const formik: any = useFormik({
    initialValues: {
      grade: "",
      description: "",
      id: null,
      index: null,
    },
    onSubmit: (values) => {
      if (modalMode === "create") {
        const newGrade = {
          grade: formik.values.grade,
          description: formik.values.description,
          status: "active",
        };

        setItems((prevItems) => [...prevItems, newGrade]);
      } else {
        setItems((prevItems) => {
          const updatedItems = [...prevItems];
          updatedItems[formik.values.index] = {
            id: formik.values.id,
            grade: formik.values.grade,
            description: formik.values.description,
            status: formik.values.status,
          };
          return updatedItems;
        });
      }

      setOpenModal(false);

      formik.setValues({ grade: "", description: "", id: null, index: null });
    },
  });

  const handleClickEdit = (item: any, index: number) => {
    formik.setValues({
      description: item.description,
      grade: item.grade,
      id: item.id,
      status: item.status,
      index,
    });
    setModalMode("edit");
    setOpenModal(true);
  };

  const handleSendData = () => {
    const toSend = items.map((item, index) => ({
      ...item,
      ordinance: index,
    }));

    PostInstante(null, { grades: toSend }, "/grades")
      .then((response: any) => {
        dispatch(updateLoading(false));
        toast.success(response?.data?.message);

        GetInstance(null, "/grades")
          .then((response: any) => {
            setItems(response.data.data);
            dispatch(updateLoading(false));
          })
          .catch((e: any) => {
            if (e.code !== "ERR_CANCELED") {
              dispatch(updateLoading(false));
              toast.error(
                e?.response?.data?.message ??
                  "Erro inesperado, tente novamente."
              );
            }
          });
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
  };

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance(null, "/grades")
      .then((response: any) => {
        if (Array.isArray(response?.data?.data)) setItems(response.data.data);
        dispatch(updateLoading(false));
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickUp = (index: number) => {
    setItems((prevItems) => {
      if (index <= 0) return prevItems;
      const updatedItems = [...prevItems];

      [updatedItems[index - 1], updatedItems[index]] = [
        updatedItems[index],
        updatedItems[index - 1],
      ];
      return updatedItems;
    });
  };

  const handleClickDown = (index: number) => {
    setItems((prevItems) => {
      if (index >= prevItems.length - 1) return prevItems;
      const updatedItems = [...prevItems];

      [updatedItems[index], updatedItems[index + 1]] = [
        updatedItems[index + 1],
        updatedItems[index],
      ];
      return updatedItems;
    });
  };

  return (
    <Box>
      <Dialog
        open={openModal}
        keepMounted
        onClose={() => {
          setOpenModal(false);
          setModalMode("");
          formik.setValues({
            grade: "",
            description: "",
            id: null,
            index: null,
          });
        }}
        aria-describedby='alert-dialog'>
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle color='InfoText'>
            {modalMode === "create"
              ? "Adicione uma nova grade"
              : "Edite a grade"}
          </DialogTitle>
          <DialogContent>
            <Grid mt={1} display='flex' flexDirection='column' gap={2}>
              <TextField
                fullWidth
                id='grade'
                label='Grade'
                variant='outlined'
                value={formik.values.grade}
                onChange={(event: any) => {
                  formik.setFieldValue("grade", event.target.value);
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.grade && Boolean(formik.errors.grade)}
                helperText={formik.touched.grade && formik.errors.grade}
              />
              <TextField
                fullWidth
                id='description'
                label='Nível funcional'
                variant='outlined'
                value={formik.values.description}
                onChange={(event: any) => {
                  formik.setFieldValue("description", event.target.value);
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{ width: "100%", justifyContent: "center", display: "flex" }}>
            <Button
              variant='contained'
              fullWidth
              sx={{ alignSelf: "center" }}
              type='submit'>
              OK
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Grid
        container
        gap={2}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={2}>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link
            underline='hover'
            sx={{ cursor: "pointer" }}
            color='inherit'
            href='/'>
            Inicio
          </Link>
          <Typography>Configurações</Typography>
          <Typography fontWeight={800}>Grades</Typography>
        </Breadcrumbs>
        <Button
          variant={"contained"}
          endIcon={<AddCircleOutlined />}
          onClick={() => {
            setOpenModal(true);
            setModalMode("create");
          }}>
          Nova grade
        </Button>
      </Grid>
      <Paper>
        <Grid container p={2} mt={2}>
          <Grid
            width={"100%"}
            display={"grid"}
            gridTemplateColumns={"1fr 1fr 1fr 1fr"}>
            <Grid item alignItems={"center"}>
              <Typography fontWeight={800}>Ações</Typography>
            </Grid>
            <Grid item>
              <Typography fontWeight={800}>Grade</Typography>
            </Grid>
            <Grid item>
              <Typography fontWeight={800}>Nível funcional</Typography>
            </Grid>
            <Grid item>
              <Typography fontWeight={800}>Status</Typography>
            </Grid>
          </Grid>
          <Grid item width={"100%"}>
            <Divider />
          </Grid>
          {items.map((item, index) => (
            <Grid
              width={"100%"}
              display={"grid"}
              pt={2}
              gridTemplateColumns={"1fr 1fr 1fr 1fr"}
              key={index}
              draggable
              onDragStart={() => handleDragStart(index)}
              onDragOver={handleDragOver}
              onDrop={() => handleDrop(index)}>
              <Grid item gap={2} display={"flex"}>
                <Tooltip title={"Arraste para a posição desejada"}>
                  <IconButton variant='ghost' radius='full'>
                    <DragIndicator />
                  </IconButton>
                </Tooltip>
                <Tooltip title={"Subir"}>
                  <IconButton
                    variant='ghost'
                    radius='full'
                    onClick={() => handleClickUp(index)}>
                    <ArrowUpward />
                  </IconButton>
                </Tooltip>
                <Tooltip title={"Descer"}>
                  <IconButton
                    variant='ghost'
                    radius='full'
                    onClick={() => handleClickDown(index)}>
                    <ArrowDownward />
                  </IconButton>
                </Tooltip>
                <Tooltip title={"Editar"}>
                  <IconButton
                    variant='ghost'
                    radius='full'
                    onClick={() => handleClickEdit(item, index)}>
                    <Edit fontSize='small' />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={item.status === "active" ? "Desativar" : "Ativar"}>
                  <IconButton
                    variant='ghost'
                    radius='full'
                    onClick={() =>
                      setItems(
                        items.map((x, i) =>
                          i === index
                            ? {
                                ...x,
                                status:
                                  x.status === "active" ? "inactive" : "active",
                              }
                            : x
                        )
                      )
                    }>
                    {item.status === "active" ? (
                      <HighlightOff />
                    ) : (
                      <CheckCircle />
                    )}
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>{item.grade}</Grid>
              <Grid item>{item.description}</Grid>
              <Grid item>{FormatStatus(item.status)}</Grid>
            </Grid>
          ))}
        </Grid>
      </Paper>
      <Grid container gap={2} mt={2} justifyContent={"end"}>
        <Button
          variant={"contained"}
          endIcon={<Edit />}
          onClick={handleSendData}>
          Salvar
        </Button>
      </Grid>
    </Box>
  );
}
