/** @format */

import { exportDataToCSV } from "../../../../helpers/exportDataToCSV";
import { GenericError } from "../../../../helpers/genericError";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance } from "../../../../services/api";

export const handleDownloadButton = async (dispatch: any, values: any) => {
  dispatch(updateLoading(true));
  GetInstance(values, "/processes/export_csv")
    .then((response: any) => {
      const data = response.data.data;
      exportDataToCSV(data, "Processos");
      dispatch(updateLoading(false));
    })
    .catch((e: any) => {
      GenericError(e, dispatch);
    });
};
