/** @format */

import * as yup from "yup";
import { validationCPF } from "../../../../../helpers/validationCPF";
export const validationSchema = yup.object({
  username: yup.string().nullable().required("Campo é obrigatório"),
  name: yup.string().nullable().required("Campo é obrigatório"),
  cpf: yup
    .string()
    .nullable()
    .required("Campo é obrigatório")
    .test("invalid-cpf", "CPF inválido!", (cpf) => validationCPF(cpf)),
  birth_date: yup.string().nullable().required("Campo é obrigatório"),
  father_cpf: yup
    .string()
    .nullable()
    .test("invalid-cpf", "CPF inválido!", (cpf) => validationCPF(cpf)),
  mother_cpf: yup
    .string()
    .nullable()
    .test("invalid-cpf", "CPF inválido!", (cpf) => validationCPF(cpf)),
});
