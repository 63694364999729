/** @format */

import { MenuItem, TextField } from "@mui/material";
import { Filter } from "../../../../../components/refactor/filter";

export const Filters = ({ formik, options }: any) => {
  return (
    <Filter
      formik={formik}
      id={"users_leave"}
      keys={{
        job_role_id: { label: "Cargo", options: options?.job_roles ?? [] },
        manager_id: { label: "Gestor", options: options?.managers ?? [] },
        department_id: {
          label: "Departamento",
          options: options?.departments ?? [],
        },
      }}>
      <TextField
        fullWidth
        id='job_role_id'
        name='job_role_id'
        select
        label='Cargo'
        value={formik.values.job_role_id}
        onChange={(event: any) =>
          formik.setFieldValue("job_role_id", event.target.value)
        }
        onBlur={formik.handleBlur}
        error={formik.touched.job_role_id && Boolean(formik.errors.job_role_id)}
        helperText={formik?.errors?.job_role_id}>
        {options?.job_roles?.map((option: any) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        fullWidth
        id='manager_id'
        name='manager_id'
        select
        label='Gestor'
        value={formik.values.manager_id}
        onChange={(event: any) =>
          formik.setFieldValue("manager_id", event.target.value)
        }
        onBlur={formik.handleBlur}
        error={formik.touched.manager_id && Boolean(formik.errors.manager_id)}
        helperText={formik?.errors?.manager_id}>
        {options?.managers?.map((option: any) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        fullWidth
        id='department_id'
        name='department_id'
        select
        label='Departamento'
        value={formik.values.department_id}
        onChange={(event: any) =>
          formik.setFieldValue("department_id", event.target.value)
        }
        onBlur={formik.handleBlur}
        error={
          formik.touched.department_id && Boolean(formik.errors.department_id)
        }
        helperText={formik?.errors?.department_id}>
        {options?.departments?.map((option: any) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Filter>
  );
};
