/** @format */

import { Menu } from "@mui/icons-material";
import { Chip } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { FormatGroupPermission } from "../../helpers/formatGroupPermission";
import { ModeTheme } from "../../helpers/mode";
import { DelegationHeader } from "./delegation";
import NotificationSection from "./notification";
import ProfileSection from "./profile";

const Header = ({ handleLeftDrawerToggle, open }: any) => {
  const theme = useTheme();
  const company = useSelector((state: any) => state.company);
  const user = useSelector((state: any) => state.user.data);
  const delegation = useSelector((state: any) => state.user.delegation_data);

  return (
    <>
      <Box
        sx={{
          width: 228,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
          alignItems: "center",
        }}>
        <Avatar
          variant='rounded'
          sx={{
            transition: "all .2s ease-in-out",
            background: "transparent",
            color: theme.palette.grey.A700,
            "&:hover": {
              background: theme.palette.grey.A700,
              color: theme.palette.common.white,
            },
          }}
          onClick={handleLeftDrawerToggle}
          color='inherit'>
          <Menu />
        </Avatar>
        <Box
          component='span'
          sx={{
            display: { xs: "none", md: "block" },
            textAlign: "left",
            marginLeft: "20px",
            flexGrow: 1,
          }}>
          <img {...company.logoSmall} alt='Logo da empresa' />
        </Box>
        {company.logos &&
          company.logos.map((item: any) => (
            <Box
              component='span'
              sx={{
                display: { xs: "none", md: "block" },
                textAlign: "left",
                marginLeft: "50px",
                flexGrow: 1,
              }}>
              <img {...item} alt='Logo da empresa' />
            </Box>
          ))}
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />
      <DelegationHeader />
      <Chip
        style={{ marginRight: 8 }}
        color={"primary"}
        label={FormatGroupPermission(user.role)}
      />
      <Avatar
        sx={{
          background: "transparent",
          color: theme.palette.grey.A700,
          "&:hover": {
            background: theme.palette.grey.A700,
            color: theme.palette.common.white,
          },
        }}
        variant={"rounded"}>
        <ModeTheme />
      </Avatar>
      <NotificationSection />
      {!delegation?.delegated_session && <ProfileSection />}
    </>
  );
};

export default Header;
