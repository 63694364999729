/** @format */

import { Download } from "@mui/icons-material";
import { Grid, Tooltip, Typography, useTheme } from "@mui/material";

export const DownloadFile = ({ formik }: any) => {
  const theme = useTheme();
  return (
    <>
      {formik.values.files.length > 0 && (
        <Grid container mt={2}>
          <Typography>Arquivos anexados</Typography>
        </Grid>
      )}
      <Grid container mt={2} gap={2}>
        {formik.values.files.map((file: any) => (
          <Tooltip title={"Baixar arquivo"}>
            <a
              title={file.name}
              href={file.url}
              target='_blank'
              style={{
                border: theme.palette.primary.main + " 1px solid",
                color: theme.palette.primary.main,
                padding: 8,
                fontSize: 12,
                borderRadius: 24,
                fontWeight: 800,
                textDecorationLine: "none",
                display: "flex",
                gap: 4,
                alignItems: "center",
              }}
              download={file.name}
              rel='noreferrer'>
              <Download fontSize={"small"} />
              {file.name}
            </a>
          </Tooltip>
        ))}
        {formik.values.files.length === 0 && (
          <Typography>Não existem arquivos anexados</Typography>
        )}
      </Grid>
    </>
  );
};
