/** @format */

import { Grid, MenuItem, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GenericError } from "../../../../helpers/genericError";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance } from "../../../../services/api";
import { UserGetAll } from "../../../../services/protected/hooks/getUser";

interface Props {
  formik: any;
  id?: string;
}
export function Form({ formik, id }: Props) {
  const dispatch = useDispatch();
  const editPage = id !== undefined;
  const { users } = UserGetAll(dispatch);
  const user = useSelector((state: any) => state.user.data);

  useEffect(() => {
    if (user.id !== undefined)
      formik.setFieldValue("delegated_user_id", user.id);
    if (editPage) {
      dispatch(updateLoading(true));
      GetInstance({}, "/delegations/" + id)
        .then(async (response: any) => {
          dispatch(updateLoading(false));
          const initial_date = dayjs(response.data.data.initial_date);
          const final_date = dayjs(response.data.data.final_date);
          formik.setValues({
            initial_date,
            final_date,
            target_user_id: response.data.data.record.target_user_id,
            delegated_user_id: response.data.data.record.delegated_user_id,
          });
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, user]);

  return (
    <>
      <Grid gridTemplateColumns={"1fr 1fr"} display={"grid"} gap={2}>
        <DatePicker
          slotProps={{
            textField: {
              fullWidth: true,
              id: "initial_date",
              onBlur: formik.handleBlur,
              error:
                formik.touched.initial_date &&
                Boolean(formik.errors.initial_date),
              helperText:
                formik.touched.initial_date && formik.errors.initial_date,
            },
          }}
          disabled={editPage && user.id !== formik.values.delegated_user_id}
          label='Data Início'
          value={formik.values.initial_date}
          onChange={(value: any) => {
            formik.setFieldValue("initial_date", value);
          }}
        />
        <DatePicker
          slotProps={{
            textField: {
              fullWidth: true,
              id: "final_date",
              onBlur: formik.handleBlur,
              error:
                formik.touched.final_date && Boolean(formik.errors.final_date),
              helperText: formik.touched.final_date && formik.errors.final_date,
            },
          }}
          disabled={editPage && user.id !== formik.values.delegated_user_id}
          label='Data Fim'
          value={formik.values.final_date}
          onChange={(value: any) => {
            formik.setFieldValue("final_date", value);
          }}
        />
      </Grid>
      <Grid gridTemplateColumns={"1fr 1fr"} mt={2} display={"grid"} gap={2}>
        <TextField
          fullWidth
          id='target_user_id'
          select
          disabled={editPage && user.id !== formik.values.delegated_user_id}
          label='Atribuir a'
          value={formik.values.target_user_id}
          onChange={(event: any) =>
            formik.setFieldValue("target_user_id", event.target.value)
          }
          onBlur={formik.handleBlur}
          error={
            formik.touched.target_user_id &&
            Boolean(formik.errors.target_user_id)
          }
          helperText={
            formik.touched.target_user_id && formik.errors.target_user_id
          }>
          {users.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        {user.role === "rh" ? (
          <TextField
            fullWidth
            id='delegated_user_id'
            select
            disabled={editPage}
            label='Colaborador delegado'
            value={formik.values.delegated_user_id}
            onChange={(event: any) =>
              formik.setFieldValue("delegated_user_id", event.target.value)
            }
            onBlur={formik.handleBlur}
            error={
              formik.touched.delegated_user_id &&
              Boolean(formik.errors.delegated_user_id)
            }
            helperText={
              formik.touched.delegated_user_id &&
              formik.errors.delegated_user_id
            }>
            {users.map((option: any) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        ) : null}
      </Grid>
    </>
  );
}
