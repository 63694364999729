/** @format */

import { Button } from "@mui/material";
import { Dialog, Flex, RadioGroup } from "@radix-ui/themes";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { permissionsMock } from "../../config/permissionsMock";
import { GenericError } from "../../helpers/genericError";
import { getNotificationsUnread } from "../../pages/protected/notifications/helpers/getNotification";
import { updateLoadingSessao } from "../../redux/reducers/common";
import {
  updateUser,
  updateUserDelegation,
  updateUserPermissions,
} from "../../redux/reducers/user";
import { GetInstance, PostInstante } from "../../services/api";

interface Props {
  delegationModal: any;
  setDelegationModal: any;
  delegationUser: any;
  setDelegationUser: any;
}
export const DialogDelegation = ({
  delegationModal,
  delegationUser,
  setDelegationModal,
  setDelegationUser,
}: Props) => {
  const delegation = useSelector((state: any) => state.user.delegation_data);
  const dispatch = useDispatch();
  const router = useNavigate();

  function onDelegate() {
    if (delegationUser !== null) {
      dispatch(updateLoadingSessao(true));
      setDelegationModal(false);
      PostInstante(delegationUser, null, "/delegations/sign_in/")
        .then((response: any) => {
          dispatch(updateUser(response.data.data.user));
          Cookies.set("auth", response.data.data.token);
          GetInstance({}, "users/sessao")
            .then((response: any) => {
              dispatch(
                updateUserPermissions(
                  permissionsMock.filter(
                    (item: any) => item.uuid === response.data.data.record.role
                  )[0].permissions
                )
              );
              dispatch(updateLoadingSessao(false));
              dispatch(updateUser(response.data.data.record));
              dispatch(
                updateUserDelegation(response.data.data.delegation_data)
              );
              getNotificationsUnread(dispatch);
              const interval = setInterval(() => {
                getNotificationsUnread(dispatch);
              }, 30000);
              clearInterval(interval);
              toast.success("Sucesso ao entrar como delegado");
            })
            .catch((e: any) => {
              if (e.code !== "ERR_CANCELED") {
                dispatch(updateLoadingSessao(false));
                router("/login");
              }
            });
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    } else {
      toast.error("Não foi selecionado nenhum usuário para acesso.");
    }
  }

  return (
    <Dialog.Root open={delegationModal}>
      <Dialog.Content maxWidth='450px'>
        <Dialog.Title>Acessar delegação</Dialog.Title>
        <Dialog.Description size='2' mb='4'>
          Acesse um perfil que você possui função de delegado, selecione ele
          abaixo e clique em entrar:
        </Dialog.Description>
        <Flex direction='column' gap='3'>
          <RadioGroup.Root
            defaultValue={delegationUser}
            onClick={(e: any) => setDelegationUser(e.target.value)}>
            {delegation?.delegation_options?.map((item: any) => (
              <RadioGroup.Item value={item.uuid}>
                {item.delegated_user_name}
              </RadioGroup.Item>
            ))}
          </RadioGroup.Root>
        </Flex>
        <Flex gap='3' mt='4' justify='end'>
          <Button onClick={() => setDelegationModal(false)} variant='outlined'>
            Cancelar
          </Button>
          <Button onClick={onDelegate} variant='contained' color={"primary"}>
            Entrar
          </Button>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
};
