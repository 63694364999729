/** @format */

import { Grid, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

export const Form = ({ formik }: any) => {
  return (
    <>
      <Grid
        gridTemplateColumns={"1fr 1fr 1fr 1fr"}
        display={"grid"}
        mt={2}
        gap={2}>
        <DatePicker
          slotProps={{
            textField: {
              fullWidth: true,
              id: `acquisition_period_start`,
              onBlur: formik.handleBlur,
              error: Boolean(formik.errors.acquisition_period_start),
              helperText: formik.errors.acquisition_period_start,
            },
          }}
          label='Data de aquisição inicial'
          value={formik.values.acquisition_period_start}
          onChange={(value: any) => {
            formik.setFieldValue(`acquisition_period_start`, value);
            formik.setFieldValue(
              `concessive_period_start`,
              value.add(1, "year")
            );
          }}
        />
        <DatePicker
          slotProps={{
            textField: {
              fullWidth: true,
              id: `acquisition_period_end`,
              onBlur: formik.handleBlur,
              error: Boolean(formik.errors.acquisition_period_end),
              helperText: formik.errors.acquisition_period_end,
            },
          }}
          label='Data de aquisição final'
          value={formik.values.acquisition_period_end}
          onChange={(value: any) => {
            formik.setFieldValue(`acquisition_period_end`, value);
            formik.setFieldValue(`concessive_period_end`, value.add(1, "year"));
          }}
        />
        <DatePicker
          slotProps={{
            textField: {
              fullWidth: true,
              id: `concessive_period_start`,
              onBlur: formik.handleBlur,
              error: Boolean(formik.errors.concessive_period_start),
              helperText: formik.errors.concessive_period_start,
            },
          }}
          label='Data de inicio da concessão'
          value={formik.values.concessive_period_start}
          onChange={(value: any) => {
            formik.setFieldValue(`concessive_period_start`, value);
          }}
        />
        <DatePicker
          slotProps={{
            textField: {
              fullWidth: true,
              id: `concessive_period_end`,
              onBlur: formik.handleBlur,
              error: Boolean(formik.errors.concessive_period_end),
              helperText: formik.errors.concessive_period_end,
            },
          }}
          label='Data final da concessão'
          value={formik.values.concessive_period_end}
          onChange={(value: any) => {
            formik.setFieldValue(`concessive_period_end`, value);
          }}
        />
        <TextField
          fullWidth
          id={`days_available`}
          type='number'
          label='Quantidade de dias disponiveis'
          variant='outlined'
          value={formik.values.days_available}
          onChange={(event: any) => {
            formik.setFieldValue(`days_available`, event.target.value);
          }}
          error={Boolean(formik.errors.days_available)}
          helperText={formik.errors.days_available}
        />
        <TextField
          fullWidth
          id={`days_used`}
          type='number'
          label='Quantidade de dias usados'
          variant='outlined'
          value={formik.values.days_used}
          onChange={(event: any) => {
            formik.setFieldValue(`days_used`, event.target.value);
          }}
          error={Boolean(formik.errors.days_used)}
          helperText={formik.errors.days_used}
        />
      </Grid>
    </>
  );
};
