/** @format */

import { Info } from "@mui/icons-material";
import { Grid, IconButton, Tooltip } from "@mui/material";
import { useFormik } from "formik";
import MUIDataTable from "mui-datatables";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BreadcrumbsComponent } from "../../../../components/refactor/breadcrumbsComponent";
import { optionsTable } from "../../../../config/optionsTable";
import useUsers from "../../../../services/protected/hooks/useUsers";
import { columns } from "./helpers/constants";
import { Filters } from "./helpers/filters";
import { handleDownloadButton } from "./helpers/handleDownloadButton";

export function LeaveEmployee() {
  const dispatch = useDispatch();
  const formik: any = useFormik({
    initialValues: {
      job_role_id: "all",
      manager_id: "all",
      department_id: "all",
    },
    onSubmit: () => {
      setReset(true);
    },
  });
  const {
    data,
    total,
    setOrder,
    setPage,
    setPerPage,
    searchCall,
    page,
    perPage,
    setReset,
    options,
  } = useUsers({ ...formik.values, status: "on_leave" });
  const router = useNavigate();

  return (
    <Grid container gap={2}>
      <BreadcrumbsComponent
        links={[
          { label: "Inicio" },
          { label: "Colaboradores", value: "/employee/active" },
          { label: "Afastados", value: "/employee/leave" },
        ]}
      />
      <Filters formik={formik} options={options} />

      <MUIDataTable
        title={""}
        data={data}
        columns={[
          {
            name: "action",
            label: "Ações",
            options: {
              sort: false,
              customBodyRenderLite: (dataIndex: number) => {
                return (
                  <Tooltip title={"Ver informações"}>
                    <IconButton
                      onClick={() =>
                        router(
                          `/request/updateInformation/request/${data[dataIndex].uuid}`
                        )
                      }>
                      <Info color='primary' />
                    </IconButton>
                  </Tooltip>
                );
              },
            },
          },
          ...columns,
        ]}
        options={{
          ...optionsTable,
          searchPlaceholder: "Pesquise por nome ou CPF",
          onSearchChange: (value: string) => {
            searchCall(value);
          },
          onColumnSortChange: (value: string, direction: string) => {
            setOrder(`${value} ${direction}`);
          },
          count: total,
          page,
          onChangePage: (page: number) => {
            setPage(page);
          },
          onDownload: () => {
            handleDownloadButton(dispatch, formik);
            return false;
          },
          rowsPerPage: perPage,
          onChangeRowsPerPage: (perPage: number) => {
            setPage(0);
            setPerPage(perPage);
          },
        }}
      />
    </Grid>
  );
}
