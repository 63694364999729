/** @format */

import { Edit, Undo } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ActionAccountingDisapproved } from "../../../../../components/refactor/actionAccountingDisapproved";
import { ActionAproved } from "../../../../../components/refactor/actionApproved";
import { ActionCompleted } from "../../../../../components/refactor/actionCompleted";
import { ActionDisapproved } from "../../../../../components/refactor/actionDisapproved";
import { ActionResend } from "../../../../../components/refactor/actionResend";
import { ActionReturned } from "../../../../../components/refactor/actionReturned";
import { BreadcrumbsComponent } from "../../../../../components/refactor/breadcrumbsComponent";
import { ButtonComponent } from "../../../../../components/refactor/buttonComponent";
import { DownloadFile } from "../../../../../components/refactor/donwloadFile";
import { InfoComponent } from "../../../../../components/refactor/infoComponent";
import { InputFile } from "../../../../../components/refactor/inputFile";
import { StatusPermittedByRoleContainer } from "../../../../../components/statusPermittedByRoleContainer";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import {
  GetInstance,
  PutInstante,
  PutInstanteFormData,
} from "../../../../../services/api";
import { validationSchema } from "../helpers/constants";
import { DialogPosition } from "../helpers/dialogPosition";
import { Form } from "../helpers/form";
import { HistoryComponent } from "../helpers/historyComponent";

export function DismissalsDetails() {
  const [options, setOptions] = useState({
    dismissal_reasons: [],
    dismissal_types: [],
  });
  const router = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [data, setData]: any = useState({
    collaborator_data: {},
    solicitation_data: {},
  });
  const [openModalPosition, setOpenModalPosition] = useState({
    open: false,
    action: null,
  });
  const [valide, setValide] = useState(false);
  const permissions = useSelector((state: any) => state.user.permissions);
  const user = useSelector((state: any) => state.user.data);

  const isResendable =
    data?.solicitation_data.status === "returned" &&
    user.id === data.record.created_by_id;
  const edit =
    (permissions !== null &&
      permissions.indexOf("requestDismissalsDetailsEdit") !== -1 &&
      (data?.solicitation_data.status === "waiting_rh_user" ||
        data?.solicitation_data.status === "accounting_return") &&
      user.role === "rh") ||
    isResendable;

  const formik: any = useFormik({
    initialValues: {
      dismissal_date: null,
      early_warning: "",
      files: [],
      dismissal_type_id: "",
      dismissal_reason_id: "",
      comment: "",
      position_delete: false,
    },
    validateOnBlur: valide,
    validateOnChange: valide,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setValide(true);
      dispatch(updateLoading(true));
      PutInstante(null, { ...values }, `/dismissals/${id}`)
        .then((response: any) => {
          const formData = new FormData();
          // eslint-disable-next-line array-callback-return
          formik.values.files.map((file: any) => {
            formData.append("file[]", file);
          });
          PutInstanteFormData(
            null,
            formData,
            "/solicitations/" +
              response.data.data.solicitation_uuid +
              "/upload_files"
          )
            .then(() => {
              dispatch(updateLoading(false));
              router("/request/dismissals");
              toast.success(response?.data?.message);
            })
            .catch((e: any) => {
              if (e.code !== "ERR_CANCELED") {
                dispatch(updateLoading(false));
                toast.error(
                  e?.response?.data?.message ??
                    "Erro inesperado, tente novamente."
                );
              }
            });
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    GetInstance({}, "/dismissals/" + id)
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        const formData = response.data.data;
        setData(formData);
        formik.setValues({
          dismissal_date: dayjs(formData.record.dismissal_date),
          early_warning: formData.record.early_warning,
          files: formData.solicitation_files ?? [],
          dismissal_reason_id: formData.record.dismissal_reason_id,
          dismissal_type_id: formData.record.dismissal_type_id,
          comment: formData.record.comment,
          position_delete: false,
        });
        if (user?.user_role === "rh" || user?.user_role === "manager") {
          setOptions({
            dismissal_reasons:
              response.data.data.dismissal_types.group_0.filter(
                (d: any) => d.associated
              ),
            dismissal_types:
              response.data.data.dismissal_reasons.group_0.filter(
                (d: any) => d.associated
              ),
          });
        } else {
          setOptions({
            dismissal_reasons:
              response.data.data.dismissal_types.group_1.filter(
                (d: any) => d.associated
              ),
            dismissal_types:
              response.data.data.dismissal_reasons.group_1.filter(
                (d: any) => d.associated
              ),
          });
        }
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          toast.error(
            (e?.response?.data?.message?.error || e?.response?.data?.message) ??
              "Erro inesperado, tente novamente."
          );
          router("/request/dismissals");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <form onSubmit={formik.handleSubmit}>
      {openModalPosition.open && (
        <DialogPosition
          formik={formik}
          setOpenModalPosition={setOpenModalPosition}
          onSubmit={formik.handleSubmit}
        />
      )}
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Início" },
            { label: "Solicitações", value: "/request/dismissals" },
            {
              label: "Desligamentos",
              value: "/request/dismissals",
            },
            { label: "Detalhes da solicitação" },
          ]}
        />
        <InfoComponent
          user={data.collaborator_data}
          solicitation={data.solicitation_data}
        />
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Detalhes do desligamento
              </Typography>
            </Grid>
            <Form formik={formik} options={options} edit={!edit} />
            {edit ? (
              <InputFile formik={formik} />
            ) : (
              <DownloadFile formik={formik} />
            )}
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <ButtonComponent
                variant={"outlined"}
                onClick={() => {
                  router("/request/dismissals");
                }}
                endIcon={<Undo />}>
                Voltar
              </ButtonComponent>
              {isResendable && (
                <ActionResend
                  id={id}
                  url={"dismissals"}
                  disabled={!formik.isValid}
                  onSubmit={(resendActionCallback: any) => {
                    dispatch(updateLoading(true));
                    PutInstante(null, { ...formik.values }, `/dismissals/${id}`)
                      .then((response: any) => {
                        resendActionCallback();
                        dispatch(updateLoading(false));
                        router("/request/dismissals");
                        toast.success(response?.data?.message);
                      })
                      .catch((e: any) => {
                        GenericError(e, dispatch);
                      });
                  }}
                />
              )}
              <StatusPermittedByRoleContainer
                rhAllowedStatuses={[
                  "pending",
                  "waiting_manager_user",
                  "waiting_rh_user",
                  "accounting_return",
                ]}
                managerAllowedStatuses={["pending", "waiting_manager_user"]}
                status={data?.solicitation_data.status}>
                {edit && (
                  <ButtonComponent
                    variant={"contained"}
                    type={"submit"}
                    endIcon={<Edit />}>
                    Editar
                  </ButtonComponent>
                )}
                <ActionReturned
                  id={id}
                  url={"dismissals"}
                />
                <ActionDisapproved
                  id={id}
                  url={"dismissals"}
                />
              </StatusPermittedByRoleContainer>
              <StatusPermittedByRoleContainer
                rhAllowedStatuses={[
                  "pending",
                  "waiting_manager_user",
                  "waiting_rh_user",
                ]}
                managerAllowedStatuses={["pending", "waiting_manager_user"]}
                status={data?.solicitation_data.status}>
                <ActionAproved
                  keyPrivate={"requestDismissalsDetails"}
                  id={id}
                  close={() =>
                    setOpenModalPosition({ open: false, action: null })
                  }
                  values={formik.values}
                  url={"dismissals"}
                  beforeAction={(onSubmit) =>
                    setOpenModalPosition({ open: true, action: onSubmit })
                  }
                />
              </StatusPermittedByRoleContainer>
              <StatusPermittedByRoleContainer
                accountantAllowedStatuses={["approved"]}
                status={data?.solicitation_data.status}>
                <ActionCompleted
                  keyPrivate={"requestDetailsComplete"}
                  id={id}
                  url={"dismissals"}
                />
                <ActionAccountingDisapproved
                  keyPrivate={"requestDetailsComplete"}
                  id={id}
                  url={"processes"}
                />
              </StatusPermittedByRoleContainer>
            </Grid>
            {data?.solicitation_data?.solicitation_histories?.length > 0 && (
              <HistoryComponent
                data={data?.solicitation_data?.solicitation_histories}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
