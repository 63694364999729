/** @format */
import * as yup from "yup";
export const columns = [
  { name: "solicitation_code", label: "Código" },
  { name: "collaborator_name", label: "Nome" },
  { name: "solicitation_date", label: "Data da solicitação" },
  { name: "absence_date", label: "Data de afastamento" },
  { name: "return_date", label: "Data do retorno previsto" },
  { name: "solicitation_status", label: "Status" },
];

export const validationSchema = yup.object({
  absence_date: yup.date().required("Data de saída é obrigatório"),
  return_date: yup
    .date()
    .min(new Date(), "Data é igual ou anterior a data de hoje")
    .test({
      exclusive: false,
      name: "return_date",
      message: "Data de retorno não pode ser anterior a data de saída",
      test: function (value: any) {
        const { absence_date } = this.parent;
        if (value && new Date(value) <= new Date(absence_date)) return false;
        return true;
      },
    })
    .nullable(),
  e_social_motive: yup.string().nullable().required("Motivo é obrigatório"),
  observations: yup.string().nullable().required("Informação é obrigatório"),
  files: yup
    .array()
    .min(1, "Precisa ter ao menos 1 anexo")
    .nullable()
    .required("Arquivo é obrigatório"),
});
