/** @format */

import { Edit, InfoOutlined, Undo } from "@mui/icons-material";
import { Breadcrumbs, Button, Grid, Link, Typography } from "@mui/material";
import { Callout } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { ConfigSelection } from "../../../../components/configSelection";
import { GenericError } from "../../../../helpers/genericError";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../services/api";

export function PermissionsDismissals() {
  const [leftApprovers, setLeftApprovers] = useState<any>([]);
  const [rightApprovers, setRightApprovers] = useState<any>([]);
  const [options, setOptions] = useState<any>([]);

  const [reasons, setReasons] = useState<any>([]);
  const [leftReasons, setLeftReasons] = useState<any>([]);
  const [rightReasons, setRightReasons] = useState<any>([]);

  const [companies, setCompanies] = useState<any>([]);
  const [leftCompanies, setLeftCompanies] = useState<any>([]);
  const [rightCompanies, setRightCompanies] = useState<any>([]);

  const [reasonsTypes, setReasonsTypes] = useState<any>([]);
  const [leftReasonsTypes, setLeftReasonsTypes] = useState<any>([]);
  const [rightReasonsTypes, setRightReasonsTypes] = useState<any>([]);

  const [reasonsCollaborators, setReasonsCollaborator] = useState<any>([]);
  const [leftReasonsCollaborators, setLeftReasonsCollaborator] = useState<any>(
    []
  );
  const [rightReasonsCollaborators, setRightReasonsCollaborators] =
    useState<any>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    GetInstance({}, "/company_settings/")
      .then((response: any) => {
        GetInstance({}, "/users/options?settings=true")
          .then((response2: any) => {
            dispatch(updateLoading(false));
            setOptions(response2.data.data);
            const arrayIds = response2.data.data.map((opt: any) => opt.value);
            if (
              Object.keys(
                response.data.data.record.personnel_requisitions_rules
              ).length > 0
            ) {
              if (
                response.data.data.record.personnel_requisitions_rules.approvers
              ) {
                const resultOpt = arrayIds?.filter(
                  (item: any) =>
                    !response.data.data.record.personnel_requisitions_rules.approvers.includes(
                      item
                    )
                );
                setRightApprovers(
                  response.data.data.record.personnel_requisitions_rules
                    .approvers
                );
                setLeftApprovers(resultOpt);
              }
            } else {
              setLeftApprovers(arrayIds);
            }
          })
          .catch((e: any) => {
            if (e.code !== "ERR_CANCELED") {
              dispatch(updateLoading(false));
              toast.error(
                e?.response?.data?.message ??
                  "Erro inesperado, tente novamente."
              );
            }
          });
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    GetInstance({}, "/dismissal_types")
      .then((response) => {
        dispatch(updateLoading(false));
        const reasonsLabel = response.data.group_0.map((r: any) => ({
          label: r.name,
          value: r.id,
          ...r,
        }));
        const colaboratorsLabel = response.data.group_1.map((r: any) => ({
          label: r.name,
          value: r.id,
          ...r,
        }));

        const reasonsLeft = response.data.group_0
          .filter((r: any) => !r.associated)
          .map((r: any) => r.id);
        const reasonsRight = response.data.group_0
          .filter((r: any) => r.associated)
          .map((r: any) => r.id);

        const typesCollaboratorLeft = response.data.group_1
          .filter((r: any) => !r.associated)
          .map((r: any) => r.id);
        const typesCollaboratorRight = response.data.group_1
          .filter((r: any) => r.associated)
          .map((r: any) => r.id);

        setCompanies(colaboratorsLabel);
        setLeftCompanies(typesCollaboratorLeft);
        setRightCompanies(typesCollaboratorRight);

        setRightReasons(reasonsRight);
        setLeftReasons(reasonsLeft);
        setReasons(reasonsLabel);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    GetInstance({}, "/dismissal_reasons")
      .then((response) => {
        dispatch(updateLoading(false));

        const reasonsLabel = response.data.group_0.map((r: any) => ({
          label: r.name,
          value: r.id,
          ...r,
        }));

        const reasonsLeft = response.data.group_0
          .filter((r: any) => !r.associated)
          .map((r: any) => r.id);
        const reasonsRight = response.data.group_0
          .filter((r: any) => r.associated)
          .map((r: any) => r.id);

        const typesCollaboratorLeft = response.data.group_0
          .filter((r: any) => !r.associated)
          .map((r: any) => r.id);
        const typesCollaboratorRight = response.data.group_0
          .filter((r: any) => r.associated)
          .map((r: any) => r.id);

        setLeftCompanies(typesCollaboratorLeft);
        setRightCompanies(typesCollaboratorRight);

        setRightReasonsTypes(reasonsRight);
        setLeftReasonsTypes(reasonsLeft);
        setReasonsTypes(reasonsLabel);

        const reasonsLabelCollaborator = response.data.group_1.map(
          (r: any) => ({
            label: r.name,
            value: r.id,
            ...r,
          })
        );

        const reasonsLeftCollaborator = response.data.group_1
          .filter((r: any) => !r.associated)
          .map((r: any) => r.id);
        const reasonsRightCollaborator = response.data.group_1
          .filter((r: any) => r.associated)
          .map((r: any) => r.id);

        setRightReasonsCollaborators(reasonsRightCollaborator);
        setLeftReasonsCollaborator(reasonsLeftCollaborator);
        setReasonsCollaborator(reasonsLabelCollaborator);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onSubmit() {
    dispatch(updateLoading(true));

    PutInstante(
      null,
      {
        personnel_requisitions_rules: {
          approvers: rightApprovers,
          dismissal_reason_ids: rightReasons,
        },
        dismissal_types_data: [
          { group: 0, types: rightReasons },
          { group: 1, types: rightCompanies },
        ],
        dismissal_reasons_data: [
          { group: 0, types: rightReasonsTypes },
          { group: 1, types: rightReasonsCollaborators },
        ],
      },
      "/company_settings"
    )
      .then((response: any) => {
        dispatch(updateLoading(false));
        toast.success(response?.data?.message);
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
  }

  return (
    <form>
      <Grid container gap={2}>
        <Grid container justifyContent={"space-between"}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/'>
              Inicio
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/settings/updateInformation'>
              Configurações
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/settings/updateInformation'>
              Requisição de pessoal
            </Link>
            <Typography fontWeight={800}>Ver detalhes</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid container>
          <Callout.Root size='2' style={{ alignItems: "center" }}>
            <Callout.Icon>
              <InfoOutlined />
            </Callout.Icon>
            <Grid container flexDirection={"column"}>
              <Typography fontSize={16} fontWeight={400}>
                Nesta tela é permitido a configuração de usuários com permissão
                aos fluxos de requisição de pessoal.
              </Typography>
              <Typography fontSize={16} fontWeight={400} mt={1}>
                Para realizar esse procedimento, é só selecionar os itens que
                deseja incluir no lado esquerdo, enviar para o lado direito. e
                para remover é só realizar o processo contrário, após a
                conclusão da personalização, clique em 'SALVAR MODIFICAÇÕES'
                para concluir.
              </Typography>
              <Typography fontSize={16} fontWeight={800} mt={1}>
                Esse procedimento afeta as ações: VER DETALHES, APROVAR E
                REPROVAR.
              </Typography>
            </Grid>
          </Callout.Root>
        </Grid>

        <ConfigSelection
          leftLabel='Usuários sem permissão:'
          rightLabel='Usuários com permissão:'
          left={leftApprovers}
          right={rightApprovers}
          options={options}
          setLeft={setLeftApprovers}
          setRight={setRightApprovers}
        />

        <Grid container>
          <Callout.Root size='2' style={{ alignItems: "center" }}>
            <Callout.Icon>
              <InfoOutlined />
            </Callout.Icon>
            <Grid container flexDirection={"column"}>
              <Typography fontSize={16} fontWeight={400}>
                Nesta tela é permitido a configuração dos tipos de desligamento.
              </Typography>
              <Typography fontSize={16} fontWeight={400} mt={1}>
                Para realizar esse procedimento, é só selecionar os itens que
                deseja incluir no lado esquerdo, enviar para o lado direito. e
                para remover é só realizar o processo contrário, após a
                conclusão da personalização, clique em 'SALVAR MODIFICAÇÕES'
                para concluir.
              </Typography>
            </Grid>
          </Callout.Root>
        </Grid>

        <ConfigSelection
          leftLabel='Perfil RH e Gestor - Tipos sem permissão:'
          rightLabel='Perfil RH e Gestor -  Tipos com permissão:'
          left={leftReasons}
          right={rightReasons}
          options={reasons}
          setLeft={setLeftReasons}
          setRight={setRightReasons}
        />

        <ConfigSelection
          leftLabel='Perfil Colaborador - Tipos sem permissão:'
          rightLabel='Perfil Colaborador - Tipos com permissão:'
          left={leftCompanies}
          right={rightCompanies}
          options={companies}
          setLeft={setLeftCompanies}
          setRight={setRightCompanies}
        />

        <ConfigSelection
          leftLabel='Perfil RH e Gestor - Motivos sem permissão:'
          rightLabel='Perfil RH e Gestor - Motivos com permissão:'
          left={leftReasonsTypes}
          right={rightReasonsTypes}
          options={reasonsTypes}
          setLeft={setLeftReasonsTypes}
          setRight={setRightReasonsTypes}
        />

        <ConfigSelection
          leftLabel='Perfil Colaborador - Motivos sem permissão:'
          rightLabel='Perfil Colaborador - Motivos com permissão:'
          left={leftReasonsCollaborators}
          right={rightReasonsCollaborators}
          options={reasonsCollaborators}
          setLeft={setLeftReasonsCollaborator}
          setRight={setRightReasonsCollaborators}
        />

        <Grid container gap={2} justifyContent={"end"} mt={2}>
          <Button
            variant={"outlined"}
            onClick={() => {
              window.history.back();
            }}
            endIcon={<Undo />}>
            Voltar
          </Button>
          <Button variant={"contained"} onClick={onSubmit} endIcon={<Edit />}>
            Salvar modificações
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
