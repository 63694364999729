/** @format */

import { AddCircle, Clear, InfoOutlined, Undo } from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { Callout } from "@radix-ui/themes";
import { useFormik } from "formik";
import JoditEditor from "jodit-react";
import { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { typeLetter } from "../../../../../config/constants";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance, PostInstante } from "../../../../../services/api";

const validationSchema = yup.object({
  solicitation_type: yup.string().nullable().required("Campo é obrigatório"),
  name: yup.string().nullable().required("Campo é obrigatório"),
  content: yup
    .string()
    .nullable()
    .min(100, "Minimo de 100 caracteres")
    .required("Documento é obrigatório"),
});

export const variables = [
  { label: "Nome do colaborador", value: "collaborator_name" },
  { label: "Cargo do colaborador", value: "collaborator_job" },
  { label: "Novo cargo do colaborador", value: "collaborator_job_new" },
  { label: "Salário do colaborador", value: "collaborator_salary" },
  { label: "Novo salário do colaborador", value: "collaborator_salary_new" },
  { label: "Data da solicitação", value: "solicitation_date" },
  { label: "Data de vigência da solicitação", value: "solicitation_date_init" },
  { label: "Porcentagem da solicitação", value: "solicitation_percent" },
];

export function LetterNew() {
  const router = useNavigate();
  const dispatch = useDispatch();
  const editor: any = useRef(null);
  const [valide, setValide] = useState(false);
  const [options, setOptions] = useState({
    dismissal_types: [],
    dismissal_reasons: [],
  });

  const config = {
    readonly: false,
    placeholder: "EX: Nome do colaborador: {{collaborator_name}}",
    language: "pt_br",
    minHeight: 500,
    uploader: {
      insertImageAsBase64URI: true,
    },
    events: {
      paste: (e: any) => {
        const newContent = e.target.innerHTML.replace(
          /(^|;)\s*font-[^;]+/g,
          ""
        );
        editor.current.value = newContent;
        return e;
      },
    },
  };

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance(null, "/letters_form")
      .then((response: any) => {
        setOptions(response.data.data.options);
        dispatch(updateLoading(false));
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues: {
      solicitation_type: "",
      dismissal_type: "",
      dismissal_reason: "",
      name: "",
      content: "",
      active: false,
    },
    validationSchema: validationSchema,
    validateOnBlur: valide,
    validateOnChange: valide,
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      PostInstante(
        null,
        {
          ...values,
        },
        "/letters"
      )
        .then((response: any) => {
          dispatch(updateLoading(false));
          router("/settings/letter");
          toast.success(response?.data?.message);
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <Grid container justifyContent={"space-between"}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/'>
              Inicio
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/settings/letter'>
              Configurações
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/settings/letter'>
              Cartas
            </Link>
            <Typography fontWeight={800}>Novo modelo</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid container>
          <Callout.Root size='2' style={{ alignItems: "center" }}>
            <Callout.Icon>
              <InfoOutlined />
            </Callout.Icon>
            <Grid container flexDirection={"column"}>
              <Grid container flexDirection={"column"}>
                <Typography fontSize={16} fontWeight={800}>
                  Instruções
                </Typography>
                <Typography fontSize={12} fontWeight={400} mb={1}>
                  Selecione o tipo de carta, adicione um nome e os outros dados
                  necessário, Selecione 'Habilitar' caso deseje ativar e crie o
                  documento personalizado que deseja acrescentar no sistema.
                  Depois é só clicar em cadastrar.
                </Typography>
                <Typography fontSize={12} fontWeight={800}>
                  Para adicionar uma variavel de ambiente, por exemplo que seja
                  colocado o nome do colaborador personalizado, adicione o texto
                  como no exemplo do arquivo.
                </Typography>
                <Typography fontSize={16} fontWeight={800} mt={1}>
                  Variáveis disponíveis:
                </Typography>
                {variables.map((item: any) => (
                  <Typography fontSize={12} fontWeight={400}>
                    {item.label} <strong>{item.value}</strong>
                  </Typography>
                ))}
              </Grid>
            </Grid>
          </Callout.Root>
        </Grid>
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Criar novo modelo de carta
              </Typography>
            </Grid>
            <Grid gridTemplateColumns={"1fr 1fr"} display={"grid"} gap={2}>
              <TextField
                fullWidth
                id='solicitation_type'
                select
                label='Tipo de carta'
                value={formik.values?.solicitation_type}
                onChange={(event: any) =>
                  formik.setFieldValue("solicitation_type", event.target.value)
                }
                onBlur={formik.handleBlur}
                error={Boolean(formik.errors.solicitation_type)}
                helperText={formik.errors.solicitation_type}>
                {typeLetter.map((option: any) => (
                  <MenuItem value={option.value}>{option?.label}</MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                id='name'
                label='Nome'
                variant='outlined'
                value={formik.values.name}
                onChange={(event: any) =>
                  formik.setFieldValue("name", event.target.value)
                }
                onBlur={formik.handleBlur}
                error={Boolean(formik.errors.name)}
                helperText={formik.errors.name}
              />
            </Grid>
            {formik.values.solicitation_type === "dismissal" && (
              <Grid
                gridTemplateColumns={"1fr 1fr"}
                mt={2}
                display={"grid"}
                gap={2}>
                <TextField
                  fullWidth
                  id='dismissal_type'
                  select
                  label='Tipo de desligamento'
                  value={formik.values.dismissal_type}
                  onChange={(event: any) =>
                    formik.setFieldValue("dismissal_type", event.target.value)
                  }
                  onBlur={formik.handleBlur}
                  error={Boolean(formik.errors.dismissal_type)}
                  helperText={formik.errors.dismissal_type}>
                  {options.dismissal_types?.map((option: any) => (
                    <MenuItem key={option} value={option.value}>
                      {option.value} - {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  fullWidth
                  id='dismissal_reason'
                  select
                  label='Motivo do desligamento'
                  value={formik.values.dismissal_reason}
                  onChange={(event: any) =>
                    formik.setFieldValue("dismissal_reason", event.target.value)
                  }
                  onBlur={formik.handleBlur}
                  error={Boolean(formik.errors.dismissal_reason)}
                  helperText={formik.errors.dismissal_reason}>
                  {options.dismissal_reasons.map((option: any) => (
                    <MenuItem key={option} value={option.value}>
                      {option.value} - {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
            <Grid
              gridTemplateColumns={"1fr 1fr"}
              mt={2}
              ml={2}
              display={"grid"}
              gap={2}>
              <FormControlLabel
                control={
                  <Tooltip
                    title={
                      "Se marcado, o sistema compreende que está ativo e será enviado esse documento sempre que necessário"
                    }>
                    <Checkbox
                      checked={formik.values.active}
                      onChange={() => {
                        formik.setFieldValue("active", !formik.values.active);
                      }}
                    />
                  </Tooltip>
                }
                label='Habilitar'
              />
            </Grid>
            <Grid pt={2}>
              <JoditEditor
                ref={editor}
                value={formik.values.content}
                config={config}
                onBlur={(newContent) =>
                  formik.setFieldValue("content", newContent)
                }
                onChange={(newContent) => {
                  formik.setFieldValue("content", newContent);
                }}
              />
              {formik.errors?.content && (
                <Typography
                  fontSize={"0.75rem"}
                  style={{ marginTop: 4, marginLeft: 14 }}
                  color={"error"}>
                  {formik.errors?.content}
                </Typography>
              )}
            </Grid>
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <Button
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                fullWidth={isMobile}
                endIcon={<Undo />}>
                Voltar
              </Button>
              <Button
                variant={"outlined"}
                onClick={() => {
                  formik.setFieldValue("content", "");
                }}
                fullWidth={isMobile}
                endIcon={<Clear />}>
                Limpar tudo
              </Button>
              <Button
                fullWidth={isMobile}
                variant={"contained"}
                onClick={() => {
                  setValide(true);
                  formik.handleSubmit();
                }}
                endIcon={<AddCircle />}>
                Cadastrar
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
