/** @format */

import { Button } from "@mui/material";
import { isMobile } from "react-device-detect";

export const ButtonComponent = (props: any) => {
  return (
    <Button
      variant={props.variant ?? "outlined"}
      fullWidth={isMobile}
      {...props}>
      {props.children}
    </Button>
  );
};
