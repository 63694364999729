/** @format */

import { Edit, InfoOutlined, Undo } from "@mui/icons-material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { DatePicker } from "@mui/x-date-pickers";
import { Callout, Tooltip } from "@radix-ui/themes";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { ActionAprovedRule } from "../../../../../components/actionApprovedRule";
import { ActionDisapprovedRule } from "../../../../../components/actionDisapprovedRule";
import { ActionReturnedRule } from "../../../../../components/actionReturnedRule";
import { ActionAccountingDisapproved } from "../../../../../components/refactor/actionAccountingDisapproved";
import { ActionCompleted } from "../../../../../components/refactor/actionCompleted";
import { ActionResend } from "../../../../../components/refactor/actionResend";
import { StatusPermittedByRoleContainer } from "../../../../../components/statusPermittedByRoleContainer";
import { aviso_e_social } from "../../../../../config/constants";
import { EmptyData } from "../../../../../helpers/emptyData";
import { FormatDate } from "../../../../../helpers/formatDate";
import { FormatStatus } from "../../../../../helpers/formatStatus";
import { FormatStatusToHistoric } from "../../../../../helpers/formatStatusToHistoric";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import {
  GetInstance,
  PostInstante,
  PutInstante,
} from "../../../../../services/api";

const validationSchema = yup.object().shape({
  vacation_items: yup.array().of(
    yup.object().shape({
      days_quantity: yup
        .number()
        .min(5, "Mínimo de 5 dias")
        .required("Campo obrigatório"),
      start_date: yup
        .date()
        .typeError("Data inválida")
        .nullable("Data inválida")
        .required("Campo obrigatório")
        .test(
          "min",
          "Data de inicio não pode ser anterior ao período concessivo",
          function (item: any, all: any) {
            return (
              all.options.context.concessive_period_start.getTime() <
              new Date(item).getTime()
            );
          }
        )
        .test(
          "min_policy",
          "Data não respeita as regras da empresa",
          function (item: any, all: any) {
            const policyDay = new Date();
            policyDay.setDate(
              policyDay.getUTCDate() +
                all.options.context.vacations_request_minimum_days
            );
            return new Date(item).getTime() > policyDay.getTime();
          }
        ),
      end_date: yup
        .date()
        .test(
          "min",
          "Data de término não pode ser posterior ao período concessivo",
          function (item: any, all: any) {
            return (
              all.options.context.concessive_period_end.getTime() >
              new Date(item).getTime()
            );
          }
        )
        .typeError("Data de início ou quantidade inválido."),
      thirteen_anticipation: yup
        .string()
        .nullable()
        .required("Campo obrigatório"),
      abono_pecuniario: yup.string().nullable().required("Campo obrigatório"),
    })
  ),
});

export function VacationDetails() {
  const router = useNavigate();
  const { id } = useParams();
  const [data, setData]: any = useState({ vacations: [{}] });
  const userData = useSelector((state: any) => state.user);
  const [expandedHistoricId, setExpandedHistoricId] = useState<any>(null);
  const theme = useTheme();

  const isResendable =
    data?.status === "returned" && userData.data.id === data.created_by_id;

  const editManager =
    userData.data.name === data.manager_name &&
    data?.status === "waiting_manager_user";
  const statusAction =
    data?.status === "pending" ||
    data?.status === "returned" ||
    data?.status === "waiting_manager_user" ||
    data?.status === "waiting_rh_user" ||
    editManager;
  const userAction = userData.data.id === data?.target_user_id;
  const edit =
    (statusAction && userAction) || userData.data.role === "rh" || isResendable;
  const dispatch = useDispatch();
  const formik: any = useFormik({
    initialValues: {
      vacation_items: [
        {
          start_date: null,
          end_date: null,
          days_quantity: "",
          thirteen_anticipation: "",
          abono_pecuniario: "",
          payment_date: null,
          description: "",
        },
      ],
    },
    validationSchema:
      userData?.data?.role === "rh" &&
      userData?.data?.id !== data?.target_user_id
        ? null
        : edit && validationSchema,
    validateOnBlur: edit,
    validateOnChange: edit,
    onSubmit: async (values: any) => {
      const sendValues = values;
      // eslint-disable-next-line array-callback-return
      await sendValues.vacation_items.map((item: any) => {
        item.days_quantity = Number(sendValues.days_quantity);
        item.abono_quantity =
          Number(sendValues.days_used) - Number(sendValues.days_quantity);
      });
      delete sendValues?.status;
      delete sendValues.vacation_items[0].vacation;
      dispatch(updateLoading(true));
      PutInstante(
        values.vacation_items[0].uuid,
        {
          ...sendValues.vacation_items[0],
        },
        "/vacation_items"
      )
        .then((response: any) => {
          dispatch(updateLoading(false));
          router("/request/vacation");
          toast.success(response?.data?.message);
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });
  useEffect(() => {
    GetInstance({}, "/vacation_items/" + id)
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        setData({
          ...response.data.data.user_data,
          statusUser: response.data.data.user_data.status,
          ...response.data.data,
          ...response.data.data.record.vacation,
          ...response.data.data.record,
          ...response.data.data.company_rules,
          history: response.data.data.history,
        });
        response.data.data.record.start_date = dayjs(
          response.data.data.record.start_date
        );
        response.data.data.record.end_date = dayjs(
          response.data.data.record.end_date
        );
        response.data.data.record.payment_date = dayjs(
          response.data.data.record.payment_date
        );

        const values: any = {};
        values.vacations_request_minimum_days =
          response.data.data.company_rules.vacations_request_minimum_days;
        values.vacation_items = [response.data.data.record];
        // ...response.data.data.record,
        values.days_init = response.data.data.record.vacation.days_available;
        values.days_used =
          response.data.data.record.vacation.days_available === 0
            ? 30
            : response.data.data.record.vacation.days_used +
              response.data.data.record.abono_quantity;
        values.days_available =
          response.data.data.record.vacation.days_available;
        values.abono_quantity = response.data.data.record.abono_quantity;

        values.concessive_period_start = new Date(
          response.data.data.record.vacation.concessive_period_start
        );
        values.concessive_period_end = new Date(
          response.data.data.record.vacation.concessive_period_end
        );
        formik.setValues(values);
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const userInfo = [
    {
      title: "Nome",
      value: data.name,
    },
    {
      title: "Cód. cargo",
      value: EmptyData(data.job_role_code),
    },
    {
      title: "Cargo",
      value: EmptyData(data.job_role_name),
    },
    {
      title: "Gestor",
      value: EmptyData(data.manager_name),
    },
    {
      title: "Status",
      value: FormatStatus(data.statusUser),
    },
  ];

  const Info = [
    {
      title: "Período aquisitivo",
      value:
        FormatDate(data.acquisition_period_start) +
        " - " +
        FormatDate(data.acquisition_period_end),
    },
    {
      title: "Dias disponíveis",
      value: EmptyData(formik.values.days_available),
    },
    {
      title: "Dias utilizados",
      value: EmptyData(formik.values.days_used),
    },
    {
      title: "Período concessivo",
      value:
        FormatDate(data.concessive_period_start) +
        " - " +
        FormatDate(data.concessive_period_end),
    },
  ];

  const solicitationHistories =
    data?.history?.length &&
    data?.history?.sort((a: any, b: any) => {
      const dateA: any = new Date(a.created_at);
      const dateB: any = new Date(b.created_at);

      return dateB - dateA;
    });

  const resend = async (resendActionCallback: any) => {
    const sendValues = formik.values;
    // eslint-disable-next-line array-callback-return
    await sendValues.vacation_items.map((item: any) => {
      item.days_quantity = Number(sendValues.days_quantity);
      item.abono_quantity =
        Number(sendValues.days_used) - Number(sendValues.days_quantity);
    });
    delete sendValues?.status;
    delete sendValues.vacation_items[0].vacation;
    dispatch(updateLoading(true));
    PutInstante(
      formik.values.vacation_items[0].uuid,
      {
        ...sendValues.vacation_items[0],
      },
      "/vacation_items"
    )
      .then((response: any) => {
        resendActionCallback();
        dispatch(updateLoading(false));
        router("/request/vacation");
        toast.success(response?.data?.message);
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <Grid container justifyContent={"space-between"}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/'>
              Inicio
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/vacation'>
              Solicitações
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/vacation'>
              Férias
            </Link>
            <Typography fontWeight={800}>Detalhes da solicitação</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid container>
          <Callout.Root size='2' style={{ alignItems: "center" }}>
            <Callout.Icon>
              <InfoOutlined />
            </Callout.Icon>
            <Grid container>
              <Grid container flexDirection={"column"}>
                <Typography fontSize={16} fontWeight={800}>
                  Informações do colaborador
                </Typography>
                <Grid container gap={"12px 96px"} mt={1}>
                  {userInfo.map((item: any) => (
                    <Grid>
                      <Typography fontSize={12} fontWeight={400}>
                        {item.title}
                      </Typography>
                      <Typography fontSize={10} fontWeight={300}>
                        {item.value}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid container flexDirection={"column"}>
                <Typography fontSize={16} fontWeight={800}>
                  Informações da solicitação
                </Typography>
                <Grid container gap={"12px 96px"} mt={1}>
                  {Info.map((item: any) => (
                    <Grid>
                      <Typography fontSize={12} fontWeight={400}>
                        {item.title}
                      </Typography>
                      <Typography fontSize={10} fontWeight={300}>
                        {item.value}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Callout.Root>
        </Grid>
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Detalhes da programação para o período aquisitivo (
                {FormatDate(data?.vacation?.acquisition_period_start) +
                  " - " +
                  FormatDate(data?.vacation?.acquisition_period_end)}
                )
              </Typography>
            </Grid>
            <Grid container flexDirection={"column"}>
              <Tooltip
                content={
                  <Grid container>
                    <Grid>
                      <Typography fontSize={12} fontWeight={400}>
                        <strong>
                          Sempre que houver mais de 1 periodo, o mais antigo
                          sempre é o disponivel para programação.
                        </strong>
                      </Typography>
                      <Typography fontSize={12} fontWeight={400}>
                        <strong> Mínimo de Dias:</strong> Cada período deve ter
                        no mínimo 5 dias.
                      </Typography>
                      <Typography fontSize={12} fontWeight={400}>
                        <strong> Política da empresa:</strong> As férias devem
                        ser solicitadas com pelo menos{" "}
                        {data.company_rules?.vacations_request_minimum_days}{" "}
                        dias de antecedência.
                      </Typography>
                      <Typography fontSize={12} fontWeight={400}>
                        <strong>Divisão de Saldo:</strong> Se dividir o saldo em
                        3 períodos, pelo menos um deve ter 14 dias ou mais.
                      </Typography>
                      <Typography fontSize={12} fontWeight={400}>
                        <strong>Abono Pecuniário:</strong> Ao selecionar "Sim" ,
                        1/3 dos dias disponíveis será convertido em remuneração,
                        e o saldo será reduzido proporcionalmente.
                      </Typography>
                      <Typography fontSize={12} fontWeight={400}>
                        <strong>
                          As férias serão definidas pela disponibilidade de
                          dias, sendo seu início dois dias antes de um feriado
                          ou do Descanso Semanal Remunerado (DSR).
                        </strong>
                      </Typography>
                    </Grid>
                  </Grid>
                }>
                <Typography align='center' fontSize={12} fontWeight={500}>
                  Clique aqui para ver as instruções
                </Typography>
              </Tooltip>
            </Grid>
            {formik.values.vacation_items.map((item: any, index: number) => (
              <>
                <Divider style={{ marginTop: 16 }} />
                <Grid
                  gridTemplateColumns={"1fr 1fr 1fr"}
                  display={"grid"}
                  mt={2}
                  gap={2}>
                  <TextField
                    fullWidth
                    id={`vacation_items[${index}].days_quantity`}
                    type='number'
                    label='Quantidade'
                    disabled={!edit}
                    variant='outlined'
                    value={formik.values.vacation_items[index].days_quantity}
                    onChange={(event: any) => {
                      formik.setFieldValue(
                        `vacation_items[${index}].days_quantity`,
                        event.target.value
                      );
                    }}
                    onBlur={() => {
                      const newValue = Number(
                        formik.values.vacation_items[index]?.days_quantity
                      );

                      const allDaysArray = formik.values.vacation_items.map(
                        (item: any) => Number(item?.days_quantity)
                      );
                      const allDay = allDaysArray.reduce(
                        (acumulador: number, valorAtual: number) => {
                          return Number(acumulador) + Number(valorAtual);
                        },
                        0
                      );
                      formik.setFieldValue(
                        "days_available",
                        Number(data.days_available) - Number(allDay)
                      );
                      formik.setFieldValue(
                        "days_used",
                        Number(data.days_used) + Number(allDay)
                      );
                      if (formik.values.vacation_items[index]?.start_date) {
                        const dateInit = new Date(
                          formik.values.vacation_items[index]?.start_date
                        );
                        dateInit.setUTCDate(dateInit.getUTCDate() + newValue);
                        formik.setFieldValue(
                          `vacation_items[${index}].end_date`,
                          dayjs(dateInit)
                        );
                        const dataPayment = new Date(
                          formik.values.vacation_items[index]?.start_date
                        );
                        dataPayment.setUTCDate(dateInit.getUTCDate() - 2);
                        formik.setFieldValue(
                          `vacation_items[${index}].payment_date`,
                          dayjs(dataPayment)
                        );
                      }
                    }}
                    error={
                      formik.errors.vacation_items &&
                      Boolean(formik.errors.vacation_items[index].days_quantity)
                    }
                    helperText={
                      formik.errors.vacation_items &&
                      formik.errors.vacation_items[index].days_quantity
                    }
                  />
                  <DatePicker
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        id: `vacation_items[${index}].start_date`,
                        onBlur: formik.handleBlur,
                        error:
                          formik.errors.vacation_items &&
                          Boolean(
                            formik.errors.vacation_items[index].start_date
                          ),
                        helperText:
                          formik.errors.vacation_items &&
                          formik.errors.vacation_items[index].start_date,
                      },
                    }}
                    disabled={!edit}
                    label='Data de início'
                    value={formik.values.vacation_items[index].start_date}
                    onChange={(value: any) => {
                      formik.setFieldValue(
                        `vacation_items[${index}].start_date`,
                        value
                      );
                      const dataPayment = new Date(value);
                      dataPayment.setUTCDate(dataPayment.getUTCDate() - 2);
                      formik.setFieldValue(
                        `vacation_items[${index}].payment_date`,
                        dayjs(dataPayment)
                      );
                      if (formik.values.vacation_items[index].days_quantity) {
                        const dateInit = new Date(value);
                        dateInit.setUTCDate(
                          dateInit.getUTCDate() +
                            Number(
                              formik.values.vacation_items[index].days_quantity
                            )
                        );
                        formik.setFieldValue(
                          `vacation_items[${index}].end_date`,
                          dayjs(dateInit)
                        );
                      }
                    }}
                  />
                  <DatePicker
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        id: `vacation_items[${index}].end_date`,
                        onBlur: formik.handleBlur,
                        error:
                          formik.errors.vacation_items &&
                          Boolean(formik.errors.vacation_items[index].end_date),
                        helperText:
                          formik.errors.vacation_items &&
                          formik.errors.vacation_items[index].end_date,
                      },
                    }}
                    disabled
                    label='Data de término'
                    value={formik.values.vacation_items[index].end_date}
                    onChange={(value: any) => {
                      formik.setFieldValue(
                        `vacation_items[${index}].end_date`,
                        value
                      );
                    }}
                  />
                  <TextField
                    fullWidth
                    id={`vacation_items[${index}].thirteen_anticipation`}
                    select
                    disabled={!edit}
                    label='Antecipação do 13°'
                    value={
                      formik.values.vacation_items[index].thirteen_anticipation
                    }
                    onChange={(event: any) =>
                      formik.setFieldValue(
                        `vacation_items[${index}].thirteen_anticipation`,
                        event.target.value
                      )
                    }
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.vacation_items &&
                      Boolean(
                        formik.errors.vacation_items[index]
                          .thirteen_anticipation
                      )
                    }
                    helperText={
                      formik.errors.vacation_items &&
                      formik.errors.vacation_items[index].thirteen_anticipation
                    }>
                    {aviso_e_social.map((option: any) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    fullWidth
                    id={`vacation_items[${index}].abono_pecuniario`}
                    select
                    disabled={!edit}
                    label='Abono pecuniário'
                    value={
                      formik.values.vacation_items[index]?.abono_pecuniario
                    }
                    onChange={(event: any) => {
                      formik.setFieldValue(
                        `vacation_items[${index}].abono_pecuniario`,
                        event.target.value
                      );
                      if (event.target.value) {
                        const abono =
                          data.vacations_custom_days_abono ??
                          Math.floor(data.days_available / 3);
                        formik.setFieldValue("abono_quantity", abono);
                        formik.setFieldValue(
                          "days_available",
                          Number(formik.values.days_available) - Number(abono)
                        );
                        formik.setFieldValue(
                          "days_used",
                          Number(formik.values.days_used) + Number(abono)
                        );
                      } else {
                        const abono =
                          data.vacations_custom_days_abono ??
                          Math.floor(data.days_available / 3);
                        formik.setFieldValue("abono_quantity", abono);
                        formik.setFieldValue(
                          "days_available",
                          Number(formik.values.days_available) + Number(abono)
                        );
                        formik.setFieldValue(
                          "days_used",
                          Number(formik.values.days_used) - Number(abono)
                        );
                      }
                    }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.vacation_items &&
                      Boolean(
                        formik.errors.vacation_items[index]?.abono_pecuniario
                      )
                    }
                    helperText={
                      formik.errors.vacation_items &&
                      formik.errors.vacation_items[index]?.abono_pecuniario
                    }>
                    {aviso_e_social.map((option: any) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <DatePicker
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        id: `vacation_items[${index}].payment_date`,
                        onBlur: formik.handleBlur,
                        error:
                          formik.errors.vacation_items &&
                          Boolean(
                            formik.errors.vacation_items[index].payment_date
                          ),
                        helperText:
                          formik.errors.vacation_items &&
                          formik.errors.vacation_items[index].payment_date,
                      },
                    }}
                    disabled={
                      !(
                        userData?.data?.role === "rh" &&
                        userData?.data?.id !== data?.target_user_id
                      )
                    }
                    label='Data de pagamento'
                    value={formik.values.vacation_items[index].payment_date}
                    onChange={(value: any) => {
                      formik.setFieldValue(
                        `vacation_items[${index}].payment_date`,
                        value
                      );
                    }}
                  />
                </Grid>
                <Grid
                  gridTemplateColumns={"1fr"}
                  mt={2}
                  display={"grid"}
                  gap={2}>
                  <div>
                    <TextField
                      fullWidth
                      disabled={!edit}
                      id={`vacation_items[${index}].description`}
                      label='Informações sobre as férias'
                      variant='outlined'
                      multiline={true}
                      rows={4}
                      value={formik.values.vacation_items[index].description}
                      onChange={(event: any) =>
                        formik.setFieldValue(
                          `vacation_items[${index}].description`,
                          event.target.value
                        )
                      }
                      onBlur={formik.handleBlur}
                      error={
                        formik.errors.vacation_items &&
                        Boolean(formik.errors.vacation_items[index].description)
                      }
                      helperText={
                        formik.errors.vacation_items &&
                        formik.errors.vacation_items[index].description
                      }
                    />
                  </div>
                </Grid>
                <Divider style={{ marginTop: 16 }} />
                {(data.status === "awaiting_acceptance" &&
                  data?.target_user_id === userData?.data?.id) ||
                data.status === "accepted" ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: 20,
                      }}>
                      <FormControlLabel
                        color='red'
                        control={
                          <Checkbox
                            color={"primary"}
                            disabled={data.status === "accepted"}
                            onClick={() => {
                              dispatch(updateLoading(true));
                              PostInstante(
                                null,
                                {
                                  action_type: "accepted",
                                },
                                "/vacation_items/" + id + "/action"
                              )
                                .then((response: any) => {
                                  dispatch(updateLoading(false));
                                  toast.success(response?.data?.message);
                                  setTimeout(() => {
                                    window.history.back();
                                  }, 3000);
                                })
                                .catch((e: any) => {
                                  if (e.code !== "ERR_CANCELED") {
                                    dispatch(updateLoading(false));
                                    toast.error(
                                      e?.response?.data?.message ??
                                        "Erro inesperado, tente novamente."
                                    );
                                  }
                                });
                            }}
                            checked={data.status !== "awaiting_acceptance"}
                          />
                        }
                        label={
                          data.status === "awaiting_acceptance"
                            ? "Clique aqui para aceitar suas férias"
                            : "Férias aceita e confirmada"
                        }
                      />
                    </div>
                    <Divider />
                  </>
                ) : null}
              </>
            ))}
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <Button
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                fullWidth={isMobile}
                endIcon={<Undo />}>
                Voltar
              </Button>

              {isResendable && (
                <ActionResend
                  id={id}
                  url={"vacation_items"}
                  disabled={!formik.isValid}
                  onSubmit={resend}
                />
              )}

              {edit && (
                <Button
                  variant={"contained"}
                  type={"submit"}
                  fullWidth={isMobile}
                  endIcon={<Edit />}>
                  Editar
                </Button>
              )}

              <StatusPermittedByRoleContainer
                rhAllowedStatuses={[
                  "pending",
                  "waiting_manager_user",
                  "waiting_rh_user",
                ]}
                managerAllowedStatuses={["pending", "waiting_manager_user"]}
                status={data?.status}>
                <ActionReturnedRule
                  rule={data.company_rules?.approve || editManager}
                  id={id}
                  url={"vacation_items"}
                />
                <ActionDisapprovedRule
                  rule={data.company_rules?.approve || editManager}
                  id={id}
                  url={"vacation_items"}
                />
                <ActionAprovedRule
                  rule={data.company_rules?.approve || editManager}
                  id={id}
                  url={"vacation_items"}
                />
              </StatusPermittedByRoleContainer>

              <StatusPermittedByRoleContainer
                rhAllowedStatuses={["accounting_return"]}
                status={data?.solicitation_status}>
                <ActionReturnedRule
                  rule={data.company_rules?.approve || editManager}
                  id={id}
                  url={"vacation_items"}
                />
                <ActionDisapprovedRule
                  rule={data.company_rules?.approve || editManager}
                  id={id}
                  url={"vacation_items"}
                />
              </StatusPermittedByRoleContainer>

              <StatusPermittedByRoleContainer
                accountantAllowedStatuses={["approved"]}
                status={data?.solicitation_status}>
                <ActionCompleted
                  keyPrivate={"requestDetailsComplete"}
                  id={id}
                  url={"vacation_items"}
                />

                <ActionAccountingDisapproved
                  keyPrivate={"requestDetailsComplete"}
                  id={id}
                  url={"vacation_items"}
                />
              </StatusPermittedByRoleContainer>
            </Grid>

            {data?.history?.length > 0 && (
              <Typography fontSize={18} fontWeight={500}>
                Histórico do pedido
              </Typography>
            )}
            {data?.history?.length &&
              solicitationHistories.map((history: any, index: number) => {
                return (
                  <Accordion
                    sx={{
                      marginTop: 3.5,
                      border: `1px solid ${theme.palette.divider}`,
                      borderRadius: 1,
                      "&:before": {
                        display: "none",
                      },
                    }}
                    expanded={expandedHistoricId === history?.id}
                    onChange={() => setExpandedHistoricId(history?.id)}>
                    <AccordionSummary
                      aria-controls='panel1d-content'
                      id='panel1d-header'
                      expandIcon={
                        <ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />
                      }
                      sx={{
                        margin: 0,
                        flexDirection: "row-reverse",
                        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":
                          {
                            transform: "rotate(90deg)",
                          },
                      }}>
                      <Box
                        display='flex'
                        alignItems='center'
                        justifyContent='space-between'
                        width='100%'>
                        <Typography
                          marginLeft={2}
                          fontSize={18}
                          fontWeight={500}>
                          {`${history.user_name} - ${FormatStatusToHistoric(
                            history.status
                          )}`}
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid display='flex' flexDirection='column' gap={2}>
                        <Grid
                          gridTemplateColumns={"1fr 1fr 1fr"}
                          display={"grid"}
                          gap={2}>
                          <TextField
                            fullWidth
                            label='Quantidade'
                            variant='outlined'
                            rows={4}
                            disabled
                            value={history.new_data.days_quantity}
                          />
                          <TextField
                            fullWidth
                            label='Data de início'
                            variant='outlined'
                            rows={4}
                            disabled
                            value={FormatDate(history.new_data.start_date)}
                          />
                          <TextField
                            fullWidth
                            label='Data de término'
                            variant='outlined'
                            rows={4}
                            disabled
                            value={FormatDate(history.new_data.end_date)}
                          />
                        </Grid>
                        <Grid
                          gridTemplateColumns={"1fr 1fr 1fr"}
                          display={"grid"}
                          gap={2}>
                          <TextField
                            fullWidth
                            label='Antecipação do 13°'
                            variant='outlined'
                            rows={4}
                            disabled
                            value={
                              aviso_e_social?.find(
                                (item) =>
                                  item.value ===
                                  history.new_data.thirteen_anticipation
                              )?.label
                            }
                          />
                          <TextField
                            fullWidth
                            label='Abono pecuniário'
                            variant='outlined'
                            rows={4}
                            disabled
                            value={
                              aviso_e_social?.find(
                                (item) =>
                                  item.value ===
                                  history.new_data.abono_pecuniario
                              )?.label
                            }
                          />
                          <TextField
                            fullWidth
                            label='Data de pagamento'
                            variant='outlined'
                            rows={4}
                            disabled
                            value={FormatDate(history.new_data.payment_date)}
                          />
                        </Grid>
                        <Grid
                          gridTemplateColumns={"1fr"}
                          display={"grid"}
                          gap={2}>
                          <TextField
                            fullWidth
                            label='Informações sobre as férias'
                            variant='outlined'
                            rows={4}
                            multiline={true}
                            disabled
                            value={history.new_data.description}
                          />
                        </Grid>
                        {history.observations && (
                          <Grid
                            gridTemplateColumns={"1fr"}
                            display={"grid"}
                            gap={2}>
                            <TextField
                              fullWidth
                              label='Justificativa'
                              variant='outlined'
                              rows={4}
                              multiline={true}
                              disabled
                              value={history.observations}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
