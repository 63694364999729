/** @format */

import { Cancel, CheckCircle, Send } from "@mui/icons-material";
import { Button } from "@mui/material";
import { AlertDialog, Flex } from "@radix-ui/themes";
import { isMobile } from "react-device-detect";
import Lottie from "react-lottie";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import animationData from "../assets/lotties/loading_modal.json";
import { GenericError } from "../helpers/genericError";
import { updateLoading } from "../redux/reducers/common";
import { PostInstante } from "../services/api";

export function ActionAprovedRule({ rule, id, url }: any) {
  const dispatch = useDispatch();
  const loading = useSelector((state: any) => state.common.loading);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  function Approve() {
    dispatch(updateLoading(true));
    PostInstante(
      null,
      {
        action_type: "approved",
        action_justification: null,
      },
      "/" + url + "/" + id + "/action"
    )
      .then((response: any) => {
        dispatch(updateLoading(false));
        toast.success(response?.data?.message);
        setTimeout(() => {
          window.history.back();
        }, 3000);
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
  }

  return (
    rule && (
      <form>
        <AlertDialog.Root>
          <AlertDialog.Trigger>
            <Button
              fullWidth={isMobile}
              variant={"contained"}
              color={"success"}
              endIcon={<Send />}>
              Aprovar
            </Button>
          </AlertDialog.Trigger>
          <AlertDialog.Content maxWidth='450px'>
            {loading ? (
              <Lottie options={defaultOptions} height={"auto"} width={200} />
            ) : (
              <>
                <AlertDialog.Title>
                  Deseja realmente aprovar esta solicitação?
                </AlertDialog.Title>
                <AlertDialog.Description size='2'>
                  Após a confirmação, não será possivel reverter o processo.
                  Tenha absoluta certeza antes de realizar essa ação.
                </AlertDialog.Description>
                <Flex gap='3' mt='4' justify='end'>
                  <AlertDialog.Cancel>
                    <Button variant={"outlined"} endIcon={<Cancel />}>
                      Cancelar
                    </Button>
                  </AlertDialog.Cancel>
                  <AlertDialog.Action>
                    <Button
                      variant={"contained"}
                      onClick={Approve}
                      endIcon={<CheckCircle />}>
                      Confirmar
                    </Button>
                  </AlertDialog.Action>
                </Flex>
              </>
            )}
          </AlertDialog.Content>
        </AlertDialog.Root>
      </form>
    )
  );
}
