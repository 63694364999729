/** @format */

import { DatePicker } from "@mui/x-date-pickers";
import { Filter } from "../../../../../components/refactor/filter";

export function Filters({ formik }: any) {
  return (
    <Filter
      formik={formik}
      id={"vacation_dashboard"}
      keys={{ from: { label: "Data inicio" }, to: { label: "Data final" } }}>
      <DatePicker
        slotProps={{
          textField: {
            fullWidth: true,
            id: "from",
            onBlur: formik.handleBlur,
            error: formik.touched.from && Boolean(formik.errors.from),
            helperText: formik?.errors?.from,
          },
        }}
        label='Data Inícial'
        value={formik.values.from}
        onChange={(value: any) => {
          formik.setFieldValue("from", value);
        }}
      />
      <DatePicker
        slotProps={{
          textField: {
            fullWidth: true,
            id: "to",
            onBlur: formik.handleBlur,
            error: formik.touched.to && Boolean(formik.errors.to),
            helperText: formik?.errors?.to,
          },
        }}
        label='Data Final'
        value={formik.values.to}
        onChange={(value: any) => {
          formik.setFieldValue("to", value);
        }}
      />
    </Filter>
  );
}
