/** @format */

import { InfoOutlined } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { Callout } from "@radix-ui/themes";
import { requestInfo, RequestProps } from "../../config/requestInfo";
import { userInfo, UserProps } from "../../config/userInfo";

interface Props {
  user?: UserProps;
  instruction?: string;
  solicitation?: RequestProps;
}

export const InfoComponent = ({ user, instruction, solicitation }: Props) => {
  return (
    <Grid container>
      <Callout.Root size='2' style={{ alignItems: "center" }}>
        <Callout.Icon>
          <InfoOutlined />
        </Callout.Icon>
        {user && (
          <Grid container flexDirection={"column"}>
            <Typography fontSize={16} fontWeight={800}>
              Informações do colaborador
            </Typography>
            <Grid container gap={"12px 96px"} mt={1}>
              {userInfo(user).map((item: any) => (
                <Grid>
                  <Typography fontSize={12} fontWeight={600}>
                    {item.title}
                  </Typography>
                  <Typography fontSize={10} fontWeight={300}>
                    {item.value}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
        {solicitation && (
          <Grid container flexDirection={"column"}>
            <Typography fontSize={16} fontWeight={800}>
              Informações da solicitação
            </Typography>
            <Grid container gap={"12px 96px"} mt={1}>
              {requestInfo(solicitation).map((item: any) => (
                <Grid>
                  <Typography fontSize={12} fontWeight={600}>
                    {item.title}
                  </Typography>
                  <Typography fontSize={10} fontWeight={300}>
                    {item.value}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
        {instruction && (
          <Grid container flexDirection={"column"}>
            <Typography fontSize={16} fontWeight={800}>
              Instruções
            </Typography>
            <Typography fontSize={12} fontWeight={400}>
              {instruction}
            </Typography>
          </Grid>
        )}
      </Callout.Root>
    </Grid>
  );
};
