/** @format */

import { Edit, Info, Undo } from "@mui/icons-material";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import { useFormik } from "formik";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BreadcrumbsComponent } from "../../../../../components/refactor/breadcrumbsComponent";
import { ButtonComponent } from "../../../../../components/refactor/buttonComponent";
import { InfoComponent } from "../../../../../components/refactor/infoComponent";
import { optionsTable } from "../../../../../config/optionsTable";
import { FilterEmpty } from "../../../../../helpers/emptyData";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../../services/api";
import { columns2, validationSchema } from "../helpers/constants";
import { Form } from "../helpers/form";

export function VacationDetailsTime() {
  const router = useNavigate();
  const { id } = useParams();
  const [data, setData]: any = useState({});
  const [table, setTable]: any = useState([]);

  const dispatch = useDispatch();
  const formik: any = useFormik({
    initialValues: {
      initialValues: {
        concessive_period_start: null,
        concessive_period_end: null,
        acquisition_period_start: null,
        acquisition_period_end: null,
        days_available: "",
        days_used: "",
      },
    },
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values: any) => {
      dispatch(updateLoading(true));
      PutInstante(values.uuid, values, "/vacations")
        .then((response: any) => {
          dispatch(updateLoading(false));
          router("/vacation/time");
          toast.success(response?.data?.message);
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    GetInstance({}, "/vacations/" + id)
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        setData({
          ...response.data.data.user_data,
          ...response.data.data.company_rules,
        });
        setTable(FilterEmpty(response.data.data.record.vacation_items));
        formik.setValues({
          ...response.data.data.record,
          concessive_period_start: dayjs(
            response.data.data.record.concessive_period_start
          ),
          concessive_period_end: dayjs(
            response.data.data.record.concessive_period_end
          ),
          acquisition_period_start: dayjs(
            response.data.data.record.acquisition_period_start
          ),
          acquisition_period_end: dayjs(
            response.data.data.record.acquisition_period_end
          ),
        });
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Férias", value: "/vacation/time" },
            { label: "Periodo", value: "/vacation/time" },
            { label: "Detalhes do periodo" },
          ]}
        />
        <InfoComponent user={data} />

        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Editar periodo de férias
              </Typography>
            </Grid>
            <Form formik={formik} />
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <ButtonComponent
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                endIcon={<Undo />}>
                Voltar
              </ButtonComponent>
              <ButtonComponent
                variant={"contained"}
                onClick={() => {
                  formik.handleSubmit();
                }}
                endIcon={<Edit />}>
                Editar
              </ButtonComponent>
            </Grid>
          </Paper>
        </Grid>
        <Grid container>
          <MUIDataTable
            title={"Programações"}
            data={table}
            columns={[
              {
                name: "action",
                label: "Ações",
                options: {
                  sort: false,
                  customBodyRenderLite: (dataIndex: number) => {
                    return (
                      <Tooltip title={"Ver informações"}>
                        <IconButton
                          onClick={() =>
                            router(
                              `/request/vacation/details/${table[dataIndex].uuid}`
                            )
                          }>
                          <Info />
                        </IconButton>
                      </Tooltip>
                    );
                  },
                },
              },
              ...columns2,
            ]}
            options={{
              ...optionsTable,
              searchAlwaysOpen: false,
              order: false,
              download: false,
              search: false,
              count: table.length,
              page: 1,
              rowsPerPage: table.length,
            }}
          />
        </Grid>
      </Grid>
    </form>
  );
}
