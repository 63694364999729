/** @format */

import { Edit, InfoOutlined, Undo } from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  Grid,
  Link,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { Callout } from "@radix-ui/themes";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import { ConfigSelection } from "../../../../components/configSelection";
import { abono_settings } from "../../../../config/constants";
import { GenericError } from "../../../../helpers/genericError";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../services/api";

const validationSchema = yup.object().shape({
  vacations_request_minimum_days: yup
    .number()
    .required("Campo obrigatório")
    .min(30, "Minimo de 30 dias"),
});

export function PermissionsVacation() {
  const [leftApprovers, setLeftApprovers]: any = useState([]);
  const [rightApprovers, setRightApprovers]: any = useState([]);

  const [leftApproversMaster, setLeftApproversMaster]: any = useState([]);
  const [rightApproversMaster, setRightApproversMaster]: any = useState([]);

  const [options, setOptions]: any = useState([]);
  const dispatch = useDispatch();
  const formik: any = useFormik({
    initialValues: {
      vacations_request_minimum_days: "",
      custom_abono: false,
      vacations_custom_days_abono: 0,
    },
    validationSchema: validationSchema,
    onSubmit: async (values: any) => {
      dispatch(updateLoading(true));
      PutInstante(
        null,
        {
          vacations_request_minimum_days: values.vacations_request_minimum_days,
          vacations_rules: {
            approvers: rightApprovers,
            master_approvers: rightApproversMaster,
            vacations_custom_days_abono: values.custom_abono
              ? Number(values.vacations_custom_days_abono)
              : null,
          },
        },
        "/company_settings"
      )
        .then((response: any) => {
          dispatch(updateLoading(false));
          toast.success(response?.data?.message);
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    GetInstance({}, "/company_settings/")
      .then(async (response: any) => {
        GetInstance({}, "/users/options?settings=true")
          .then(async (response2: any) => {
            dispatch(updateLoading(false));
            setOptions(response2.data.data);
            formik.setValues({
              vacations_request_minimum_days:
                response.data.data.record.vacations_request_minimum_days,
              custom_abono:
                response.data.data.record.vacations_rules
                  .vacations_custom_days_abono !== null &&
                response.data.data.record.vacations_rules
                  .vacations_custom_days_abono !== undefined,
              vacations_custom_days_abono:
                response.data.data.record.vacations_rules
                  .vacations_custom_days_abono,
            });
            const arrayIds = response2.data.data.map((opt: any) => opt.value);
            if (
              Object.keys(response.data.data.record.vacations_rules).length > 0
            ) {
              if (response.data.data.record.vacations_rules?.approvers) {
                const resultOpt = arrayIds.filter(
                  (item: any) =>
                    !response.data.data.record.vacations_rules.approvers.includes(
                      item
                    )
                );
                setRightApprovers(
                  response.data.data.record.vacations_rules.approvers
                );
                setLeftApprovers(resultOpt);
              } else {
                setLeftApprovers(arrayIds);
              }
              if (response.data.data.record.vacations_rules?.master_approvers) {
                const resultOpt = arrayIds.filter(
                  (item: any) =>
                    !response.data.data.record.vacations_rules.master_approvers.includes(
                      item
                    )
                );
                setRightApproversMaster(
                  response.data.data.record.vacations_rules.master_approvers
                );
                setLeftApproversMaster(resultOpt);
              }
            } else {
              setLeftApproversMaster(arrayIds);
            }
          })
          .catch((e: any) => {
            if (e.code !== "ERR_CANCELED") {
              dispatch(updateLoading(false));
              toast.error(
                e?.response?.data?.message ??
                  "Erro inesperado, tente novamente."
              );
            }
          });
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form>
      <Grid container gap={2}>
        <Grid container justifyContent={"space-between"}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/'>
              Inicio
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/settings/vacation'>
              Configurações
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/settings/vacation'>
              Férias
            </Link>
            <Typography fontWeight={800}>Ver detalhes</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid container>
          <Callout.Root size='2' style={{ alignItems: "center" }}>
            <Callout.Icon>
              <InfoOutlined />
            </Callout.Icon>
            <Grid container flexDirection={"column"}>
              <Typography fontSize={16} fontWeight={400}>
                Nesta tela é permitido a configuração de usuários com permissão
                aos fluxos de férias e suas configurações da empresa.
              </Typography>
              <Typography fontSize={16} fontWeight={400} mt={1}>
                Para realizar esse procedimento, é só selecionar os itens que
                deseja incluir no lado esquerdo, enviar para o lado direito. e
                para remover é só realizar o processo contrário, após a
                conclusão da personalização, clique em 'SALVAR MODIFICAÇÕES'
                para concluir.
              </Typography>
            </Grid>
          </Callout.Root>
        </Grid>
        <Paper sx={{ width: "100%", height: "auto" }}>
          <Grid gridTemplateColumns={"1fr"} display={"grid"} m={2} gap={2}>
            <Typography fontSize={14} fontWeight={500}>
              Configurações
            </Typography>
          </Grid>
          <Grid
            gridTemplateColumns={"1fr 1fr 1fr 1fr"}
            display={"grid"}
            m={2}
            gap={2}>
            <TextField
              fullWidth
              id={`vacations_request_minimum_days`}
              type='number'
              label='Quantidade mínima de dias para solicitar férias'
              variant='outlined'
              value={formik.values.vacations_request_minimum_days}
              onChange={(event: any) => {
                formik.setFieldValue(
                  `vacations_request_minimum_days`,
                  event.target.value
                );
              }}
              error={Boolean(formik.errors.vacations_request_minimum_days)}
              helperText={formik.errors.vacations_request_minimum_days}
            />
            <TextField
              fullWidth
              id='custom_abono'
              name='custom_abono'
              select
              label='Dias de abono'
              value={formik.values.custom_abono}
              onChange={(event: any) =>
                formik.setFieldValue("custom_abono", event.target.value)
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.custom_abono &&
                Boolean(formik.errors.custom_abono)
              }
              helperText={
                formik.touched.custom_abono && formik.errors.custom_abono
              }>
              {abono_settings.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            {formik.values.custom_abono && (
              <TextField
                fullWidth
                id={`vacations_custom_days_abono`}
                type='number'
                label='Quantidade de dias personalizado para o abono'
                variant='outlined'
                value={formik.values.vacations_custom_days_abono}
                onChange={(event: any) => {
                  formik.setFieldValue(
                    `vacations_custom_days_abono`,
                    event.target.value
                  );
                }}
                error={Boolean(formik.errors.vacations_custom_days_abono)}
                helperText={formik.errors.vacations_custom_days_abono}
              />
            )}
          </Grid>
        </Paper>
        <Grid container>
          <Callout.Root size='2' style={{ alignItems: "center" }}>
            <Callout.Icon>
              <InfoOutlined />
            </Callout.Icon>
            <Grid container flexDirection={"column"}>
              <Typography fontSize={16} fontWeight={400} mt={1}>
                Usuários com permissão, porém com restrição do GESTOR para as
                ações:
              </Typography>
              <Typography fontSize={16} fontWeight={800} mt={1}>
                {" "}
                VER DETALHES, APROVAR E REPROVAR.
              </Typography>
            </Grid>
          </Callout.Root>
        </Grid>

        <ConfigSelection
          leftLabel='Usuários sem permissão:'
          rightLabel='Usuários com permissão:'
          left={leftApprovers}
          right={rightApprovers}
          options={options}
          setLeft={setLeftApprovers}
          setRight={setRightApprovers}
        />

        <Grid container>
          <Callout.Root size='2' style={{ alignItems: "center" }}>
            <Callout.Icon>
              <InfoOutlined />
            </Callout.Icon>
            <Grid container flexDirection={"column"}>
              <Typography fontSize={16} fontWeight={400} mt={1}>
                Usuários com permissão sem restrição para as ações:
              </Typography>
              <Typography fontSize={16} fontWeight={800} mt={1}>
                VER DETALHES, APROVAR E REPROVAR.
              </Typography>
            </Grid>
          </Callout.Root>
        </Grid>

        <ConfigSelection
          leftLabel='Usuários sem permissão:'
          rightLabel='Usuários com permissão:'
          left={leftApproversMaster}
          right={rightApproversMaster}
          options={options}
          setLeft={setLeftApproversMaster}
          setRight={setRightApproversMaster}
        />

        <Grid container gap={2} justifyContent={"end"} mt={2}>
          <Button
            variant={"outlined"}
            onClick={() => {
              window.history.back();
            }}
            endIcon={<Undo />}>
            Voltar
          </Button>
          <Button
            variant={"contained"}
            onClick={formik.handleSubmit}
            endIcon={<Edit />}>
            Salvar modificações
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
