/** @format */

import { Cancel, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import { GenericError } from "../../../../helpers/genericError";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../services/api";

const validationSchema = yup.object({
  questions: yup.array().of(
    yup.object({
      answer: yup.string().required("A resposta é obrigatória"),
    })
  ),
});

export function SecretAnswers() {
  const dispatch = useDispatch();

  const formik: any = useFormik({
    initialValues: {
      questions: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      const params = {
        questions: values.questions
          .filter((question: any) => question.enable)
          .map((question: any) => ({
            id: question.id,
            answer: question.answer,
          })),
      };
      PutInstante(null, params, "/secret_questions/update_user_secret_answers")
        .then((r: any) => {
          dispatch(updateLoading(false));
          window.location.reload();
          toast.success(r.data.message);
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  const toggleQuestionsVisibility = (index: number) => {
    const updatedQuestions = formik.values.questions.map(
      (question: any, i: number) =>
        i === index
          ? { ...question, visibility: !question.visibility }
          : question
    );
    formik.setFieldValue("questions", updatedQuestions);
  };

  const handleEnableQuestion = (index: number) => {
    const updatedQuestions = formik.values.questions.map(
      (question: any, i: number) =>
        i === index ? { ...question, answer: "", enable: true } : question
    );
    formik.setFieldValue("questions", updatedQuestions);
  };

  useEffect(() => {
    const find_secret_questions = () => {
      dispatch(updateLoading(true));
      GetInstance({}, `/secret_questions`)
        .then(async (response: any) => {
          dispatch(updateLoading(false));
          const {
            secret_questions: secretQuestions,
            unanswered_questions: unansweredQuestions,
          } = response.data.data.records;
          formik.setValues({
            questions: secretQuestions.map((question: any) => {
              const isUnanswered = unansweredQuestions.includes(question.id);
              return {
                id: question.id,
                label: question.question,
                visibility: false,
                answer: isUnanswered ? "" : "*************************",
                enable: isUnanswered,
              };
            }),
          });
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    };
    find_secret_questions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid gap={2} container flexDirection='column'>
        {formik.values.questions.map((question: any, index: number) => (
          <TextField
            key={index}
            fullWidth
            id={`questions[${index}].answer`}
            label={question.label}
            disabled={!question.enable}
            value={question.answer}
            variant='outlined'
            onChange={(event: any) => {
              formik.setFieldValue(
                `questions[${index}].answer`,
                event.target.value
              );
            }}
            onBlur={formik.handleBlur}
            type={question.visibility ? "text" : "password"}
            error={
              formik.touched.questions?.[index]?.answer &&
              Boolean(formik.errors.questions?.[index]?.answer)
            }
            helperText={
              formik.touched.questions?.[index]?.answer &&
              formik.errors.questions?.[index]?.answer
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  {question.enable ? (
                    <IconButton
                      aria-label='toggle visibility'
                      edge='end'
                      onClick={() => toggleQuestionsVisibility(index)}>
                      {question.visibility ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility color='primary' />
                      )}
                    </IconButton>
                  ) : (
                    <IconButton
                      aria-label='handle enable'
                      edge='end'
                      onClick={() => handleEnableQuestion(index)}>
                      <Cancel />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
        ))}
        <Button type='submit' fullWidth variant='contained'>
          Atualizar perguntas secretas
        </Button>
      </Grid>
    </form>
  );
}
