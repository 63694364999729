/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

import { Add, Visibility } from "@mui/icons-material";
import { Grid, IconButton, Tooltip } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ActionRemove } from "../../../../components/refactor/actionRemove";
import { BreadcrumbsComponent } from "../../../../components/refactor/breadcrumbsComponent";
import { ButtonComponent } from "../../../../components/refactor/buttonComponent";
import { optionsTable } from "../../../../config/optionsTable";
import { IsPermissions } from "../../../../helpers/isPermission";
import useRequestUpdateInformation from "../../../../services/protected/hooks/useRequestUpdateInformation";
import { columns } from "./helpers/constants";

export function UpdateInformation() {
  const router = useNavigate();
  const user = useSelector((state: any) => state.user.data);
  const delegation = useSelector((state: any) => state.user.delegation_data);
  const {
    data,
    total,
    page,
    perPage,
    setPage,
    setPerPage,
    setOrder,
    searchCall,
  } = useRequestUpdateInformation();

  return (
    <Grid container gap={2}>
      <BreadcrumbsComponent
        links={[
          { label: "Inicio", value: "/" },
          { label: "Solicitações", value: "/request" },
          { label: "Alteração cadastral", value: "/request/updateInformation" },
        ]}
        buttons={
          !delegation?.delegated_session && (
            <ButtonComponent
              variant={"contained"}
              onClick={() => {
                router(`/request/updateInformation/request/${user.uuid}`);
              }}
              endIcon={<Add />}>
              Solicitar alteração cadastral
            </ButtonComponent>
          )
        }
      />
      <Grid container>
        <MUIDataTable
          title={""}
          data={data}
          columns={[
            {
              name: "action",
              label: "Ações",
              options: {
                sort: false,
                customBodyRenderLite: (dataIndex: number) => {
                  return (
                    <Grid container>
                      <IsPermissions
                        keyPrivate={"requestUpdateInformationDetails"}>
                        <Tooltip title={"Ver solicitação"}>
                          <IconButton
                            onClick={() =>
                              router(
                                `/request/updateInformation/details/${data[dataIndex].uuid}`
                              )
                            }>
                            <Visibility color='primary' />
                          </IconButton>
                        </Tooltip>
                      </IsPermissions>
                      <ActionRemove
                        statusList={data[dataIndex].solicitation_status}
                        url='/user_update_requests'
                        id={data[dataIndex].uuid}
                      />
                    </Grid>
                  );
                },
              },
            },
            ...columns,
          ]}
          options={{
            ...optionsTable,
            searchPlaceholder: "Pesquise por nome",
            download: false,
            onSearchChange: (value: string) => {
              searchCall(value);
            },
            onColumnSortChange: (value: string, direction: string) => {
              setOrder(`${value} ${direction}`);
            },
            count: total,
            page,
            onChangePage: (page: number) => {
              setPage(page);
            },
            rowsPerPage: perPage,
            onChangeRowsPerPage: (perPage: number) => {
              setPage(0);
              setPerPage(perPage);
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
