/** @format */

import { Grid, MenuItem, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { aviso_e_social } from "../../../../../config/constants";
import { PostInstante } from "../../../../../services/api";

export const Form = ({ formik, options, edit }: any) => {
  return (
    <>
      <Grid gridTemplateColumns={"1fr 1fr"} display={"grid"} gap={2}>
        <DatePicker
          slotProps={{
            textField: {
              fullWidth: true,
              id: "dismissal_date",
              onBlur: async (event) => {
                formik.handleBlur(event);
                if (event.target.value) {
                  const { data } = await PostInstante(
                    null,
                    { dismissal_date: event.target.value },
                    "/dismissals/validate_date"
                  );
                  if (!data.data.success) {
                    formik.setErrors({
                      dismissal_date:
                        "A data de desligamento excede o limite permitido de D+1. Por favor, contate o departamento de RH para proceder com o desligamento retroativo.",
                    });
                  }
                }
              },
              error: Boolean(formik.errors.dismissal_date),
              helperText: formik.errors.dismissal_date,
            },
          }}
          disabled={edit}
          label='Data de saída'
          value={formik.values.dismissal_date}
          onChange={(value: any) => {
            formik.validateField("dismissal_date");
            formik.setFieldTouched("dismissal_date", true);
            formik.setFieldValue("dismissal_date", value);
          }}
        />
        <TextField
          fullWidth
          id='early_warning'
          select
          disabled={edit}
          label='Vai cumprir aviso prévio'
          value={formik.values.early_warning}
          onChange={(event: any) =>
            formik.setFieldValue("early_warning", event.target.value)
          }
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors.early_warning)}
          helperText={formik.errors.early_warning}>
          {aviso_e_social.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
        <TextField
          fullWidth
          id='dismissal_type'
          select
          disabled={edit}
          label='Tipo de desligamento'
          value={formik.values.dismissal_type}
          onChange={(event: any) =>
            formik.setFieldValue("dismissal_type", event.target.value)
          }
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors.dismissal_type)}
          helperText={formik.errors.dismissal_type}>
          {options.dismissal_types.map((option: any) => (
            <MenuItem key={option} value={option.id}>
              {option.id} - {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
        <TextField
          fullWidth
          disabled={edit}
          id='dismissal_reason'
          select
          label='Motivo do desligamento'
          value={formik.values.dismissal_reason}
          onChange={(event: any) =>
            formik.setFieldValue("dismissal_reason", event.target.value)
          }
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors.dismissal_reason)}
          helperText={formik.errors.dismissal_reason}>
          {options.dismissal_reasons.map((option: any) => (
            <MenuItem key={option} value={option.id}>
              {option.id} - {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
        <div>
          <TextField
            fullWidth
            disabled={edit}
            id='comment'
            label='Comentários'
            variant='outlined'
            multiline={true}
            rows={4}
            value={formik.values.comment}
            onChange={(event: any) =>
              formik.setFieldValue("comment", event.target.value)
            }
            onBlur={formik.handleBlur}
            error={Boolean(formik.errors.comment)}
            helperText={formik.errors.comment}
          />
        </div>
      </Grid>
    </>
  );
};
