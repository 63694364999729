/** @format */

import { East } from "@mui/icons-material";
import { Button, Grid, Hidden, Link, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GenericError } from "../../../helpers/genericError";
import { ModeTheme } from "../../../helpers/mode";
import { GetInstance } from "../../../services/api";

export function MethodForgetPasswordPage() {
  const company = useSelector((state: any) => state.company);
  const dispatch = useDispatch();
  const router = useNavigate();

  const [forgetPasswordSettings, setForgetPasswordSettings]: any = useState({});

  useEffect(() => {
    const params = {
      name: company.company,
    };
    GetInstance(params, "/company_settings/password_recovery_settings")
      .then(async (response: any) => {
        setForgetPasswordSettings(
          response.data.data.password_recovery_settings
        );
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        height={"100vh"}
        padding={10}>
        <Grid style={{ position: "absolute", top: 10, right: 10 }}>
          <ModeTheme />
        </Grid>
        <Grid item xs={12} lg={6} md={4}>
          <Grid container justifyContent={"center"}>
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
              maxWidth={500}
              gap={3}>
              <img {...company.logoDefault} alt='Logo da empresa' />
              <div>
                <Typography
                  textAlign={"center"}
                  fontWeight={600}
                  fontSize={"2.4rem"}>
                  Redefinição de senha
                </Typography>
                <Typography textAlign={"center"} color={"grey"}>
                  Bem vindo a seção de recuperação de senha do nosso sistema de
                  gestão de RH. Abaixo você pode escolher entre esses métodos
                  para redefinir sua senha.
                </Typography>
              </div>

              {forgetPasswordSettings?.secret_questions && (
                <Button
                  onClick={() => router("/forgetPasswordSecretQuestions")}
                  fullWidth
                  variant='contained'
                  endIcon={<East />}>
                  Recuperar senha por perguntas
                </Button>
              )}

              {forgetPasswordSettings?.default && (
                <Button
                  onClick={() => router("/forgetPasswordStandard")}
                  fullWidth
                  variant='contained'
                  endIcon={<East />}>
                  Recuperar senha para a padrão
                </Button>
              )}

              {forgetPasswordSettings?.email && (
                <Button
                  onClick={() => router("/forgetPasswordSendEmail")}
                  fullWidth
                  variant='contained'
                  endIcon={<East />}>
                  Recuperar senha por email
                </Button>
              )}

              <Link
                style={{ cursor: "pointer" }}
                underline={"hover"}
                href={"/login"}>
                Voltar
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Hidden smDown>
          <Grid item lg={6} md={8}>
            <Grid container justifyContent={"center"}>
              <img
                src={company.image}
                style={{
                  objectFit: "contain",
                  objectPosition: "center",
                  height: "auto",
                  borderRadius: 20,
                  width: "80%",
                  maxHeight: 700,
                }}
                width={0}
                height={0}
                sizes='100vw'
                alt='Imagem da empresa'
              />
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
    </>
  );
}
