/** @format */

import { EmptyData } from "../helpers/emptyData";
import { FormatStatus } from "../helpers/formatStatus";

export interface RequestProps {
  code: string;
  date: string;
  created_by: string;
  status: string;
  action_justification: string;
}

export const requestInfo = (data: RequestProps) => [
  {
    title: "Código",
    value: data.code,
  },
  {
    title: "Data",
    value: data.date,
  },
  {
    title: "Solicitante",
    value: EmptyData(data.created_by),
  },
  {
    title: "Status",
    value: FormatStatus(data.status),
  },
  {
    title: "Justificativa",
    value: EmptyData(data.action_justification),
  },
];
