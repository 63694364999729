/** @format */

import { Cancel, CheckCircle } from "@mui/icons-material";
import { Button } from "@mui/material";
import { AlertDialog, Flex } from "@radix-ui/themes";

export const DialogDate = ({ date, type, onSubmit, setOpenModalDate }: any) => {
  return (
    <AlertDialog.Root open>
      <AlertDialog.Content maxWidth='450px'>
        <AlertDialog.Title color='red'>
          Atenção - Periodo de estabilidade!
        </AlertDialog.Title>
        <AlertDialog.Description size='2'>
          <strong>Até o dia:</strong> {date}
        </AlertDialog.Description>
        <AlertDialog.Description size='2'>
          <strong>Motivo:</strong> {type}
        </AlertDialog.Description>
        <AlertDialog.Description size='2' style={{ gap: 12 }}>
          Se deseja prossegir com a solicitação, clique no botão abaixo para
          confirmar sua escolha.
        </AlertDialog.Description>
        <Flex gap='3' mt='4' justify='end'>
          <AlertDialog.Cancel>
            <Button
              color='error'
              variant={"outlined"}
              onClick={() => setOpenModalDate(false)}
              endIcon={<Cancel />}>
              Cancelar
            </Button>
          </AlertDialog.Cancel>
          <AlertDialog.Action>
            <Button
              color='success'
              variant={"contained"}
              onClick={onSubmit}
              endIcon={<CheckCircle />}>
              Confirmar
            </Button>
          </AlertDialog.Action>
        </Flex>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};
