/** @format */

import { AddCircle, Undo } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { BreadcrumbsComponent } from "../../../../../components/refactor/breadcrumbsComponent";
import { ButtonComponent } from "../../../../../components/refactor/buttonComponent";
import { InfoComponent } from "../../../../../components/refactor/infoComponent";
import { ClearCurrency } from "../../../../../helpers/formatCurrency";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance, PostInstante } from "../../../../../services/api";
import { validationSchema } from "../helpers/constants";
import { Form } from "../helpers/form";

export function PromoteNew() {
  const router = useNavigate();
  const { id } = useParams();
  const [data, setData]: any = useState({
    options: {
      business_unit: [],
      sector: [],
      cost_center: [],
      job_role: [],
      job_role_group: [],
      positions: [],
    },
    user_data: {},
    rules: {},
  });
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      target_business_unit_id: "",
      movement_date: null,
      percent: "",
      target_salary: "",
      observations: "",
      promote_position: false,
      target_sector_id: "",
      target_cost_center_id: "",
      target_position_id: "",
      target_job_role_id: "",
      actual_salary: "",
    },
    validationSchema: validationSchema(
      data.rules.promotions_min_percent,
      data.rules.promotions_max_percent
    ),
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      PostInstante(
        null,
        {
          ...values,
          percent: Number(values.percent),
          collaborator_id: data.user_data.id,
        },
        "/promotions"
      )
        .then(() => {
          dispatch(updateLoading(false));
          router("/request/promote");
          toast.success("Promoção solicitada com sucesso");
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    GetInstance({ user_uuid: id }, "/promotions_form")
      .then(async (response: any) => {
        formik.setFieldValue(
          "actual_salary",
          ClearCurrency(response.data.data.user_data.remuneration_value) ?? "0"
        );
        dispatch(updateLoading(false));
        setData(response.data.data);
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Início" },
            { label: "Solicitações", value: "/request/promote" },
            { label: "Promoções", value: "/request/promote" },
            { label: "Nova solicitação" },
          ]}
        />
        <InfoComponent
          user={data.user_data}
          instruction='Para promover o colaborador confira as informações abaixo e
                  digite os detalhes da promoção.'
        />
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Criar promoção
              </Typography>
            </Grid>
            <Form formik={formik} data={data} />
            <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
              <Grid container gap={2} justifyContent={"end"}>
                <ButtonComponent
                  variant={"outlined"}
                  onClick={() => {
                    window.history.back();
                  }}
                  endIcon={<Undo />}>
                  Voltar
                </ButtonComponent>
                <ButtonComponent
                  variant={"contained"}
                  type={"submit"}
                  endIcon={<AddCircle />}>
                  Cadastrar
                </ButtonComponent>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
