/** @format */

import axios from "axios";
import { useEffect, useState } from "react";
import { ClearString } from "../../../helpers/clearString";

interface Props {
  state: string;
}

export default function useLocation({ state }: Props) {
  const [data, setData]: any = useState({ city: [], state: [] });

  const GetState = async () => {
    const { data } = await axios.get(
      "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
    );
    setData((old: any) => ({ ...old, state: data }));
  };

  const GetCity = async (state: string) => {
    const { data } = await axios.get(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`
    );
    await data.map(
      (item: any) => (item.value = ClearString(item.nome).toUpperCase())
    );
    setData((old: any) => ({ ...old, city: data }));
  };

  useEffect(() => {
    GetState();
  }, []);

  useEffect(() => {
    if (state) GetCity(state);
  }, [state]);

  return {
    options: data,
  };
}
