/** @format */

import {
  AddCircle,
  GroupAdd,
  Mood,
  MoveUp,
  PersonAddOutlined,
  UploadFile,
} from "@mui/icons-material";

export const actionsFastTeam: {
  id: string;
  title: string;
  description: string;
  icon: any;
  url: string;
  user: boolean;
  delegation: boolean;
}[] = [
  {
    id: "requestPositionNew",
    title: "Posição",
    description: "Solicite um aumento do seu time.",
    icon: <AddCircle />,
    url: "/request/position/new",
    user: false,
    delegation: false,
  },
  {
    id: "requestPersonalNew",
    title: "Requisição de pessoal",
    description: "Potencialize seu time de agora.",
    icon: <GroupAdd />,
    url: "/request/personal/new",
    user: false,
    delegation: false,
  },
  {
    id: "requestVacation",
    title: "Solicitações de férias",
    description: "Gerencie as férias do seu time.",
    icon: <Mood />,
    url: "/request/vacation",
    user: false,
    delegation: false,
  },
  {
    id: "employeeNew",
    title: "Novo colaborador",
    description: "Cadastre um novo colaborador",
    icon: <PersonAddOutlined />,
    url: "/employee/pending/new",
    user: false,
    delegation: false,
  },
  {
    id: "delegationNew",
    title: "Delegação",
    description: "Delegue seus fluxos de trabalho.",
    icon: <MoveUp />,
    url: "/delegation/new",
    user: false,
    delegation: true,
  },
  {
    id: "employeeImportNew",
    title: "Importar",
    description: "Importe seus colaboradores por planilha.",
    icon: <UploadFile />,
    url: "/employee/import/new",
    user: false,
    delegation: false,
  },
];
