/** @format */

import {
  EditOutlined,
  Mood,
  PersonOffOutlined,
  PersonRemoveOutlined,
} from "@mui/icons-material";

export const actionsFast: {
  id: string;
  title: string;
  description: string;
  icon: any;
  url: string;
  user: boolean;
}[] = [
  {
    id: "requestUpdateInformation",
    title: "Editar cadastro",
    description: "Edite seu cadastro de forma rápida.",
    icon: <EditOutlined />,
    url: "/request/updateInformation/request/",
    user: true,
  },
  {
    id: "requestLeave",
    title: "Informar afastamento",
    description: "Notifique um afastamento conforme período.",
    icon: <PersonRemoveOutlined />,
    url: "/request/leave/new/",
    user: true,
  },
  {
    id: "requestVacation",
    title: "Solicitar férias",
    description: "Solicite suas férias conforme período.",
    icon: <Mood />,
    url: "/request/vacation/new/",
    user: true,
  },
  {
    id: "requestDismissals",
    title: "Solicitar desligamento",
    description: "Solicite seu desligamento de forma simplificada.",
    icon: <PersonOffOutlined />,
    url: "/request/dismissals/new/",
    user: true,
  },
];
