/** @format */

import { Download } from "@mui/icons-material";
import { Grid, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GenericError } from "../../helpers/genericError";
import { updateLoading } from "../../redux/reducers/common";
import { GetInstance } from "../../services/api";
import { ButtonComponent } from "./buttonComponent";

interface Props {
  solicitation_uuid?: string;
  solicitation_type: string;
  dismissal_reason_id?: string;
  dismissal_type_id?: string;
}

export const DocsView = (props: Props) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const loading = useSelector((state: any) => state.common.loading);

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance(props, "/letters_content")
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        setData(response.data.data.data);
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <Grid>
      {data.length === 0 && !loading && (
        <Paper sx={{ p: 2, mt: 2 }}>
          <Typography fontSize={18} fontWeight={500}>
            Documentos
          </Typography>
          <Grid>
            <Typography fontSize={14}>
              Não existem documentos para este colaborador ou não foi encontrado
              modelo de documento para esse tipo de solicitação.
            </Typography>
          </Grid>
        </Paper>
      )}
      {data.length > 0 && !loading && (
        <Grid>
          {data.map((item: any) => (
            <Paper sx={{ p: 2, mt: 2, gap: 2 }}>
              <Grid container justifyContent={"space-between"}>
                <Typography fontSize={14} fontWeight={500}>
                  {item.name.toUpperCase()}
                </Typography>
                <ButtonComponent
                  variant={"contained"}
                  onClick={() => window.print()}
                  endIcon={<Download />}>
                  Baixar
                </ButtonComponent>
              </Grid>
              <div
                className='printable-content'
                dangerouslySetInnerHTML={{ __html: item.content }}
              />
            </Paper>
          ))}
        </Grid>
      )}
    </Grid>
  );
};
