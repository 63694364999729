/**
 * eslint-disable jsx-a11y/iframe-has-title
 *
 * @format
 */

/** @format */

import { Grid } from "@mui/material";
import { BreadcrumbsComponent } from "../../../components/refactor/breadcrumbsComponent";

export default function DataPulsePage() {
  return (
    <Grid container>
      <BreadcrumbsComponent
        links={[{ label: "Inicio" }, { label: "DataPulse" }]}
      />
      <Grid
        style={{
          background: "linear-gradient(145deg, #F5F5F5, #EEF0F7 40%, #E5F3FA)",
          height: "calc(100dvh - 150px)",
          width: "100%",
          borderRadius: 20,
        }}
        position={"relative"}>
        <iframe
          title='DataPulse'
          src='https://app.databox.com/datawall/1dd9c41fe576f53fc521387f9c06eff712e2b1466e44c16?i'
          style={{
            width: "100%",
            height: "100%",
            border: "transparent",
          }}
        />
      </Grid>
    </Grid>
  );
}
