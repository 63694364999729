/** @format */

export const optionsTable: any = {
  filterType: "checkbox",
  serverSide: true,
  print: false,
  viewColumns: false,
  filter: false,
  fixedHeader: true,
  selectableRows: "none",
  rowsPerPageOptions: [10, 20, 30, 40, 50, 100, 200],
  searchAlwaysOpen: true,
  textLabels: {
    body: {
      noMatch: "Não existe dados para ser exibido.",
      toolTip: "Ordenar",
      columnHeaderTooltip: (column: any) => `Ordenar por ${column.label}`,
    },
    pagination: {
      next: "Próxima página",
      previous: "Página anterior",
      rowsPerPage: "Linhas por página:",
      displayRows: "de",
    },
    toolbar: {
      search: "Pesquisar",
      downloadCsv: "Exportar dados",
      print: "Imprimir",
      viewColumns: "Ver colunas",
      filterTable: "Filtrar tabela",
    },
  },
};
