/** @format */

import { routers } from "../config/routers";

export function RedirectSolicitation(type: any, id: any, router: any) {
  switch (type) {
    case "update_user":
      router(
        routers
          .filter(
            (item: any) => item.id === "requestUpdateInformationDetails"
          )[0]
          .path.replace(":id", "") + id
      );
      break;
    case "suspend_user":
      router(
        routers
          .filter((item: any) => item.id === "requestLeaveDetails")[0]
          .path.replace(":id", "") + id
      );
      break;
    case "disable_user":
      router(
        routers
          .filter((item: any) => item.id === "requestDismissalsDetails")[0]
          .path.replace(":id", "") + id
      );
      break;
    case "new_user":
      router(
        routers
          .filter((item: any) => item.id === "employeePendingDetails")[0]
          .path.replace(":id", "") + id
      );
      break;
    case "new_position":
      router(
        routers
          .filter((item: any) => item.id === "requestPositionDetails")[0]
          .path.replace(":id", "") + id
      );
      break;
    case "salary_review":
      router(
        routers
          .filter((item: any) => item.id === "requestSalaryReviewDetails")[0]
          .path.replace(":id", "") + id
      );
      break;
    case "promote_user":
      router(
        routers
          .filter((item: any) => item.id === "requestPromoteDetails")[0]
          .path.replace(":id", "") + id
      );
      break;
    case "transfer":
      router(
        routers
          .filter((item: any) => item.id === "requestTransferDetails")[0]
          .path.replace(":id", "") + id
      );
      break;
    case "absence":
      router(
        routers
          .filter((item: any) => item.id === "requestLeaveDetails")[0]
          .path.replace(":id", "") + id
      );
      break;
    case "promotion":
      router(
        routers
          .filter((item: any) => item.id === "requestPromoteDetails")[0]
          .path.replace(":id", "") + id
      );
      break;
    case "dismissal":
      router(
        routers
          .filter((item: any) => item.id === "requestDismissalsDetails")[0]
          .path.replace(":id", "") + id
      );
      break;
    case "dismissal_request":
      router(
        routers
          .filter(
            (item: any) => item.id === "requestDismissalRequestsDetails"
          )[0]
          .path.replace(":id", "") + id
      );
      break;
    case "personnel_requisition":
      router(
        routers
          .filter((item: any) => item.id === "requestPersonalDetails")[0]
          .path.replace(":id", "") + id
      );
      break;
    case "user_update_request":
      router(
        routers
          .filter(
            (item: any) => item.id === "requestUpdateInformationDetails"
          )[0]
          .path.replace(":id", "") + id
      );
      break;
    case "vacation_item":
      router(
        routers
          .filter((item: any) => item.id === "requestVacationDetails")[0]
          .path.replace(":id", "") + id
      );
      break;
    default:
      return null;
  }
}
