/** @format */
import * as yup from "yup";
import { ClearCurrency } from "../../../../../helpers/formatCurrency";
export const columns = [
  { name: "solicitation_code", label: "Código" },
  { name: "collaborator_name", label: "Nome" },
  { name: "e_social_motive", label: "Motivo" },
  { name: "job_role_name", label: "Cargo" },
  { name: "manager_name", label: "Gestor" },
  { name: "job_role_group_name", label: "Departamento" },
  { name: "solicitation_status", label: "Status" },
];

export const validationSchema = (rules: any, user: any) =>
  yup.object({
    move_date: yup
      .date()
      .min(new Date(), "Data da movimentação precisa ser futura")
      .nullable()
      .required("Data da movimentação é obrigatório"),
    e_social_motive: yup
      .string()
      .nullable()
      .required("Motivo da revisão é obrigatório"),
    percent: yup
      .number()
      .nullable()
      .test(
        "between-min-and-max-or-rh-user",
        `O valor deve ser entre ${rules.salary_review_min_percent}% e ${rules.salary_review_max_percent}%`,
        (value: any) =>
          user.role === "rh" ||
          (value >= rules.salary_review_min_percent &&
            value <= rules.salary_review_max_percent)
      )
      .required("Porcentagem é obrigatório"),
    future_salary: yup
      .string()
      .nullable()
      .test({
        message: "Salário não pode ser menor que o atual.",
        test: (value: any, { parent }) => {
          const { actual_salary } = parent;
          const valueFormat = Number(ClearCurrency(value));
          if (valueFormat < Number(actual_salary)) {
            return false;
          }
          return true;
        },
      })
      .required("Salário futuro é obrigatório"),
    observations: yup.string().nullable().required("Comentário é obrigatório"),
  });
