/** @format */

import { MenuItem, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Filter } from "../../../../components/refactor/filter";
import { processOptions, accountantStatusOptions, rhStatusOptions } from "./constants";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

export function Filters({ formik }: any) {
  const user = useSelector((state: any) => state.user.data);
  const statusOptions = user.role === 'rh' ? rhStatusOptions : user.role === 'accountant' ? accountantStatusOptions : []

  return (
    <Filter
      formik={formik}
      id={'processes'}
      keys={{
        from: { label: "Data inicio" },
        to: { label: "Data final" },
        solicitation_types: { label: "Processo", options: processOptions },
        status: { label: "Status", options: statusOptions },
      }}>
      <DatePicker
        slotProps={{
          textField: {
            fullWidth: true,
            id: "from",
            onBlur: formik.handleBlur,
            error: formik.touched?.from && Boolean(formik.errors?.from),
            helperText: formik?.errors?.from,
          },
        }}
        label='Data Inícial'
        value={dayjs(formik.values?.from)}
        onChange={(value: any) => {
          formik.setFieldValue("from", value);
        }}
      />
      <DatePicker
        slotProps={{
          textField: {
            fullWidth: true,
            id: "to",
            onBlur: formik.handleBlur,
            error: formik.touched?.to && Boolean(formik.errors?.to),
            helperText: formik?.errors?.to,
          },
        }}
        label='Data Final'
        value={dayjs(formik.values?.to)}
        onChange={(value: any) => {
          formik.setFieldValue("to", value);
        }}
      />
      <TextField
        fullWidth
        id='solicitation_types'
        name='solicitation_types'
        select
        label='Processo'
        value={formik.values?.solicitation_types}
        onChange={(event: any) =>
          formik.setFieldValue("solicitation_types", event.target.value)
        }
        onBlur={formik.handleBlur}
        error={
          formik.touched?.solicitation_types &&
          Boolean(formik.errors?.solicitation_types)
        }
        helperText={formik?.errors?.solicitation_types}>
        {processOptions.map((option: any) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        fullWidth
        id='status'
        name='status'
        select
        label='Status'
        value={formik.values?.status}
        onChange={(event: any) =>
          formik.setFieldValue("status", event.target.value)
        }
        onBlur={formik.handleBlur}
        error={formik.touched?.status && Boolean(formik.errors?.status)}
        helperText={formik?.errors?.status}>
        {statusOptions.map((option: any) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Filter>
  );
}
