/** @format */

import { Drafts, Visibility } from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  Link,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ActionRemove } from "../../../../components/refactor/actionRemove";
import { Filter } from "../../../../components/refactor/filter";
import { typeLetter } from "../../../../config/constants";
import { optionsTable } from "../../../../config/optionsTable";
import { FilterEmpty } from "../../../../helpers/emptyData";
import { GenericError } from "../../../../helpers/genericError";
import { IsPermissions } from "../../../../helpers/isPermission";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance } from "../../../../services/api";

const columns = [
  { name: "id", label: "Código" },
  { name: "name", label: "Nome da carta" },
  { name: "solicitation_type", label: "Tipo da carta" },
  { name: "active", label: "Habilitado" },
];

const statusOptions = [
  { label: "Todos", value: "all" },
  { label: "Habilitado", value: true },
  { label: "Desabilitado", value: false },
];

const typeLetterOptions = [{ label: "Todos", value: "all" }, ...typeLetter];

let timerSearch: any;

export function LetterRequest() {
  const dispatch = useDispatch();
  const router = useNavigate();
  const [data, setData]: any = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch]: any = useState(null);
  const [order, setOrder]: any = useState(null);

  const formik: any = useFormik({
    initialValues: {
      solicitation_type: "all",
      active: "all",
    },
    onSubmit: (values: any) => {
      dispatch(updateLoading(true));
      const sendValues = {
        solicitation_type:
          values.solicitation_type === "all" ? null : values.solicitation_type,
        active: values.active === "all" ? null : values.active,
      };
      GetInstance(
        {
          page: page + 1,
          per_page: perPage,
          search,
          order_by: order,
          ...sendValues,
        },
        "/letters"
      )
        .then((response: any) => {
          setData((old: any) =>
            page === 0
              ? FilterEmpty(response.data.data.data)
              : [...FilterEmpty(response.data.data.data), ...old]
          );
          setTotal(response.data.data.total_count);
          dispatch(updateLoading(false));
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance(
      { page: page + 1, per_page: perPage, search, order_by: order },
      "/letters"
    )
      .then((response: any) => {
        setData((old: any) =>
          page === 0
            ? FilterEmpty(response.data.data.data)
            : [...FilterEmpty(response.data.data.data), ...old]
        );
        setTotal(response.data.data.total_count);
        dispatch(updateLoading(false));
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, search, order]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return (
    <Grid container gap={2}>
      <Grid container gap={2} justifyContent={"space-between"}>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link
            underline='hover'
            sx={{ cursor: "pointer" }}
            color='inherit'
            href='/'>
            Inicio
          </Link>
          <Typography>Configurações</Typography>
          <Typography fontWeight={800}>Cartas</Typography>
        </Breadcrumbs>
        <Button
          variant={"contained"}
          onClick={() => {
            router("/settings/letter/new");
          }}
          fullWidth={isMobile}
          endIcon={<Drafts />}>
          Novo modelo de carta
        </Button>
      </Grid>

      <Filter
        formik={formik}
        id={'letter'}
        keys={{
          solicitation_type: {
            label: "Tipo de carta",
            options: typeLetterOptions ?? [],
          },
          active: { label: "Status", options: statusOptions },
        }}>
        <TextField
          fullWidth
          id='solicitation_type'
          select
          label='Tipo de carta'
          value={formik.values?.solicitation_type}
          onChange={(event: any) =>
            formik.setFieldValue("solicitation_type", event.target.value)
          }
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors.solicitation_type)}
          helperText={formik.errors.solicitation_type}>
          {typeLetterOptions.map((option: any) => (
            <MenuItem value={option.value}>{option?.label}</MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          id='active'
          name='active'
          select
          label='Status'
          value={formik.values.active}
          onChange={(event: any) =>
            formik.setFieldValue("active", event.target.value)
          }
          onBlur={formik.handleBlur}
          error={formik.touched.active && Boolean(formik.errors.active)}
          helperText={formik?.errors?.active}>
          {statusOptions.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Filter>
      <MUIDataTable
        title={""}
        data={data}
        columns={[
          {
            name: "action",
            label: "Ações",
            options: {
              sort: false,
              customBodyRenderLite: (dataIndex: number) => {
                return (
                  <Grid container>
                    <IsPermissions keyPrivate={"permissionLetterDetails"}>
                      <Tooltip title={"Ver carta"}>
                        <IconButton
                          onClick={() =>
                            router(
                              `/settings/letter/details/${data[dataIndex].uuid}`
                            )
                          }>
                          <Visibility color='primary' />
                        </IconButton>
                      </Tooltip>
                    </IsPermissions>
                    <ActionRemove
                      statusList={data[dataIndex].solicitation_status}
                      url='/letter'
                      id={data[dataIndex].uuid}
                    />
                  </Grid>
                );
              },
            },
          },
          ...columns,
        ]}
        options={{
          ...optionsTable,
          searchPlaceholder: "Pesquise por nome",
          download: false,
          onSearchChange: (value: string) => {
            clearTimeout(timerSearch);
            timerSearch = setTimeout(() => {
              setPage(0);
              setSearch(value);
            }, 500);
          },
          onColumnSortChange: (value: string, direction: string) => {
            setOrder(`${value} ${direction}`);
          },
          count: total,
          page,
          onChangePage: (page: number) => {
            setPage(page);
          },
          rowsPerPage: perPage,
          onChangeRowsPerPage: (perPage: number) => {
            setPage(0);
            setPerPage(perPage);
          },
        }}
      />
    </Grid>
  );
}
