/** @format */

import {
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import {
  ClearCurrency,
  FormatCurrency,
  MaskCurrency,
} from "../../../../../helpers/formatCurrency";
import { ReadjustSalary } from "../../../../../helpers/readjustSalary";

export function Form({ formik, data, edit }: any) {
  return (
    <>
      <Grid gridTemplateColumns={"1fr 1fr"} display={"grid"} gap={2}>
        <FormControlLabel
          value={formik.values.promote_position}
          onChange={(event: any) => {
            formik.setFieldValue("promote_position", event.target.checked);
            if (event.target.checked) {
              const option =
                data.options.positions.filter(
                  (item: any) => item.value === data.user_data.position_id
                )[0] ?? {};
              formik.setFieldValue(
                "target_position_id",
                data.user_data.position_id
              );
              formik.setFieldValue("target_job_role_id", option.job_role_id);
              formik.setFieldValue("target_sector_id", option.sector_id);
              formik.setFieldValue(
                "target_cost_center_id",
                option.cost_center_id
              );
              formik.setFieldValue(
                "target_business_unit_id",
                option.business_unit_id
              );
            }
          }}
          disabled={edit}
          control={<Checkbox />}
          label='A promoção será para a mesma posição?'
        />
      </Grid>
      <Grid gridTemplateColumns={"1fr 1fr"} mt={2} display={"grid"} gap={2}>
        <TextField
          fullWidth
          id='target_position_id'
          select
          label='Posição destino'
          disabled={formik.values.promote_position || edit}
          value={formik.values.target_position_id}
          onChange={(event: any) => {
            const option =
              data.options.positions.filter(
                (item: any) => item.value === event.target.value
              )[0] ?? {};
            formik.setFieldValue("target_position_id", event.target.value);
            formik.setFieldValue("target_job_role_id", option.job_role_id);
            formik.setFieldValue("target_sector_id", option.sector_id);
            formik.setFieldValue(
              "target_cost_center_id",
              option.cost_center_id
            );
            formik.setFieldValue(
              "target_business_unit_id",
              option.business_unit_id
            );
          }}
          onBlur={formik.handleBlur}
          error={
            formik.touched.target_position_id &&
            Boolean(formik.errors.target_position_id)
          }
          helperText={
            formik.touched.target_position_id &&
            formik.errors.target_position_id
          }>
          {formik.values.promote_position ? (
            <MenuItem
              key={data.user_data.position_id}
              value={data.user_data.position_id}>
              {data.user_data.position_name}
            </MenuItem>
          ) : (
            data.options.positions.map((option: any) => (
              <MenuItem key={option.value} value={option.value}>
                {option.code} {option.job_role_name}
              </MenuItem>
            ))
          )}
        </TextField>
        <TextField
          fullWidth
          id='target_job_role_id'
          select
          disabled={edit}
          label='Cargo destino'
          value={formik.values.target_job_role_id}
          onChange={(event: any) =>
            formik.setFieldValue("target_job_role_id", event.target.value)
          }
          onBlur={formik.handleBlur}
          error={
            formik.touched.target_job_role_id &&
            Boolean(formik.errors.target_job_role_id)
          }
          helperText={
            formik.touched.target_job_role_id &&
            formik.errors.target_job_role_id
          }>
          {data.options.job_role.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <DatePicker
          slotProps={{
            textField: {
              fullWidth: true,
              id: "movement_date",
              onBlur: formik.handleBlur,
              error:
                formik.touched.movement_date &&
                Boolean(formik.errors.movement_date),
              helperText:
                formik.touched.movement_date && formik.errors.movement_date,
            },
          }}
          disabled={edit}
          label='Data da movimentação'
          value={formik.values.movement_date}
          onChange={(value: any) => {
            formik.setFieldValue("movement_date", value);
          }}
        />
        <TextField
          fullWidth
          id='percent'
          type={"number"}
          label='Porcentagem (%)'
          disabled={edit}
          value={formik.values.percent}
          onChange={(event: any) => {
            formik.setFieldValue("percent", event.target.value);
            if (event.target.value.length === 0) {
              formik.setFieldValue("target_salary", MaskCurrency(0));
            } else {
              formik.setFieldValue(
                "target_salary",
                MaskCurrency(
                  ((parseFloat(event.target.value) + 100) *
                    parseFloat(formik.values.actual_salary)) /
                    100
                )
              );
            }
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.percent && Boolean(formik.errors.percent)}
          helperText={formik.touched.percent && formik.errors.percent}
        />
        <TextField
          fullWidth
          id='target_salary'
          disabled={edit}
          label='Salário futuro'
          value={formik.values.target_salary}
          onChange={(event: any) => {
            formik.setFieldValue(
              "percent",
              ReadjustSalary(
                Number(formik.values.actual_salary),
                ClearCurrency(FormatCurrency(event))
              )
            );
            formik.setFieldValue("target_salary", FormatCurrency(event));
          }}
          onBlur={formik.handleBlur}
          error={
            formik.touched.target_salary && Boolean(formik.errors.target_salary)
          }
          helperText={
            formik.touched.target_salary && formik.errors.target_salary
          }
        />
      </Grid>
      <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
        <div>
          <TextField
            fullWidth
            disabled={edit}
            id='observations'
            label='Comentários'
            variant='outlined'
            multiline={true}
            rows={4}
            value={formik.values.observations}
            onChange={(event: any) =>
              formik.setFieldValue("observations", event.target.value)
            }
            onBlur={formik.handleBlur}
            error={
              formik.touched.observations && Boolean(formik.errors.observations)
            }
            helperText={
              formik.touched.observations && formik.errors.observations
            }
          />
        </div>
      </Grid>
    </>
  );
}
