/** @format */
import * as yup from "yup";
import { validationCPF } from "../../../../../helpers/validationCPF";

export const columns = [
  { name: "solicitation_id", label: "Código" },
  { name: "target_user_name", label: "Nome" },
  { name: "solicitation_date", label: "Data da solicitação" },
  { name: "solicitation_status", label: "Status" },
];

const contractTypeValidation = (value: any, context: any) => {
  const position_type = context.from[0].value.position_type;

  if (value === null) return true;
  if (
    (value === "999" && position_type !== "pj") ||
    (value !== "999" && position_type === "pj")
  ) {
    return false;
  }
  return true;
};

export const validationSchema = yup.object({
  cpf: yup
    .string()
    .nullable()
    .required("Campo é obrigatório")
    .test("invalid-cpf", "CPF inválido!", (cpf) => validationCPF(cpf)),
  father_cpf: yup
    .string()
    .nullable()
    .test("invalid-cpf", "CPF inválido!", (cpf) => validationCPF(cpf)),
  mother_cpf: yup
    .string()
    .nullable()
    .test("invalid-cpf", "CPF inválido!", (cpf) => validationCPF(cpf)),
  business_unit_contract_type: yup
    .string()
    .nullable()
    .test(
      "invalid-contract_type",
      "Tipo de contrato inválido!",
      (value, context) => contractTypeValidation(value, context)
    ),
  manager_id: yup.string().nullable().required("Campo é obrigatório"),
});
