/** @format */

export const columns = [
  { name: "identification", label: "Matrícula" },
  { name: "name_formatted", label: "Nome" },
  { name: "cpf", label: "CPF" },
  { name: "job_role_name", label: "Cargo" },
  { name: "manager_name", label: "Gestor" },
  { name: "department_name", label: "Departamento" },
  // { name: "status", label: "Status" },
];

export const statusOptions = [
  { label: "Todos", value: "all" },
  { label: "Ativo", value: "active" },
  { label: "Afastado", value: "on_leave" },
];
