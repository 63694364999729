/** @format */

import { Info, MoveUp } from "@mui/icons-material";
import { Grid, IconButton, Tooltip } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";
import { BreadcrumbsComponent } from "../../../components/refactor/breadcrumbsComponent";
import { ButtonComponent } from "../../../components/refactor/buttonComponent";
import { optionsTable } from "../../../config/optionsTable";
import useDelegation from "../../../services/protected/hooks/useDelegation";
import { columns } from "./helpers/constants";

export function Delegation() {
  const router = useNavigate();
  const {
    data,
    total,
    page,
    perPage,
    searchCall,
    setPage,
    setPerPage,
    setOrder,
  } = useDelegation();

  return (
    <Grid container gap={2}>
      <BreadcrumbsComponent
        links={[{ label: "Inicio" }, { label: "Delegações" }]}
        buttons={
          <ButtonComponent
            variant={"contained"}
            onClick={() => {
              router("/delegation/new");
            }}
            endIcon={<MoveUp />}>
            Delegar colaborador
          </ButtonComponent>
        }
      />

      <Grid container>
        <MUIDataTable
          title={""}
          data={data}
          columns={[
            {
              name: "action",
              label: "Ações",
              options: {
                sort: false,
                customBodyRenderLite: (dataIndex: number) => {
                  return (
                    <Tooltip title={"Ver informações"}>
                      <IconButton
                        onClick={() =>
                          router(`/delegation/details/${data[dataIndex].uuid}`)
                        }>
                        <Info />
                      </IconButton>
                    </Tooltip>
                  );
                },
              },
            },
            ...columns,
          ]}
          options={{
            ...optionsTable,
            searchPlaceholder: "Pesquise por nome",
            download: false,
            onSearchChange: (value: string) => {
              searchCall(value);
            },
            onColumnSortChange: (value: string, direction: string) => {
              setOrder(`${value} ${direction}`);
            },
            count: total,
            page,
            onChangePage: (page: number) => {
              setPage(page);
            },
            rowsPerPage: perPage,
            onChangeRowsPerPage: (perPage: number) => {
              setPerPage(perPage);
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
